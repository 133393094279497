import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NztcsTaxonSearchCriteria } from '../../../nztcs-taxon-name/taxon-search/nztcs-taxon-search-criteria';
import { NztcsTaxonEntityService } from '../../../nztcs-taxon-name/service/nztcs-taxon-entity.service';
import { NztcsSpeciesService } from '../../service/nztcs-species.service';

@Component({
  selector: 'app-taxon-select',
  templateUrl: './taxon-select.component.html',
  styleUrls: ['taxon-select.component.scss'],
})
export class TaxonSelectComponent implements OnInit {
  @ViewChild('taxonSelectModal', { static: true })
  taxonSelectModal: ModalDirective;

  @Output()
  taxonSelectedEvent = new EventEmitter();

  criteria: NztcsTaxonSearchCriteria;
  pagedSearchResponse: any;

  taxonRanks: string[];
  speciesTypes: string[];

  constructor(
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private nztcsSpeciesService: NztcsSpeciesService
  ) {}

  ngOnInit() {
    this.taxonRanks = this.nztcsTaxonEntityService.getTaxonRanks();
    this.speciesTypes = this.nztcsSpeciesService.getSpeciesTypes();
    this.clearAction();
  }

  show() {
    this.taxonSelectModal.show();
  }

  selectAction(nztcsTaxonId: number) {
    this.taxonSelectedEvent.next(nztcsTaxonId);
    this.cancelAction();
  }

  cancelAction() {
    this.taxonSelectModal.hide();
  }

  searchAction() {
    this.nztcsTaxonEntityService
      .findByCriteria(this.criteria)
      .subscribe((response) => {
        this.pagedSearchResponse = response;
      });
  }

  clearAction() {
    this.criteria = new NztcsTaxonSearchCriteria();
    this.criteria.rank = 'Genus';
    this.criteria.pageSize = 15;
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.criteria.pageNumber = event.page;
    this.searchAction();
  }
}
