export class PersonEntity {
  constructor(
    public administrator: boolean,
    public familyName: string,
    public firstName: string,
    public historicalNameEditor: boolean,
    public nameEditor: boolean,
    public passwordEdit: string,
    public personId: number,
    public personType: string,
    public userName: string
  ) {}

  static newPerson() {
    return new PersonEntity(
      false,
      null,
      null,
      false,
      false,
      null,
      null,
      'EXTERNAL',
      null
    );
  }
}
