import { Component, OnInit } from '@angular/core';
import { HomeService } from '../service/home.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AssessmentSearchCtxService } from '../../assessment/service/assessment-search-ctx.service';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { mergeMap } from 'rxjs/operators';
import { AssessmentService } from '../../assessment/service/assessment.service';

@Component({
  selector: 'app-home-quick-search',
  templateUrl: './home-quick-search.component.html',
})
export class HomeQuickSearchComponent implements OnInit {
  asyncSelected: string;
  dataSource: Observable<any>;

  constructor(
    private homeService: HomeService,
    private router: Router,
    private assessmentService: AssessmentService,
    public searchCtx: AssessmentSearchCtxService
  ) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.searchCtx.criteria.assessmentName);
    }).pipe(
      mergeMap((token: string) =>
        this.assessmentService.findSpeciesNamesByNameCriteria(
          this.asyncSelected
        )
      )
    );
  }

  ngOnInit() {}

  searchAction() {
    this.searchCtx.clear();
    this.searchCtx.criteria.assessmentName = this.asyncSelected;
    this.searchCtx.homeSearch().subscribe((pagedResponse: PagedResponse) => {
      this.searchCtx.response = pagedResponse;
      this.searchCtx.response.numberOfPages = Math.ceil(
        pagedResponse.total / this.searchCtx.criteria.pageSize
      );
      this.router.navigate(['assessment-search']);
    });
  }
}
