import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { PersonEntity } from '../../domain/person/person-entity';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { PersonCriteria } from './person-criteria';

@Injectable()
export class PersonService {
  constructor(private httpClient: HttpClient) {}

  public findAllPersons() {
    return this.httpClient.get<PagedResponse>('/rest/person/findAllPersons');
  }

  public findPersonsByCriteria(criteria: PersonCriteria) {
    return this.httpClient.post<PagedResponse>(
      '/rest/person/findPersonsByCriteria',
      criteria
    );
  }

  public getPerson(personId: number) {
    return this.httpClient.get<PersonEntity>('/rest/person/' + personId);
  }

  public savePerson(person: PersonEntity) {
    return this.httpClient.post<ResponseCargo>(
      '/rest/person',
      new ResponseCargo(person)
    );
  }

  public resetPassword(userName: string) {
    return this.httpClient.post<ResponseCargo>(
      '/rest/person/resetPassword?userName=' + userName,
      null
    );
  }

  public delete(personId: number) {
    return this.httpClient.delete('/rest/person/' + personId);
  }

  public findPanelMembership(personId: number) {
    return this.httpClient.get('/rest/panel-membership/' + personId);
  }
}
