import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-bioweb-taxon-name-list',
  templateUrl: './bioweb-taxon-name-list.component.html',
})
export class BiowebTaxonNameListComponent implements OnInit {
  @Input()
  taxon: any;

  constructor() {}

  ngOnInit() {}
}
