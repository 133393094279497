import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Tag } from '../../domain/nztcs/tag';

@Injectable()
export class TagService {
  constructor(private http: HttpClient) {}

  findAll() {
    return this.http.get<Tag[]>('/rest/tags/list');
  }

  save(tag: Tag) {
    return this.http.post('/rest/tags', tag);
  }

  remove(tagId: number, force: boolean) {
    return this.http.delete('/rest/tags/' + tagId + '?force=' + force);
  }
}
