import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NZORNameService } from '../../../../nzor/service/nzorname.service';
import { NzorNameCriteria } from '../../../../nzor/service/nzor-name-criteria';

@Component({
  selector: 'app-nzor-species-search',
  templateUrl: './nzor-species-search.component.html',
})
export class NzorSpeciesSearchComponent implements OnInit {
  @ViewChild('nzorSpeciesSearchModal', { static: true })
  nzorSpeciesSearchModal: ModalDirective;

  @Output()
  speciesSelectedEvent = new EventEmitter();

  criteria = new NzorNameCriteria();
  pagedSearchResponse: any;

  constructor(private nzorNameService: NZORNameService) {}

  ngOnInit() {
    this.searchAction();
  }

  show() {
    this.nzorSpeciesSearchModal.show();
    this.searchAction();
  }

  selectAction(biowebSpeciesId) {
    this.speciesSelectedEvent.next(biowebSpeciesId);
    this.cancelAction();
  }

  cancelAction() {
    this.nzorSpeciesSearchModal.hide();
  }

  searchAction() {
    this.criteria.nameClassList = ['Scientific Name', 'Tag Name'];
    this.nzorNameService.findByCriteria(this.criteria).subscribe((response) => {
      this.pagedSearchResponse = response;
    });
  }

  clearAction() {
    this.criteria = new NzorNameCriteria();
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.criteria.pageNumber = event.page;
    this.searchAction();
  }
}
