import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NztcsTaxonEntity } from '../../../domain/nztcs/nztcs-taxon-entity';
import { NztcsTaxonEntityService } from '../../service/nztcs-taxon-entity.service';
import { NztcsSpeciesService } from '../../../nztcs-species/service/nztcs-species.service';

@Component({
  selector: 'app-change-species-type-dialog',
  templateUrl: './change-species-type-dialog.component.html',
  styleUrls: ['change-species-type-dialog.component.scss'],
})
export class ChangeSpeciesTypeDialogComponent implements OnInit {
  @Output()
  changeSpeciesTypeEvent = new EventEmitter();

  @ViewChild('changeSpeciesTypeModal', { static: true })
  changeSpeciesTypeModal: ModalDirective;

  nztcsTaxonId: number;
  taxonName: NztcsTaxonEntity;

  speciesTypeMap: any;

  searchInProgress = false;
  speciesTypeList: string[];

  changeInProgress = false;

  constructor(
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private nztcsSpeciesService: NztcsSpeciesService
  ) {}

  ngOnInit() {
    this.speciesTypeList = this.nztcsSpeciesService.getSpeciesTypes();
  }

  show(nztcsTaxonId: number) {
    this.nztcsTaxonId = nztcsTaxonId;
    this.searchInProgress = true;
    this.nztcsTaxonEntityService
      .findNztcsTaxonEntityByPrimaryKey(this.nztcsTaxonId)
      .subscribe((taxonName) => {
        this.taxonName = taxonName;
        this.changeSpeciesTypeModal.show();
        this.nztcsTaxonEntityService
          .findAllChildrenSpeciesTypeMap(this.nztcsTaxonId)
          .subscribe((speciesTypeMapResponse) => {
            this.speciesTypeMap = speciesTypeMapResponse;
            this.searchInProgress = false;
          });
      });
  }

  changeAction() {
    this.changeInProgress = true;
    this.nztcsTaxonEntityService
      .changeSpeciesType(this.nztcsTaxonId, this.taxonName.speciesType)
      .subscribe(() => {
        console.log('Change species type completed.');
        this.changeInProgress = false;
        this.changeSpeciesTypeModal.hide();
        this.changeSpeciesTypeEvent.next('SpeciesTypeChanged');
      });
  }

  getSpeciesTypeKeys() {
    return this.speciesTypeMap ? Object.keys(this.speciesTypeMap.taxonMap) : [];
  }

  cancelAction() {
    this.changeSpeciesTypeModal.hide();
  }
}
