<div *ngIf="barChartData">
  <canvas
    baseChart
    [data]="barChartData"
    [options]="barChartOptions"
    [type]="'bar'"
  ></canvas>
</div>

<div class="row">
  <div class="col-md-4">
    <button (click)="onExport()" class="btn btn-sm btn-outline-success">
      Export
    </button>
  </div>
  <div class="col-md-8 text-right">
    <span>Species total: {{ totalCount }}</span>
  </div>
</div>
