import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-wait-spinner',
  templateUrl: './wait-spinner.component.html',
})
export class WaitSpinnerComponent {
  @Input()
  showSpinner = false;

  constructor() {}
}
