import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (localStorage.getItem('currentUser')) {
      // can't check expiry - client's clock could be wrong
      //let expired = this.jwtHelperService.isTokenExpired();
      //console.log('check token expiry = ' + expired);

      // logged in so return true
      return true;
    }

    // not logged in so redirect to login page
    this.router.navigate(['/login']);
    return false;
  }
}
