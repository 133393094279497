import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BiowebSpeciesSearchCriteria } from './bioweb-species-search-criteria';
import { Observable } from 'rxjs';
import { PagedResponse } from '../../domain/response/PagedResponse';

@Injectable()
export class SpeciesService {
  constructor(private http: HttpClient) {}

  getBioStatusCodeValues() {
    return this.http.get('/rest/species/bioStatusTypes');
  }

  getTaxonomicStatusMap() {
    return this.http.get('/rest/species/taxonomicStatusMap');
  }

  getSpecies(speciesId: number) {
    return this.http.get('/rest/species/' + speciesId);
  }

  findByCriteria(
    biowebSpeciesSearchCriteria: BiowebSpeciesSearchCriteria
  ): Observable<PagedResponse> {
    return this.http.post<PagedResponse>(
      '/rest/species/findByCriteria',
      biowebSpeciesSearchCriteria
    );
  }
}
