import { Component, OnInit, ViewChild } from '@angular/core';
import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { ActivatedRoute } from '@angular/router';
import { AssessmentService } from '../service/assessment.service';
import { ReportService } from '../../report/service/report.service';
import { User } from '../../authentication/service/user';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { PanelMemberStatus } from '../../report/service/panel-member-status';
import { AssessmentSearchCtxService } from '../service/assessment-search-ctx.service';
import { EditTagsComponent } from './edit-tags/edit-tags.component';
import { TinymceUtils } from '../../tinymce/tinymce-utils';
import { ConservationStatusRequest } from '../service/conservation-status-request';
import { Category } from '../../domain/nztcs/category';
import {
  faChevronLeft,
  faChevronRight,
  faComment,
  faExclamationTriangle,
  faPencilAlt,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { TextEditorComponent } from '../../text-editor/text-editor.component';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';

@Component({
  selector: 'app-assessment-detail',
  templateUrl: './assessment-detail.component.html',
})
export class AssessmentDetailComponent implements OnInit {
  @ViewChild('textEditorPublicNotes')
  textEditorPublicNotes: TextEditorComponent;

  @ViewChild('textEditorPrivateNotes')
  textEditorPrivateNotes: TextEditorComponent;

  @ViewChild('editTagsComponent')
  editTagsComponent: EditTagsComponent;

  currentUser: User;

  assessmentId: number;

  assessment = new ResponseCargo(null);
  report = new ResponseCargo(null);
  relatedAssessments = [];
  previousAssessment = null;
  nztcsSpecies: NztcsSpeciesEntity;

  editMode = false;

  saveOptions = ['PENDING', 'IN_PROGRESS', 'COMPLETED'];

  completeAvailable = false;
  updateNameAvailable = false;
  revertAvailable = false;
  editAvailable = false;
  notesDisplayed = false;
  commentsDisplayed = false;
  addCommentAvailable = false;
  tagsDisplayed = false;
  editTagsAvailable = false;
  editNamesAvailable = false;
  editGenerationTimeAvailable = false;

  panelMemberStatus: PanelMemberStatus;

  commentListCount = 0;

  categoryList = [];
  qualifierList = [];
  popTrendOptions = [];
  popSizeOptions = [];
  trendConfidenceOptions = [];
  sizeConfidenceOptions = [];
  statusChangeList = [];
  changeReasonList = [];

  // a list of available qualifiers not already added to the assessment
  availableQualifiers = [];

  // these are the variables to hold the currently selected values from in the
  // screen widget, they just hold the state of the UI, nothing to do with the
  // data in the model object
  selectedAssessmentQualifiers = [];
  selectedAvailableQualifiers = [];

  editorSettings = TinymceUtils.editorSettings;

  commentIcon = faComment;
  removeIcon = faTimes;
  editIcon = faPencilAlt;
  moveLeft = faChevronLeft;
  moveRight = faChevronRight;
  warningIcon = faExclamationTriangle;

  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private assessmentService: AssessmentService,
    private assessmentSearchCtxService: AssessmentSearchCtxService,
    private nztcsSpeciesService: NztcsSpeciesService,
    private reportService: ReportService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );

    this.route.params.subscribe((params) => {
      this.assessmentId = +params['assessmentId'];
      this.loadAssessment();
    });
  }

  loadSpecies() {
    this.nztcsSpeciesService
      .getNztcsSpecies(this.assessment.model.speciesId)
      .subscribe((response) => {
        this.nztcsSpecies = response;
      });
  }

  editStatusIsModifiable(status: string) {
    return (
      status === 'DRAFT' || status === 'PENDING' || status === 'IN_PROGRESS'
    );
  }

  loadRelatedAssessments() {
    this.assessmentService
      .findAllAssessmentsForSpecies(this.assessment.model.speciesId)
      .subscribe((relatedAssessmentsResponse) => {
        this.relatedAssessments = relatedAssessmentsResponse;
      });
  }

  isCategorySameAsPreviousAssessment() {
    return (
      this.previousAssessment &&
      this.previousAssessment.category &&
      this.assessment.model.category &&
      this.assessment.model.category.code ===
        this.previousAssessment.category.code
    );
  }

  isStatusChangeRequired() {
    // if category (conservation status) is different then statusChange can't be noChange
    const noChange =
      this.assessment.model.statusChange === null ||
      this.assessment.model.statusChange === undefined
        ? false
        : this.assessment.model.statusChange.code === 'NCH';
    return !this.isCategorySameAsPreviousAssessment() && noChange;
  }

  isChangeReasonRequired() {
    // if category (conservation status) is different then changeReason can't be noChange
    // NOTE: this one is changeReason and is the same but different to the statusChange :-)
    const noChange =
      this.assessment.model.changeReason === null ||
      this.assessment.model.changeReason === undefined
        ? false
        : this.assessment.model.changeReason.code === 'NCH';
    return !this.isCategorySameAsPreviousAssessment() && noChange;
  }

  editTagsAction() {
    this.editTagsComponent.showEditTags();
  }

  removeTagAction(tag: any) {
    console.log('removeTagAction()' + JSON.stringify(tag));
    this.assessmentService
      .removeTagFromAssessment(this.assessment.model.assessmentId, tag.tagRefId)
      .subscribe((response) => {
        // will remove it from the current model ourselves, rather than forcing a refresh
        for (let i = this.assessment.model.tags.length - 1; i >= 0; i--) {
          const nextTag = this.assessment.model.tags[i];
          if (nextTag.tagRefId === tag.tagRefId) {
            this.assessment.model.tags.splice(i, 1);
          }
        }
      });
  }

  navigatePrevAvailable(): boolean {
    return this.assessmentSearchCtxService.navigatePrevAvailable(
      this.assessmentId
    );
  }

  navigateNextAvailable(): boolean {
    return this.assessmentSearchCtxService.navigateNextAvailable(
      this.assessmentId
    );
  }

  navigateToNextAssessment(direction: number) {
    this.assessmentSearchCtxService.navigateByRow(direction, this.assessmentId);
  }

  editAction() {
    this.editMode = true;
  }

  cancelAction() {
    this.editMode = false;
    this.loadAssessment();
  }

  onMoveQualifier(fromList: any[], toList: any[], selectedItems: any[]) {
    // loop through the selectItems and move the from the fromList to the toList (works in either direction)
    for (const nextItem of selectedItems) {
      const idx = fromList.findIndex((item) => item.code === nextItem.code);
      if (idx > -1) {
        toList.push(nextItem);
        fromList.splice(idx, 1);
      }
    }

    // after moving the items, sort the list for UI consistency
    this.sortQualifierList(fromList);
    this.sortQualifierList(toList);
  }

  onCommentListCountChanged(commentListCount: any) {
    this.commentListCount = commentListCount;
  }

  compareOnCode(o1: any, o2: any) {
    return o1 && o2 && o1.code && o2.code && o1.code === o2.code;
  }

  saveAction(saveOption: string) {
    this.assessment.model.publicNotes = this.textEditorPublicNotes.text;
    this.assessment.model.notes = this.textEditorPrivateNotes.text;
    this.assessment.model.editStatus = saveOption;
    this.assessmentService
      .saveAssessment(this.assessment.model)
      .subscribe((response) => {
        this.assessment = response;
        if (response.responseMessages.length === 0) {
          this.editMode = false;
          this.loadAssessment();
        }
      });
  }

  changeAssessmentStatus(status: string) {
    this.assessmentService
      .changeAssessmentStatus(this.assessmentId, status)
      .subscribe(() => {
        this.loadAssessment();
      });
  }

  updateConservationStatus(categorySelected: Category) {
    const conservationStatusRequest = new ConservationStatusRequest();
    conservationStatusRequest.populationState =
      this.assessment.model.populationState;
    conservationStatusRequest.populationTrend =
      this.assessment.model.populationTrend;
    conservationStatusRequest.populationSize =
      this.assessment.model.populationSize;
    conservationStatusRequest.reportType =
      this.report.model.reportType;

    this.assessmentService
      .calculateConservationStatus(conservationStatusRequest)
      .subscribe((response) => {
        if (categorySelected) {
          if (
            categorySelected.code === response.outputConservationStatus.code
          ) {
            this.assessment.model.criteria = response.criteria;
          } else {
            this.assessment.model.criteria = null;
          }
        } else {
          this.assessment.model.category = response.outputConservationStatus;
          this.assessment.model.criteria = response.criteria;
        }
      });
  }

  updateNameAction() {
    this.assessmentService
      .updateAssessmentName(this.assessmentId)
      .subscribe(() => {
        this.loadAssessment();
      });
  }

  publicNotesAttachmentsChanged() {
    // When one text editor changes attachments then update the attachments for the other text editor
    this.textEditorPrivateNotes.loadAttachments();
  }

  privateNotesAttachmentsChanged() {
    // When one text editor changes attachments then update the attachments for the other text editor
    this.textEditorPublicNotes.loadAttachments();
  }

  private loadAssessment() {
    this.assessmentService
      .getAssessment(this.assessmentId)
      .subscribe((assessmentResponse) => {
        this.assessment = assessmentResponse;
        this.loadOptions();
        this.loadReport();
        this.loadRelatedAssessments();
        this.loadSpecies();
      });
  }

  private loadOptions() {
    this.assessmentService
      .categoryList(this.assessment.model.reportId)
      .subscribe((response) => (this.categoryList = response));

    this.assessmentService.getQualifierList().subscribe((response: any) => {
      this.qualifierList = response;
      this.determineAvailableQualifiers();
    });

    this.assessmentService
      //NZTCS-319 passing report ID to display population trend List
      .populationTrendList(this.assessment.model.reportId)
      .subscribe((response: any) => (this.popTrendOptions = response));

    this.assessmentService
      .populationSizeList(this.assessment.model.reportId)
      .subscribe((response: any) => (this.popSizeOptions = response));

    this.assessmentService
      .getConfidenceList()
      .subscribe((response: any) => (this.trendConfidenceOptions = response));

    this.assessmentService
      .getConfidenceList()
      .subscribe((response: any) => (this.sizeConfidenceOptions = response));

    this.assessmentService
      .getStatusChangeList()
      .subscribe((response: any) => (this.statusChangeList = response));

    this.assessmentService
      .getChangeReasonList()
      .subscribe((response: any) => (this.changeReasonList = response));
  }

  private loadReport() {
    this.reportService
      .getReport(this.assessment.model.reportId)
      .subscribe((reportResponse) => {
        this.report = reportResponse;
        if (!this.currentUser) {
          throw new Error('User not loaded yet');
        }

        if (
          this.report &&
          this.report.model &&
          this.report.model.panelMembers
        ) {
          const currentPersonId = this.currentUser.personId;
          this.panelMemberStatus = this.reportService.detectPanelMemberStatus(
            this.report.model,
            currentPersonId
          );

          this.completeAvailable =
            this.editStatusIsModifiable(this.assessment.model.editStatus) &&
            (this.panelMemberStatus.isPanelChair ||
              this.panelMemberStatus.isReportAdmin);
          this.updateNameAvailable =
            this.editStatusIsModifiable(this.assessment.model.editStatus) &&
            (this.panelMemberStatus.isPanelChair ||
              this.panelMemberStatus.isReportAdmin);
          this.revertAvailable =
            this.assessment.model.editStatus === 'COMPLETED' &&
            (this.panelMemberStatus.isPanelChair ||
              this.panelMemberStatus.isReportAdmin);

          this.editAvailable =
            this.editStatusIsModifiable(this.assessment.model.editStatus) &&
            (this.panelMemberStatus.isPanelChair ||
              this.panelMemberStatus.isReportAdmin);
          this.notesDisplayed =
            this.assessment.model.notes === null ||
            this.assessment.model.notes.indexOf('[[]]') === -1; // Yes, this is a magic marker (see the service for details)

          this.commentsDisplayed =
            this.panelMemberStatus.isPanelMember ||
            this.panelMemberStatus.isPanelChair ||
            this.panelMemberStatus.isReportAdmin;
          this.addCommentAvailable =
            (this.panelMemberStatus.isPanelMember ||
              this.panelMemberStatus.isPanelChair ||
              this.panelMemberStatus.isReportAdmin) &&
            this.editStatusIsModifiable(this.assessment.model.editStatus);
          this.tagsDisplayed = this.commentsDisplayed;
          this.editTagsAvailable = this.commentsDisplayed;
          this.editNamesAvailable = this.currentUser.historicalNameEditor;
          this.editGenerationTimeAvailable =
            this.currentUser.administrator ||
            (this.editStatusIsModifiable(this.assessment.model.editStatus) &&
              (this.panelMemberStatus.isPanelChair ||
                this.panelMemberStatus.isReportAdmin));
        }
      });
  }

  private determineAvailableQualifiers() {
    // reset the available list, otherwise we get duplicates (NZTCS-189)
    this.availableQualifiers = [];

    // loop through the currently available qualifiers and add any that haven't already been selected
    for (const nextQualifier of this.qualifierList) {
      if (
        !this.assessment.model.qualifiers.find(
          (item) => item.code === nextQualifier.code
        )
      ) {
        this.availableQualifiers.push(nextQualifier);
      }
    }
  }

  private sortQualifierList(list: any[]) {
    list.sort((o1, o2) => {
      if (o1.title > o2.title) {
        return 1;
      }

      if (o1.title < o2.title) {
        return -1;
      }

      return 0;
    });
  }
}
