import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BatchControlService {
  constructor(private http: HttpClient) {}

  executeJob(jobName: string): Observable<any> {
    return this.http.get('/rest/batch/Execute/' + jobName);
  }

  refresh() {
    return this.http.get('/rest/batch/Report');
  }

  deleteData(jobName: string): Observable<any> {
    return this.http.get('/rest/batch/DeleteData/' + jobName);
  }
}
