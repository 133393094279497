import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class HomeService {
  constructor(private http: HttpClient) {}

  findNamesAndIdByNameCriteria(name: string) {
    return this.http.get<any[]>(
      'rest/assessments/getSpeciesNamesAndIds/' + name
    );
  }
}
