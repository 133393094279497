<div class="row">
  <div class="col-md-6">
    <form class="form-inline">
      <div class="form-group">
        <label class="control-label mr-3" for="speciesNameTxtFld"
          >Species Name Filter</label
        >
        <input
          type="text"
          class="form-control mr-3"
          id="speciesNameTxtFld"
          name="speciesNameTxtFld"
          [(ngModel)]="assessmentSearchCriteria.assessmentName"
          (change)="onSpeciesNameChanged()"
        />
      </div>
      <button class="btn btn-primary mr-3" (click)="onSpeciesNameChanged()">
        Filter
      </button>
      <button class="btn btn-light" (click)="onReset()">Reset</button>
    </form>
  </div>
</div>

<br />

<div class="row">
  <div class="col-md-11">
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-sm small">
        <thead>
          <tr>
            <th>Species Name</th>
            <th>Umbrella Category</th>
            <th>Conservation Status</th>
            <th>Pop. Size</th>
            <th>Pop. Trend</th>
            <th>Qualifiers</th>
            <th>Change</th>
            <th>Reason</th>
            <th>Status</th>
            <th>Select</th>
          </tr>
        </thead>
        <tbody *ngIf="assessmentSearchResponse.searchResults === null">
          <tr>
            <td colspan="12">Search not yet executed.</td>
          </tr>
        </tbody>

        <tbody *ngIf="assessmentSearchResponse.searchResults.length === 0">
          <tr>
            <td colspan="12">No search results found.</td>
          </tr>
        </tbody>

        <tbody *ngIf="assessmentSearchResponse.searchResults.length > 0">
          <tr
            *ngFor="let searchResult of assessmentSearchResponse.searchResults"
          >
            <td>
              <a routerLink="/assessments/{{ searchResult.assessmentId }}">
                <span [innerHTML]="searchResult.assessmentName"></span>
              </a>
            </td>
            <td>{{ searchResult.categoryTitle }}</td>
            <td>{{ searchResult.conservationStatusTitle }}</td>
            <td>{{ searchResult.popSizeTitle }}</td>
            <td>{{ searchResult.popTrendTitle }}</td>
            <td>{{ searchResult.qualifiers }}</td>
            <td>{{ searchResult.statusChangeTitle }}</td>
            <td>{{ searchResult.changeReasonTitle }}</td>
            <td>
              <app-status-badge
                [statusValue]="searchResult.editStatus"
              ></app-status-badge>
              <app-status-badge
                [statusValue]="searchResult.publishedStatus"
              ></app-status-badge>
            </td>
            <td>
              <div class="form-group">
                <input
                  class="form-check m-auto"
                  type="checkbox"
                  id="check2"
                  [(ngModel)]="searchResult.selected"
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row">
        <div class="col-md-6">
          <pagination
            [maxSize]="5"
            [itemsPerPage]="assessmentSearchCriteria.pageSize"
            [(ngModel)]="assessmentSearchCriteria.pageNumber"
            [totalItems]="assessmentSearchResponse.total"
            (pageChanged)="onPageChanged($event)"
          >
          </pagination>
        </div>
        <div class="col-md-6 text-right">
          Total Results: {{ assessmentSearchResponse.total }}
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-1">
    <button
      class="btn btn-sm btn-success btn-block"
      (click)="onAddSpecies()"
      [disabled]="!modifable"
    >
      Add
    </button>
    <button
      class="btn btn-sm btn-danger btn-block"
      (click)="onConfirmRemoveAssessments()"
      [disabled]="getSelectedAssessmentsList().length === 0 || !modifable"
    >
      Remove
    </button>
  </div>
</div>

<app-species-selector
  #speciesSelectorComponent
  (speciesSelectorEvent)="onSpeciesSelectorEvent($event)"
></app-species-selector>

<app-message-dialog
  #messageDialog
  [header]="'Confirm Remove'"
  [messageText]="
    'Are you sure you want to remove these Assessment records. This action cannot be undone.'
  "
  [actionLabel]="'Remove'"
  [actionClass]="'btn btn-danger'"
  (actionEvent)="onDeleteAssessmentsActionEvent($event)"
>
</app-message-dialog>
