import { Component, OnInit } from '@angular/core';
import { User } from '../../authentication/service/user';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { AttachmentService } from '../service/attachment.service';
import { AttachmentSearchCriteria } from '../service/attachment-search-criteria';

@Component({
  selector: 'app-attachment-search',
  templateUrl: './attachment-search.component.html',
  styleUrls: ['./attachment-search.component.scss'],
})
export class AttachmentSearchComponent implements OnInit {
  currentUser: User;

  criteria = new AttachmentSearchCriteria();
  response: any;

  editIcon = faPencilAlt;

  constructor(
    private router: Router,
    private attachmentService: AttachmentService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.criteria.pageNumber = event.page;
    this.searchAction();
  }

  clearAction() {
    this.criteria = new AttachmentSearchCriteria();
    this.searchAction();
  }

  searchAction() {
    this.attachmentService
      .findByCriteria(this.criteria)
      .subscribe((response) => (this.response = response));
  }

  uploadAction() {
    this.router.navigate(['file-upload']);
  }

  editAction(content: any) {
    this.router.navigate(['content-edit/' + content.name]);
  }

  downloadAttachmentAction(event: Event, filename: string) {
    event.preventDefault();
    window.location.href = '/rest/attachment/files/' + filename;
  }
}
