import { Component, OnInit } from '@angular/core';
import { User } from '../../authentication/service/user';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { NztcsTaxonSearchCtxServiceService } from './nztcs-taxon-search-ctx-service.service';
import { NztcsTaxonEntityService } from '../service/nztcs-taxon-entity.service';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-taxon-search',
  templateUrl: './taxon-search.component.html',
})
export class TaxonSearchComponent implements OnInit {
  currentUser: User;
  nameEditor = false;

  taxonRanks: string[];
  speciesTypes: string[];

  constructor(
    public searchCtx: NztcsTaxonSearchCtxServiceService,
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private nztcsSpeciesService: NztcsSpeciesService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.nameEditor = this.currentUser && this.currentUser.nameEditor;
    });
    this.taxonRanks = this.nztcsTaxonEntityService.getTaxonRanks();
    this.speciesTypes = this.nztcsSpeciesService.getSpeciesTypes();
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.searchCtx.criteria.pageNumber = event.page;
    this.searchAction();
  }

  clearAction() {
    this.searchCtx.clear();
    this.searchAction();
  }

  searchAction() {
    this.searchCtx.search();
  }

  newAction() {
    this.router.navigate(['nztcs-taxon-edit/-1']);
  }
}
