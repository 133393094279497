import { Component, OnInit, ViewChild } from '@angular/core';
import { CodeValueService } from '../service/code-value.service';
import { CodeValueEditComponent } from '../code-value-edit/code-value-edit.component';
import { CodeType } from './code-type';
import {
  faPause,
  faPencilAlt,
  faPlay,
  faPlus,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-code-value-view',
  templateUrl: './code-value-view.component.html',
})
export class CodeValueViewComponent implements OnInit {
  @ViewChild('codeValueEditComponent', { static: true })
  codeValueEditComponent: CodeValueEditComponent;

  editableCodeTypes;
  selectedCodeType = new CodeType('CHANGE_REASON', 'Change Reason');

  codeValues;

  newIcon = faPlus;
  activateIcon = faPlay;
  deactivateIcon = faPause;
  editIcon = faPencilAlt;
  deleteIcon = faTrashAlt;

  constructor(private codeValueService: CodeValueService) {}

  ngOnInit() {
    this.codeValueService.getEditableCodeTypes().subscribe((response) => {
      this.editableCodeTypes = response;
    });

    this.loadCodeValueMetaData();
  }

  loadCodeValueMetaData() {
    this.codeValueService
      .getCodeValueMetaData(this.selectedCodeType.code)
      .subscribe((response) => {
        this.codeValues = response;
      });
  }

  updateSelectedCodeType($event: Event) {
    this.loadCodeValueMetaData();
  }

  codeValueUpdatedEvent($event: Event) {
    this.loadCodeValueMetaData();
  }

  changeActive(codeValue: any, active: boolean) {
    codeValue.active = active;
    // TODO: send update to server
    this.codeValueService.saveCodeValue(codeValue).subscribe((response) => {
      this.loadCodeValueMetaData();
    });
  }

  addNewCodeValueAction() {
    const newCodeValue = {
      objectId: null,
      active: true,
      code: null,
      codeType: this.selectedCodeType.code,
      description: null,
      inUse: false,
      title: null,
    };

    this.codeValueEditComponent.startEdit(newCodeValue);
  }

  editCodeValueAction(codeValue: any) {
    console.log('editCodeValueAction(): ' + JSON.stringify(codeValue));
    this.codeValueEditComponent.startEdit(codeValue);
  }

  deleteCodeValueAction(codeValue: any) {
    this.codeValueService.removeCodeValue(codeValue).subscribe((response) => {
      this.loadCodeValueMetaData();
    });
  }

  compareOnCode(o1: any, o2: any) {
    return o1 && o2 && o1.code && o2.code && o1.code === o2.code;
  }
}
