import { Component, OnInit } from '@angular/core';
import { ContentService } from '../service/content.service';
import { User } from '../../authentication/service/user';
import { AuthenticationService } from '../../authentication/service/authentication.service';

@Component({
  selector: 'app-content-links',
  templateUrl: './content-links.component.html',
})
export class ContentLinksComponent implements OnInit {
  contentLinks: any;

  currentUser: User;

  constructor(
    private contentService: ContentService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });

    this.contentService.findContentLinks().subscribe((response) => {
      this.contentLinks = response;
    });
  }

  shouldDisplayLink(link: any) {
    if (link) {
      switch (link.visibility) {
        case 'HIDDEN_LINK':
          return false;
        case 'PUBLIC':
          return true;
        case 'AUTHENTICATED':
          return this.currentUser != null && this.currentUser.token;
        case 'ADMIN':
          return (
            this.currentUser != null &&
            this.currentUser.token &&
            this.currentUser.administrator
          );
      }
    } else {
      return false;
    }
  }
}
