import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SpeciesNameSearchCriteria } from './species-name-search-criteria';
import { Observable } from 'rxjs';
import { SpeciesNameSearchResult } from './species-name-search-result';

@Injectable()
export class SpeciesNameService {
  constructor(private http: HttpClient) {}

  findSpeciesNames(criteria: SpeciesNameSearchCriteria): Observable<any> {
    return this.http.post<SpeciesNameSearchResult>(
      '/rest/species/names',
      criteria
    );
  }
}
