<div class="card">
  <div class="card-header">
    <h4>Species Details</h4>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="col-md-4"><b>Id</b></div>
      <div class="col-md-8">{{ species.speciesId }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Scientific Name</b></div>
      <div class="col-md-8">{{ species.scientificName }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Naming Authority</b></div>
      <div class="col-md-8">{{ species.namingAuthority }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Trinomial Type</b></div>
      <div class="col-md-8">{{ species.trinomialType }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Trinomial</b></div>
      <div class="col-md-8">{{ species.trinomial }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Taxonomic Status</b></div>
      <div class="col-md-8">{{ species.taxonomicStatus }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Preferred Common Name</b></div>
      <div class="col-md-8">{{ species.commonName }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Preferred Māori Name</b></div>
      <div class="col-md-8">{{ species.maoriName }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Alternative Common Names</b></div>
      <div class="col-md-8">{{ alternativeCommonNames }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Species Type</b></div>
      <div class="col-md-8">{{ species.speciesType }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Bio Status</b></div>
      <div
        class="col-md-8"
        *ngIf="nztcsBioStatusMap && species.bioStatusCategory"
      >
        {{ getBioStatus(species.bioStatusCategory) }}
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Environment</b></div>
      <div class="col-md-8">{{ species.environment }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Generation Time</b></div>
      <div class="col-md-8">
        <span *ngIf="!editInProgress">
          <span *ngIf="nztcsSpecies && nztcsSpecies.generationTimeInYears">
            {{ nztcsSpecies.generationTimeInYears }} (years)
          </span>
          <span *ngIf="editGenerationTimeAvailable">
            <button
              type="button"
              class="btn btn-sm btn-success"
              (click)="editAction()"
              tooltip="Edit the generation time for this Species."
            >
              <fa-icon [icon]="editIcon"></fa-icon>
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              (click)="removeAction()"
              tooltip="Remove the generation time from this Species."
            >
              <fa-icon [icon]="removeIcon"></fa-icon>
            </button>
          </span>
        </span>

        <span *ngIf="editInProgress">
          <div class="row">
            <div class="col-md-4">
              <input
                type="text"
                class="form-control"
                id="generationTimeInYearsTxtFld"
                name="generationTimeInYearsTxtFld"
                focus="editInProgress"
                (keyup.enter)="saveAction()"
                (keyup.esc)="cancelAction()"
                [(ngModel)]="nztcsSpecies.generationTimeInYears"
              />
            </div>
            <div class="col-md-8">
              <button
                type="button"
                class="btn btn-sm btn-success"
                (click)="saveAction()"
              >
                <fa-icon [icon]="saveIcon"></fa-icon>
              </button>
              <button
                type="button"
                class="btn btn-sm btn-light"
                (click)="cancelAction()"
              >
                <fa-icon [icon]="cancelIcon"></fa-icon>
              </button>
            </div>
          </div>
          <div
            class="alert alert-danger"
            role="alert"
            *ngFor="let msg of responseMessages"
          >
            {{ msg.description }}
          </div>
        </span>
      </div>
    </div>

    <div class="row" *ngIf="authenticationService.isLoggedIn()">
      <div class="col-md-4"><b>Notes</b></div>
      <div class="col-md-8">
        <p *ngFor="let line of species.notes | newlines">{{ line }}</p>
      </div>
    </div>
  </div>
</div>
