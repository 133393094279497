import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import {
  faCheck,
  faPencilAlt,
  faTimes,
  faUndoAlt,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-species-detail',
  templateUrl: './species-detail.component.html',
})
export class SpeciesDetailComponent implements OnInit, OnChanges {
  @Input()
  species: any;

  @Input()
  editGenerationTimeAvailable = true;

  @Output()
  nztcsSpeciesEvent = new EventEmitter<string>();

  alternativeCommonNames: string;

  nztcsBioStatusMap: any;

  nztcsSpecies: NztcsSpeciesEntity;
  editInProgress = false;
  responseMessages = [];

  editIcon = faPencilAlt;
  removeIcon = faTimes;
  saveIcon = faCheck;
  cancelIcon = faUndoAlt;

  constructor(
    private nztcsSpeciesService: NztcsSpeciesService,
    public authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.species) {
      this.loadNztcsSpecies();
      this.loadNztcsBioStatusMap();
      this.concatAlternativeCommonNames();
    }
  }

  loadNztcsSpecies() {
    this.nztcsSpeciesService
      .getNztcsSpecies(this.species.speciesId)
      .subscribe((response) => {
        this.nztcsSpecies = response;
        this.responseMessages = [];
      });
  }

  loadNztcsBioStatusMap() {
    this.nztcsSpeciesService
      .getNztcsBioStatusMap()
      .subscribe((map) => (this.nztcsBioStatusMap = map));
  }

  concatAlternativeCommonNames() {
    // NZTCS-39 scientific name, preferred common name, preferred Maori name should not be displayed in this field.
    // NZTCS-178 only C Other common name and O Other Maori name should be displayed
    const filteredNameTypes = ['C', 'O'];

    const alternativeNames: string[] = [];
    this.species.speciesAlternativeName.forEach((item) => {
      // If filtered name then add it. Filtered names are already displayed on the page under other fields.
      if (filteredNameTypes.indexOf(item.alternativeNameType) >= 0) {
        alternativeNames.push(item.alternativeName);
      }
    });
    this.alternativeCommonNames = alternativeNames.join(', ');
  }

  editAction() {
    if (this.nztcsSpecies == null) {
      this.nztcsSpecies = new NztcsSpeciesEntity();
    }
    this.editInProgress = true;
  }

  saveAction() {
    // only assign the speciesId when we save it, see the cancelAction() below
    this.nztcsSpecies.nztcsSpeciesId = this.species.speciesId;
    this.nztcsSpeciesService
      .saveNztcsSpecies(this.nztcsSpecies)
      .subscribe((response: any) => {
        this.responseMessages = response.responseMessages;
        if (this.responseMessages.length === 0) {
          this.editInProgress = false;
          this.nztcsSpeciesEvent.emit('NZTCSSpeciesUpdated');
        }
      });
  }

  cancelAction() {
    // reload to throw away any unsaved changes
    this.loadNztcsSpecies();
    this.editInProgress = false;
  }

  removeAction() {
    this.nztcsSpeciesService
      .removeNztcsSpecies(this.species.speciesId)
      .subscribe(() => {
        console.log('removeAction()');
        this.loadNztcsSpecies();
      });
  }

  getBioStatus(bioStatusCategory: string) {
    let bioStatus = 'error';
    if (this.nztcsBioStatusMap[bioStatusCategory]) {
      bioStatus = this.nztcsBioStatusMap[bioStatusCategory];
    }
    return bioStatus;
  }
}
