<br />
<div class="row">
  <div class="col-md-6">
    <div class="mb-3">
      <app-content-view [contentName]="'HOME_LEFT'"></app-content-view>
    </div>

    <div class="card mb-5">
      <div class="card-header">
        <img
          src="/assets/DOC_logo_horizontal_long.png"
          width="230"
          height="43"
          alt="The Deparment of Conservation logo"
        />
      </div>
      <div class="card-body">
        <small
          ><i
            >The New Zealand Threat Classification System is administered by the
            Department of Conservation. Please contact threatstatus@doc.govt.nz
            for enquiries about the NZTCS.</i
          ></small
        >
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <br />
    <app-home-quick-search></app-home-quick-search>
    <br />

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">
          New Zealand Threat Classification System categories
        </h5>
      </div>
      <div class="card-body">
        <!--<img src="/assets/threatened-vs-endangered.gif">-->
        <app-content-view [contentName]="'HOME_CATEGORIES'"></app-content-view>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Current Statistics</h5>
      </div>
      <div class="card-body">
        <app-umbrella-category-view></app-umbrella-category-view>
        <p class="text-right">
          <small><i>For all currently published assessments.</i></small>
        </p>
      </div>
    </div>

    <br />

    <div class="card">
      <div class="card-header">
        <h5 class="card-title">Data Export</h5>
      </div>
      <div class="card-body">
        <p>
          The following button will export all currently published assessment
          data into a downloadable Excel spreadsheet. This action can take a few
          minutes to execute.
        </p>
        <button class="btn btn-outline-success" (click)="onExportAllData()">
          Export All Data
        </button>
      </div>
    </div>

    <br />

    <div class="card mb-5">
      <div class="card-header">
        <h4 class="card-title">Links</h4>
      </div>
      <div class="card-body">
        <app-content-view [contentName]="'HOME_LINKS'"></app-content-view>
      </div>
    </div>
  </div>
</div>
