import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AssessmentSearchCriteria } from './assessment-search-criteria';

@Injectable()
export class AssessmentExportService {
  constructor(private http: HttpClient) {}

  refresh() {
    return this.http.get('/rest/assessmentExport/list');
  }

  startExport(criteria: AssessmentSearchCriteria) {
    return this.http.post('/rest/assessmentExport', criteria);
  }
}
