import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NztcsTaxonEntityDTO } from './nztcs-taxon-entity-dto';
import { HttpClient } from '@angular/common/http';
import { NztcsTaxonSearchCriteria } from '../taxon-search/nztcs-taxon-search-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { NztcsTaxonEntity } from '../../domain/nztcs/nztcs-taxon-entity';
import { NztcsTaxonName } from '../../domain/nztcs/nztcs-taxon-name';

@Injectable({
  providedIn: 'root',
})
export class NztcsTaxonEntityService {
  constructor(private http: HttpClient) {}

  public findNztcsTaxonEntityDTO(
    nztcsTaxonId: number
  ): Observable<NztcsTaxonEntityDTO> {
    return this.http.get<NztcsTaxonEntityDTO>(
      '/rest/nztcsTaxon/findNztcsTaxonEntityDTO/' + nztcsTaxonId
    );
  }

  public findByCriteria(
    searchCriteria: NztcsTaxonSearchCriteria
  ): Observable<PagedResponse> {
    return this.http.post<PagedResponse>(
      '/rest/nztcsTaxon/findNztcsTaxonEntityByCriteria',
      searchCriteria
    );
  }

  public findNztcsTaxonEntityByPrimaryKey(
    nztcsTaxonId: number
  ): Observable<NztcsTaxonEntity> {
    return this.http.get<NztcsTaxonEntity>('/rest/nztcsTaxon/' + nztcsTaxonId);
  }

  public findNztcsTaxonNameEntityByPrimaryKey(
    nztcsTaxonId: number
  ): Observable<NztcsTaxonName> {
    return this.http.get<NztcsTaxonName>(
      '/rest/nztcsTaxon/nztcsTaxonName/' + nztcsTaxonId
    );
  }

  public saveNztcsTaxonEntity(
    nztcsTaxonEntity: NztcsTaxonEntity
  ): Observable<any> {
    return this.http.post('/rest/nztcsTaxon/', nztcsTaxonEntity);
  }

  public deleteNztcsTaxonEntity(nztcsTaxonId: number) {
    return this.http.delete('/rest/nztcsTaxon/' + nztcsTaxonId);
  }

  public getTaxonRanks(): string[] {
    return ['Genus', 'Family', 'Order', 'Class', 'Phylum', 'Kingdom'];
  }

  public getTaxonomicStatusList(): string[] {
    return [
      'Taxonomically Determinate',
      'Taxonomically Indistinct',
      'Taxonomically Unresolved',
    ];
  }

  public isMoveValid(fromRank: string, toRank: string) {
    if (fromRank && toRank) {
      const fromIdx = this.getTaxonRanks().indexOf(fromRank);
      const toIdx = this.getTaxonRanks().indexOf(toRank);
      return fromIdx >= 0 && toIdx >= 0 && fromIdx === toIdx - 1;
    }
    return false;
  }

  public findAllChildren(nztcsTaxonId: number): Observable<NztcsTaxonEntity[]> {
    return this.http.get<NztcsTaxonEntity[]>(
      '/rest/nztcsTaxon/findAllChildren/' + nztcsTaxonId
    );
  }

  public findAllChildrenSpeciesTypeMap(nztcsTaxonId: number): Observable<any> {
    return this.http.get<any>(
      '/rest/nztcsTaxon/findAllChildrenSpeciesTypeMap/' + nztcsTaxonId
    );
  }

  public changeSpeciesType(nztcsTaxonId: number, speciesType: string) {
    return this.http.post(
      '/rest/nztcsTaxon/changeSpeciesType/' + nztcsTaxonId + '/' + speciesType,
      null
    );
  }

  public moveTaxon(childId: number, parentId: number): Observable<any> {
    return this.http.post(
      '/rest/nztcsTaxon/moveTaxon/' + childId + '/' + parentId,
      null
    );
  }
}
