import { Component, Input, OnInit } from '@angular/core';
import { NztcsTaxonEntityService } from '../service/nztcs-taxon-entity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../authentication/service/user';
import { NztcsTaxonEntity } from '../../domain/nztcs/nztcs-taxon-entity';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { NztcsTaxonEntityDTO } from '../service/nztcs-taxon-entity-dto';

@Component({
  selector: 'app-taxon-name-edit',
  templateUrl: './taxon-name-edit.component.html',
})
export class TaxonNameEditComponent implements OnInit {
  @Input()
  nztcsTaxonId: number;

  taxon: NztcsTaxonEntity;
  taxonDto: NztcsTaxonEntityDTO;

  taxonRanks: string[];
  speciesTypeList: string[];

  responseMessages: any[];

  currentUser: User;

  constructor(
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private nztcsSpeciesService: NztcsSpeciesService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.nztcsTaxonId = +params['nztcsTaxonId'];
      if (this.nztcsTaxonId > 0) {
        this.loadNztcsTaxonEntityDTO();
      } else {
        this.newNztcsTaxon();
      }
    });

    this.taxonRanks = this.nztcsTaxonEntityService.getTaxonRanks();
    this.speciesTypeList = this.nztcsSpeciesService.getSpeciesTypes();
  }

  saveAction() {
    // save the NztcsTaxonEntity
    this.nztcsTaxonEntityService
      .saveNztcsTaxonEntity(this.taxon)
      .subscribe((response) => {
        this.responseMessages = response.responseMessages;
        if (this.responseMessages.length === 0) {
          this.nztcsTaxonId = response.model.nztcsTaxonId;
          this.router.navigate(['/nztcs-taxon/' + this.nztcsTaxonId]);
        }
      });
  }

  cancelAction() {
    if (this.nztcsTaxonId > 0) {
      this.router.navigate(['/nztcs-taxon/' + this.nztcsTaxonId]);
    } else {
      this.router.navigate(['/nztcs-taxon-search']);
    }
  }

  private loadNztcsTaxonEntityDTO() {
    if (this.nztcsTaxonId) {
      this.nztcsTaxonEntityService
        .findNztcsTaxonEntityByPrimaryKey(this.nztcsTaxonId)
        .subscribe((taxon) => {
          this.taxon = taxon;
        });

      this.nztcsTaxonEntityService
        .findNztcsTaxonEntityDTO(this.nztcsTaxonId)
        .subscribe((dto) => (this.taxonDto = dto));
    }
  }

  private newNztcsTaxon() {
    this.taxon = new NztcsTaxonEntity();
  }
}
