<div class="card" *ngIf="nzorName">
  <div class="card-header">
    <h4>NZOR Details</h4>
  </div>

  <div class="card-body">
    <div class="row">
      <div class="col-md-4"><b>Formatted Name</b></div>
      <div class="col-md-8">
        <span [innerHTML]="nzorName.formattedFullName"></span>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Taxonomy</b></div>
      <div class="col-md-8">
        <app-nzortaxon-name-list
          [nzorName]="nzorName"
        ></app-nzortaxon-name-list>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>NZOR Id</b></div>
      <div class="col-md-8">
        <small
          ><a
            target="_blank"
            [attr.href]="'http://nzor.org.nz/names/' + nzorId"
            >{{ nzorId }}</a
          ></small
        >
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Common Names</b></div>
      <div class="col-md-8">{{ nzorCommonNames.model }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Bio Status</b></div>
      <div class="col-md-8" *ngIf="nzorName">{{ nzorName.biostatus }}</div>
    </div>
  </div>
</div>
