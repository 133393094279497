import { Injectable } from '@angular/core';
import { AssessmentSearchCriteria } from './assessment-search-criteria';
import { HttpClient } from '@angular/common/http';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AssessmentService } from './assessment.service';

@Injectable()
export class AssessmentSearchCtxService {
  public criteria = new AssessmentSearchCriteria();

  public response: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  constructor(
    private http: HttpClient,
    private router: Router,
    private assessmentService: AssessmentService
  ) {}

  search() {
    this.assessmentService
      .findByCriteria(this.criteria)
      .subscribe((pagedResponse: PagedResponse) => {
        this.response = pagedResponse;
        this.response.numberOfPages = Math.ceil(
          pagedResponse.total / this.criteria.pageSize
        );
      });
  }

  homeSearch(): Observable<any> {
    return this.assessmentService.findByCriteria(this.criteria);
  }

  navigatePrevAvailable(fromAssessmentId: number): boolean {
    const searchIdx = this.findSearchIdx(fromAssessmentId);
    const available =
      searchIdx > -1 && (searchIdx > 0 || this.criteria.pageNumber > 1);
    return available;
  }

  navigateNextAvailable(fromAssessmentId: number): boolean {
    const searchIdx = this.findSearchIdx(fromAssessmentId);
    const available =
      searchIdx > -1 &&
      (searchIdx < this.response.searchResults.length - 1 ||
        this.criteria.pageNumber < this.response.numberOfPages);
    return available;
  }

  navigateByRow(direction: number, fromAssessmentId: number) {
    const searchIdx = this.findSearchIdx(fromAssessmentId);
    if (searchIdx > -1) {
      const nextIdx = searchIdx + direction;
      if (nextIdx < 0) {
        // previous page
        this.navigateByPage(-1);
      } else if (nextIdx >= this.response.searchResults.length) {
        // next page
        this.navigateByPage(+1);
      } else {
        const nextAssessmentId =
          this.response.searchResults[nextIdx].assessmentId;
        this.router.navigate(['/assessments/' + nextAssessmentId]);
      }
    }
  }

  navigateByPage(direction: number) {
    const nextPageNumber = this.criteria.pageNumber + direction;
    if (nextPageNumber >= 1 && nextPageNumber <= this.response.numberOfPages) {
      this.criteria.pageNumber = nextPageNumber;
      this.assessmentService
        .findByCriteria(this.criteria)
        .subscribe((pagedResponse: PagedResponse) => {
          this.response = pagedResponse;
          this.response.numberOfPages = Math.ceil(
            pagedResponse.total / this.criteria.pageSize
          );

          const lastRowIdx = this.response.searchResults.length - 1;
          const lastRowAssessmentId =
            this.response.searchResults[lastRowIdx].assessmentId;
          this.router.navigate(['/assessments/' + lastRowAssessmentId]);
        });
    }
  }

  findSearchIdx(assessmentId: number): number {
    let searchIdx = -1;
    for (
      let nextIdx = 0;
      nextIdx < this.response.searchResults.length;
      nextIdx++
    ) {
      if (
        this.response.searchResults[nextIdx] &&
        this.response.searchResults[nextIdx].assessmentId === assessmentId
      ) {
        searchIdx = nextIdx;
        break;
      }
    }

    return searchIdx;
  }

  clear() {
    this.criteria = new AssessmentSearchCriteria();
  }
}
