<div class="row">
  <div class="col-md-12 mb-2">
    <h4>File Upload</h4>
  </div>

  <div class="col-md-6 mb-2">
    <div
      style="height: 60px"
      ng2FileDrop
      [ngClass]="{ 'nv-file-over': hasBaseDropZoneOver }"
      (fileOver)="fileOverBase($event)"
      [uploader]="uploader"
      class="well my-drop-zone"
    >
      Drop files here
    </div>
  </div>
  <div class="col-md-6 mb-2">
    Or, select one or more files
    <input
      type="file"
      [id]="'files-' + componentId"
      class="hidden"
      ng2FileSelect
      [uploader]="uploader"
      multiple
    />
    <label class="btn btn-primary" [for]="'files-' + componentId">Select file</label>
  </div>
</div>

<div class="row" *ngIf="uploader.queue.length > 0">
  <div class="col-md-12">
    <table class="table">
      <thead>
        <tr>
          <th width="50%">Name</th>
          <th>Size</th>
          <th>Progress</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of uploader.queue">
          <td>
            <strong>{{ item?.file?.name }}</strong>
          </td>
          <td *ngIf="uploader.options.isHTML5" nowrap>
            {{ item?.file?.size / 1024 / 1024 | number: '.2' }} MB
          </td>
          <td *ngIf="uploader.options.isHTML5">
            <div class="progress" style="margin-bottom: 0">
              <div
                class="progress-bar"
                role="progressbar"
                [ngStyle]="{ width: item.progress + '%' }"
              ></div>
            </div>
          </td>
          <td nowrap>
            <button
              type="button"
              class="btn btn-success btn-sm"
              (click)="uploadAction(item)"
              [disabled]="item.isReady || item.isUploading || item.isSuccess"
            >
              <span class="glyphicon glyphicon-upload"></span> Upload
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              (click)="removeFile(item)"
              title="Clear upload"
            >
              <fa-icon [icon]="removeIcon"></fa-icon>
            </button>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="row" *ngIf="response">
  <div class="col-md-12">
    <div class="panel panel-default">
      <div [class]="'panel-body alert alert-' + responseType">
        {{ response }}
        <button
          type="button"
          class="btn btn-sm"
          style="float: right; line-height: 1"
          (click)="clearResponse()"
        >
          <fa-icon [icon]="removeIcon"></fa-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<app-message-dialog
  #confirmUploadDialog
  [header]="'Confirm Upload'"
  [actionLabel]="'Upload'"
  [actionClass]="'btn btn-warning'"
  (actionEvent)="uploadActionConfirmed($event)"
>
</app-message-dialog>

<app-message-dialog
  #fileErrorDialog
  [header]="'File Error'"
  [actions]="[{
    label: 'Ok',
    handler: closeFileErrors
  }]"
>
  <p>The following file{{erroredFiles.length > 1 ? 's' : ''}} could not be added as it is not allowed in NZTCS:</p>
  <ul>
    <li *ngFor="let file of erroredFiles">{{file}}</li>
  </ul>
  <p>You can only upload files of the following types:</p>
  <ul>
    <li>Word document</li>
    <li>Excel worksheet</li>
    <li>CSV</li>
    <li>PDF</li>
    <li>JPEG image</li>
    <li>PNG image</li>
  </ul>
</app-message-dialog>
