/* eslint-disable @typescript-eslint/naming-convention */
export class UmbrellaCategories {
  public static VALUES = [
    'At Risk',
    'Data Deficient',
    'Extinct',
    'Introduced and Naturalised',
    'Non-resident Native',
    'Not assessed',
    'Not listed',
    'Not Threatened',
    'Taxonomically indistinct',
    'Threatened',
    'Is Blank',
  ];
}
