import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthenticationRequest } from './authentication-request';
import { AuthenticationResponse } from './authentication-response';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from './user';
import { LoginRequestEvent } from './login-request-event';

@Injectable()
export class AuthenticationService {
  public currentUserSource = new BehaviorSubject<any>(null);
  public currentUser$ = this.currentUserSource.asObservable();

  public loginRequestSource = new BehaviorSubject<any>(null);
  public loginRequest$ = this.loginRequestSource.asObservable();

  constructor(
    private http: HttpClient,
    private jwtHelperService: JwtHelperService
  ) {
    // set token if saved in local storage
    const nextUser = JSON.parse(localStorage.getItem('currentUser'));

    // Note: although we're loading the authorities from an untrusted source
    // hacking these to give yourself elevated privileges still won't achieve
    // very much because it's the token that sent through to the server to get
    // the data and that can't be tampered with because it's digitally signed.
    this.setCurrentUser(nextUser);
  }

  setCurrentUser(currentUser: any) {
    if (!currentUser) {
      currentUser = new User();
    }

    this.currentUserSource.next(currentUser);
  }

  login(username: string, password: string) {
    const authenticationRequest = new AuthenticationRequest(username, password);
    return this.http.post('/rest/authenticate', authenticationRequest).pipe(
      map((authenticationResponse: AuthenticationResponse) => {
        // login successful if there's a jwt token in the response
        if (authenticationResponse) {
          // set token property
          const token = authenticationResponse.token;
          const decodedToken = this.jwtHelperService.decodeToken(token);
          const authorities = decodedToken.authorities.split(',');

          const currentUser = new User();
          currentUser.name = decodedToken.name;
          currentUser.personId = decodedToken.personId;
          currentUser.token = token;
          currentUser.authorities = authorities;
          currentUser.updatePermissions();

          this.setCurrentUser(currentUser);

          console.log('CurrentUser = ' + JSON.stringify(currentUser));

          // store username and jwt token in local storage to keep user logged in between page refreshes
          localStorage.setItem('currentUser', JSON.stringify(currentUser));

          // return true to indicate successful login
          return true;
        } else {
          // return false to indicate failed login
          return false;
        }
      })
    );
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.setCurrentUser(null);
  }

  isLoggedIn() {
    const currentUserNow = this.currentUserSource.getValue();
    return currentUserNow && currentUserNow.token;
  }

  startLogin(targetUrl: string, optionalMessage?: string) {
    this.loginRequestSource.next(
      new LoginRequestEvent(targetUrl, optionalMessage)
    );
  }

  getAuthToken() {
    if (this.isLoggedIn()) {
      return this.currentUserSource.getValue().token;
    }
  }
}
