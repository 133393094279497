import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { AuthenticationService } from '../service/authentication.service';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';

// non-automatic imports...

import { LoginRequestEvent } from '../service/login-request-event';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  @ViewChild('loginModal', { static: true })
  loginModal: ModalDirective;

  @Output()
  loginEvent = new EventEmitter();

  targetUrl: string;
  eventMessage: string;
  model: any = {};
  loading = false;
  error: string;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}

  startLogin(loginRequestEvent: LoginRequestEvent) {
    if (loginRequestEvent) {
      this.loading = false;
      this.error = null;
      this.targetUrl = loginRequestEvent.targetUrl;
      this.eventMessage = loginRequestEvent.optionalMessage;


      // always reset any currently active login
      this.authenticationService.logout();
      this.loginModal.show();
    }
    // else ignore null events from application startup
  }

  login() {
    this.loading = true;
    this.authenticationService
      .login(this.model.username, this.model.password)
      .subscribe(
        (response) => {
          this.loading = false;

          if (response) {
            this.endLogin();
          } else {
            this.error = 'Username or password is incorrect';
          }
        },
        (errorResponse) => {
          this.loading = false;
          this.error = 'Username or password is incorrect';
        }
      );
  }

  endLogin() {
    this.loginModal.hide();

    // This is a bit of a hack but seems to be the best solution available at the moment.
    // https://stackoverflow.com/questions/39396075/how-to-reload-the-component-of-same-url-in-angular-2
    this.router.navigateByUrl('/DummyUrl').then(() => {
      this.router.navigateByUrl(this.targetUrl);
    });
  }

  onCancelLogin() {
    this.loginModal.hide();
  }
}
