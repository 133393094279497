<div class="row">
  <div class="col-md-8">
    <editor
      #editorComponent
      [init]="editorSettings"
      [(ngModel)]="text"
    ></editor>
  </div>

  <div class="col-md-4" *ngIf="attachmentsEnabled">
    <h3>Attachments</h3>

    <p>Select the content text on left before clicking "Link" below.</p>

    <div class="row" *ngIf="attachmentsResponse">
      <div class="col-md-12">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-sm small">
            <thead>
              <tr>
                <th>Actions</th>
                <th>Filename</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody *ngIf="attachmentsResponse.searchResults.length > 0">
              <tr *ngFor="let attachment of attachmentsResponse.searchResults">
                <td>
                  <button
                    class="btn btn-sm btn-secondary mr-2 mb-1"
                    (click)="onInsertLinkAction(attachment)"
                    [tooltip]="'Insert link'"
                  >
                    <fa-icon [icon]="linkIcon"></fa-icon>
                  </button>
                  <button
                    class="btn btn-sm btn-secondary"
                    (click)="onInsertImageAction(attachment)"
                    [tooltip]="'Insert image'"
                  >
                    <fa-icon [icon]="imageIcon"></fa-icon>
                  </button>
                </td>
                <td>
                  <a
                    [attr.href]="
                      '/rest/attachment/files/' + attachment.filename
                    "
                    >{{ attachment.filename }}</a
                  >
                </td>
                <td>
                  <button
                    class="btn btn-sm btn-danger"
                    (click)="onDeleteAttachment(attachment)"
                    [tooltip]="'Delete attachment'"
                  >
                    <fa-icon [icon]="deleteIcon"></fa-icon>
                  </button>
                </td>
              </tr>
            </tbody>

            <tbody *ngIf="attachmentsResponse.searchResults.length === 0">
              <td colspan="100">No attachments found.</td>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <app-file-upload
      [context]="context"
      (fileUploadEvent)="attachmentsChanged()"
      [publicallyVisible]="publicallyVisible"
    ></app-file-upload>
  </div>

  <div class="col-md-4" *ngIf="!attachmentsEnabled">
    <h3>Attachments</h3>

    <p>Attachments disabled until content has been saved for the first time.</p>
  </div>
</div>
