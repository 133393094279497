<div
  bsModal
  #loginModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Login</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCancelLogin()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <alert type="warning" *ngIf="eventMessage">{{ eventMessage }}</alert>

        <div class="row">
          <div class="col-md-6">
            <form
              name="form"
              (ngSubmit)="f.form.valid && login()"
              #f="ngForm"
              novalidate
            >
              <div
                class="form-group"
                [ngClass]="{ 'has-error': f.submitted && !username.valid }"
              >
                <label for="loginUsername">Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="loginUsername"
                  name="username"
                  [(ngModel)]="model.username"
                  #username="ngModel"
                  required
                />
                <div *ngIf="f.submitted && !username.valid" class="help-block">
                  Username is required
                </div>
              </div>
              <div
                class="form-group"
                [ngClass]="{ 'has-error': f.submitted && !password.valid }"
              >
                <label for="password">Password</label>
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  name="password"
                  [(ngModel)]="model.password"
                  #password="ngModel"
                  required
                />
                <div *ngIf="f.submitted && !password.valid" class="help-block">
                  Password is required
                </div>
              </div>
              <div class="form-group">
                <button [disabled]="loading" class="btn btn-primary">
                  Login
                </button>
                <app-wait-spinner [showSpinner]="loading"></app-wait-spinner>
              </div>
              <div *ngIf="error" class="alert alert-danger">{{ error }}</div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
