<div
  (mouseenter)="onShowEditButton(true)"
  (mouseleave)="onShowEditButton(false)"
>
  <span [innerHTML]="unsafeHtml" *ngIf="content"></span>
  <div class="a-small-note" *ngIf="content && content.lastUpdated">
    Last updated:&nbsp;{{ content.lastUpdated | date: 'd-MMM-yyyy' }}
  </div>
  <fa-icon
    [icon]="editIcon"
    (click)="editContent()"
    *ngIf="content && editContentAvailable && showEditBtn"
  ></fa-icon>
</div>

<app-content-edit-dialog
  #editContentModal
  [contentName]="contentName"
  (contentChangeEvent)="onContentChangeEvent()"
></app-content-edit-dialog>
