<div
  bsModal
  #tagEditModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Tag</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!--<app-messages-view [messages]="responseMessages"></app-messages-view>-->

        <div class="row" *ngIf="tag">
          <div class="col-md-10">
            <form
              (ngSubmit)="saveAction()"
              #editCodeValueForm="ngForm"
              novalidate
            >
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="code">Tag Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="code"
                    name="code"
                    [(ngModel)]="tag.tagName"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
            <button
              id="saveBtn"
              type="button"
              (click)="saveAction()"
              class="btn btn-success"
            >
              Save
            </button>
            <button
              id="cancelBtn"
              type="button"
              (click)="cancelAction()"
              class="btn btn-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
