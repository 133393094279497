<form autocomplete="off">
  <div class="row">
    <div class="col-md-8">
      <input
        class="form-control"
        id="nameTxtFld"
        name="nameTxtFld"
        placeholder="Scientific, Common or Māori name"
        [(ngModel)]="asyncSelected"
        [typeahead]="dataSource"
        [typeaheadAsync]="true"
        [typeaheadWaitMs]="100"
        [typeaheadOptionsLimit]="16"
      />
    </div>
    <div class="col-md-4">
      <button
        type="submit"
        class="btn btn-primary btn-block"
        (click)="searchAction()"
      >
        Search
      </button>
    </div>
  </div>
</form>
