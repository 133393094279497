import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Category } from '../../domain/nztcs/category';
import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { ConservationStatusRequest } from './conservation-status-request';

@Injectable()
export class AssessmentService {
  constructor(private http: HttpClient) {}

  getAssessment(assessmentId: number) {
    return this.http.get<ResponseCargo>(
      '/rest/assessments/' + assessmentId.toString()
    );
  }

  findByCriteria(criteria: any) {
    return this.http.post('/rest/assessmentSearch', criteria);
  }

  saveAssessment(assessment: any) {
    console.log(
      'saveAssessment() - ' + JSON.stringify(assessment.populationTrend)
    );
    return this.http.post<ResponseCargo>(
      '/rest/assessments/saveWithValidation',
      assessment
    );
  }

  saveTagsOnAssessment(assessmentId: number, tagList: any[]) {
    return this.http.post(
      '/rest/assessments/saveTagsOnAssessment/' + assessmentId,
      tagList
    );
  }

  removeTagFromAssessment(assessmentId: number, tagRefId: number) {
    return this.http.post(
      '/rest/assessments/removeTagFromAssessment/' +
        assessmentId +
        '/' +
        tagRefId,
      null
    );
  }

  changeAssessmentStatus(assessmentId: number, newStatus: string) {
    return this.http.post(
      '/rest/assessments/changeStatus/' + assessmentId + '/' + newStatus,
      null
    );
  }

  changeAssessmentName(assessmentId: number, formattedName: string) {
    const changeNameRequest = {
      assessmentId,
      formattedName,
    };
    return this.http.post(
      '/rest/assessments/changeAssessmentName/',
      changeNameRequest
    );
  }

  categoryList(reportId?: number) {
    return this.http.get<Category[]>(
      '/rest/assessments/categoryList/' + (reportId ? reportId : '')
    );
  }
//NZTCS-319 as per this story passing report ID to display population Trend List
  populationTrendList(reportId?: number) {
    return this.http.get('/rest/assessments/populationTrendList/' + (reportId ? reportId : ''));
  }

  populationSizeList(reportId?: number) {
    return this.http.get('/rest/assessments/populationSizeList/' + (reportId ? reportId : ''));
  }

  getConfidenceList() {
    return this.http.get('/rest/assessments/confidenceList');
  }

  getQualifierList() {
    return this.http.get('/rest/assessments/qualifierList');
  }

  getStatusChangeList() {
    return this.http.get('/rest/assessments/statusChangeList');
  }

  getChangeReasonList() {
    return this.http.get('/rest/assessments/changeReasonList');
  }

  updateAssessmentName(assessmentId: number) {
    return this.http.get(
      '/rest/assessments/updateAssessmentName/' + assessmentId
    );
  }

  updateAssessmentNamesByReport(reportId: number) {
    return this.http.get(
      '/rest/assessments/updateAssessmentNamesByReport/' + reportId
    );
  }

  // Not currrently hosted by AssessmentController, but is still related and didn't want to create
  // another service this time around
  findAllAssessmentsForSpecies(speciesId: number) {
    return this.http.get<any[]>('/rest/all-assessments/' + speciesId);
  }

  // Not currrently hosted by AssessmentController, but is still related and didn't want to create
  // another service this time around
  calculateConservationStatus(
    conversationStatusRequest: ConservationStatusRequest
  ) {
    return this.http.post<ConservationStatusRequest>(
      '/rest/conservationStatus',
      conversationStatusRequest
    );
  }

  findSpeciesNamesByNameCriteria(name: string) {
    return this.http.get<any[]>('rest/assessments/getSpeciesNames/' + name);
  }
}
