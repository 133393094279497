<tabset>
  <tab heading="Panel Membership">
    <div class="row">
      <div class="col-md-8">
        <table class="table table-striped table-bordered mt-3">
          <thead></thead>
          <tr>
            <th>Report</th>
            <th>Report Role</th>
          </tr>

          <tbody *ngIf="panelMembershipList === null">
            <tr>
              <td colspan="12">Search not yet executed.</td>
            </tr>
          </tbody>

          <tbody
            *ngIf="panelMembershipList && panelMembershipList.length === 0"
          >
            <tr>
              <td colspan="12">No panel memberships found.</td>
            </tr>
          </tbody>

          <tbody *ngIf="panelMembershipList && panelMembershipList.length > 0">
            <tr *ngFor="let panelMember of panelMembershipList">
              <td>
                <a routerLink="/reports/{{ panelMember.reportId }}">{{
                  panelMember.reportName
                }}</a>
              </td>
              <td>{{ panelMember.reportRole }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </tab>
</tabset>
