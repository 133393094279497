<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Name Match Details</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="navigateByRow(-1)"
          [disabled]="!navigatePrevAvailable()"
        >
          Previous
        </button>
        <button
          type="button"
          class="btn btn-sm btn-warning"
          (click)="updateName('IGNORED')"
          [disabled]="!currentUser?.nameEditor"
        >
          Ignore
        </button>
        <button
          type="button"
          class="btn btn-sm btn-success"
          (click)="updateName('ASSIGNED')"
          [disabled]="!currentUser?.nameEditor"
        >
          Select Name
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary"
          (click)="navigateByRow(1)"
          [disabled]="!navigateNextAvailable()"
        >
          Next
        </button>
      </div>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-md-6">
      <div class="panel panel-default">
        <div class="panel-heading"><h5>Available Names</h5></div>
        <div class="panel-body">
          <form class="form-inline">
            <div class="form-group">
              <label class="mr-3" for="nameCriteria">Name</label>
              <input
                class="form-control input-sm mr-3"
                name="nameCriteria"
                id="nameCriteria"
                [(ngModel)]="nzorNameCriteria.nameCriteria"
              />
            </div>
            <button
              class="btn btn-primary btn-sm"
              (click)="searchNZORNames(true)"
            >
              Search
            </button>
          </form>
        </div>

        <br />

        <table class="table table-hover">
          <thead>
            <tr>
              <th>NZOR Name</th>
            </tr>
          </thead>
          <tbody>
            <tr
              *ngFor="
                let nzorName of details.nzorNamesSearchResponse.searchResults
              "
              (click)="selectName(nzorName)"
              [ngClass]="
                nzorName.nameId === selectedNzorName.nameId ? 'bg-info' : ''
              "
            >
              <td>
                <span [innerHtml]="nzorName.formattedFullName">{{
                  nzorName.formattedFullName
                }}</span>
                <br />
                <small
                  ><a href="http://nzor.org.nz/names/{{ nzorName.nameId }}">{{
                    nzorName.nameId
                  }}</a></small
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="row">
        <div class="col-md-7">
          <pagination
            [maxSize]="5"
            [itemsPerPage]="nzorNameCriteria.pageSize"
            [(ngModel)]="nzorNameCriteria.pageNumber"
            [totalItems]="details.nzorNamesSearchResponse.total"
            (pageChanged)="onPageChanged($event)"
          >
          </pagination>
        </div>

        <div
          class="col-md-5"
          style="text-align: right; vertical-align: middle; margin: 28px 0px"
        >
          Total: {{ totalItems }}, Page:
          {{ details.nzorNamesSearchResponse.page }} of
          {{ details.nzorNamesSearchResponse.total }}
        </div>
      </div>
    </div>

    <div class="col-md-6">
      <h4>Bioweb</h4>
      <dl class="row">
        <dt class="col-md-3">Id</dt>
        <dd class="col-md-9">{{ details.species.speciesId }}</dd>

        <dt class="col-md-3">Scientific Name</dt>
        <dd class="col-md-9">{{ details.species.scientificName }}</dd>

        <dt class="col-md-3">Naming Authority</dt>
        <dd class="col-md-9">{{ details.species.namingAuthority }}&nbsp;</dd>

        <dt class="col-md-3">Trinomial Type</dt>
        <dd class="col-md-9">{{ details.species.trinomialType }}&nbsp;</dd>

        <dt class="col-md-3">Trinomial</dt>
        <dd class="col-md-9">{{ details.species.trinomial }}&nbsp;</dd>

        <dt class="col-md-3">Common Name</dt>
        <dd class="col-md-9">{{ details.species.commonName }}&nbsp;</dd>

        <dt class="col-md-3">Species Type</dt>
        <dd class="col-md-9">{{ details.species.speciesType }}&nbsp;</dd>
      </dl>

      <br />
      <hr />

      <h4>Selected NZOR Name</h4>
      <dl class="row">
        <dt class="col-md-3">Relationship</dt>
        <dd class="col-md-9">{{ details.speciesNZORName.nzorRelationship }}</dd>

        <dt class="col-md-3">Formatted Name</dt>
        <dd class="col-md-9">
          <span [innerHtml]="details.speciesNZORName.nzorFormattedName"></span>
        </dd>

        <dt class="col-md-3">Id</dt>
        <dd class="col-md-9">
          <small
            ><a
              href="http://nzor.org.nz/names/{{
                details.speciesNZORName.nzorID
              }}"
              >{{ details.speciesNZORName.nzorID }}</a
            >
          </small>
        </dd>
      </dl>

      <br />

      <dl class="row">
        <dt class="col-md-3">Classification</dt>
        <dd class="col-md-9">&nbsp;</dd>
      </dl>

      <div
        class="row"
        *ngFor="let nameLink of details.speciesNZORName.classificationNames"
      >
        <dt class="col-md-3">{{ nameLink.rank }}</dt>
        <dd class="col-md-9">
          <a href="http://nzor.org.nz/names/{{ nameLink.nameId }}">{{
            nameLink.partialName
          }}</a>
        </dd>
      </div>
    </div>
  </div>
</div>
