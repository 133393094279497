import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { User } from '../../authentication/service/user';
import { PanelMemberStatus } from '../../report/service/panel-member-status';
import { ReportService } from '../../report/service/report.service';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { EditNameComponent } from './edit-name/edit-name.component';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-assessment-tab',
  templateUrl: './assessment-tab.component.html',
})
export class AssessmentTabComponent implements OnInit {
  @Input()
  assessment: any;

  @Input()
  report: any;

  @ViewChild('editNameModal', { static: true })
  editNameModal: EditNameComponent;

  populationTrendLabel = '';

  privateNotesDisplayed = false;
  editNamesAvailable = false;

  currentUser: User;

  panelMemberStatus: PanelMemberStatus;

  editIcon = faPencilAlt;

  private _nztcsSpecies: NztcsSpeciesEntity;

  constructor(
    private authenticationService: AuthenticationService,
    private nztcsSpeciesService: NztcsSpeciesService,
    private reportService: ReportService
  ) {}

  get nztcsSpecies(): NztcsSpeciesEntity {
    return this._nztcsSpecies;
  }

  @Input()
  set nztcsSpecies(value: NztcsSpeciesEntity) {
    this._nztcsSpecies = value;
    this.updatePopulationTrendLabel();
  }

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );

    this.panelMemberStatus = this.reportService.detectPanelMemberStatus(
      this.report.model,
      this.currentUser.personId
    );
    this.privateNotesDisplayed =
      this.panelMemberStatus.isReportAdmin ||
      this.panelMemberStatus.isPanelChair ||
      this.currentUser.administrator ||
      this.panelMemberStatus.isPanelMember;

    this.editNamesAvailable = this.currentUser.historicalNameEditor;
  }

  updatePopulationTrendLabel() {
    let label = '';
    /*if(this.assessment.model.populationTrend) {
      label += this.assessment.model.populationTrend.title;
    }*/

    let popTrendConfidenceLabel = null;
    if (this.assessment.model.popTrendConfidence) {
      popTrendConfidenceLabel = this.assessment.model.popTrendConfidence.title;
    }

    let threeGenerationPeriodInYears = null;
    if (
      this._nztcsSpecies != null &&
      this._nztcsSpecies.generationTimeInYears != null
    ) {
      threeGenerationPeriodInYears =
        this.nztcsSpeciesService.getThreeGenerationPeriodInYears(
          this._nztcsSpecies
        );
    }

    if (threeGenerationPeriodInYears) {
      label += ' over ' + threeGenerationPeriodInYears + ' years';
    }

    if (popTrendConfidenceLabel) {
      label += ' (' + popTrendConfidenceLabel + ' confidence)';
    }

    this.populationTrendLabel = label;
  }

  editSpeciesNameAction() {
    this.editNameModal.start();
  }

  onEditNameEvent($event) {
    this.assessment.model.speciesAssessmentName = $event;
  }
}
