<div
  bsModal
  #speciesSelectorModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Species Selector</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-6">
            <label class="control-label" for="speciesNameTxtFld2"
              >Species Name Search</label
            >
            <input
              type="text"
              class="form-control"
              id="speciesNameTxtFld2"
              name="speciesNameTxtFld2"
              [(ngModel)]="criteria.nameCriteria"
              (change)="onSearch()"
            />
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="form-control-label" for="exampleCheck1"
                >Display species in report</label
              >
              <input
                class="form-check"
                type="checkbox"
                id="exampleCheck1"
                [(ngModel)]="criteria.matchAgainstReport"
                (change)="onMatchAgainstReportChange()"
              />
            </div>
          </div>

          <div class="col-md-2">
            <button class="btn btn-primary btn-block" (click)="onSearch()">
              Search
            </button>
            <button class="btn btn-light btn-block" (click)="onClear()">
              Clear
            </button>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-12">
            <table class="table table-striped table-bordered table-sm small">
              <thead>
                <tr>
                  <th>Species Name</th>
                  <th>In Report</th>
                  <th>
                    <span class="mr-2">Select</span>
                    <input
                      type="checkbox"
                      id="selectAllChkBx"
                      [(ngModel)]="selectAll"
                      (change)="onSelectAll()"
                    />
                  </th>
                </tr>
              </thead>
              <tbody *ngIf="searchResponse.searchResults === null">
                <tr>
                  <td colspan="12">Search not yet executed.</td>
                </tr>
              </tbody>

              <tbody *ngIf="searchResponse.searchResults.length === 0">
                <tr>
                  <td colspan="12">No search results found.</td>
                </tr>
              </tbody>

              <tbody *ngIf="searchResponse.searchResults.length > 0">
                <tr *ngFor="let searchResult of searchResponse.searchResults">
                  <td [innerHTML]="searchResult.speciesName"></td>
                  <td>{{ searchResult.includedInReport }}</td>
                  <td>
                    <div class="form-group">
                      <input
                        class="form-check"
                        type="checkbox"
                        id="check2"
                        *ngIf="!searchResult.includedInReport"
                        [(ngModel)]="searchResult.selected"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row">
              <div class="col-md-6">
                <pagination
                  [maxSize]="5"
                  [itemsPerPage]="criteria.pageSize"
                  [(ngModel)]="criteria.pageNumber"
                  [totalItems]="searchResponse.total"
                  (pageChanged)="onPageChanged($event)"
                >
                </pagination>
              </div>
              <div class="col-md-6 text-right">
                Total Results: {{ searchResponse.total }}
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6"></div>
          <div class="col-md-6 text-right">
            <app-wait-spinner
              [showSpinner]="addRequestInProgress"
            ></app-wait-spinner>
            <button
              id="confirmBtn"
              type="button"
              (click)="onAdd()"
              class="btn btn-success"
              [disabled]="addRequestInProgress"
            >
              Add
            </button>
            <button
              id="closeBtn"
              type="button"
              (click)="onClose()"
              class="btn btn-light"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
