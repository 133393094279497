import { Component, OnInit } from '@angular/core';
import { User } from '../../authentication/service/user';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { NztcsSpeciesSearchCtxService } from './nztcs-species-search-ctx.service';
import { Router } from '@angular/router';
import { NztcsSpeciesService } from '../service/nztcs-species.service';

@Component({
  selector: 'app-nztcs-species-search',
  templateUrl: './nztcs-species-search.component.html',
})
export class NztcsSpeciesSearchComponent implements OnInit {
  currentUser: User;
  nameEditor = false;

  speciesTypes: string[];

  constructor(
    public searchCtx: NztcsSpeciesSearchCtxService,
    private router: Router,
    private nztcsSpeciesService: NztcsSpeciesService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.speciesTypes = this.nztcsSpeciesService.getSpeciesTypes();
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.nameEditor = this.currentUser && this.currentUser.nameEditor;
    });
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.searchCtx.criteria.pageNumber = event.page;
    this.searchAction();
  }

  clearAction() {
    this.searchCtx.clear();
    this.searchAction();
  }

  searchAction() {
    this.searchCtx.search();
  }

  importSpeciesAction() {}

  newSpeciesAction() {
    this.router.navigate(['nztcs-species-edit/-1']);
  }
}
