<div class="container-fluid">
  <h2>Batch Control</h2>
  <hr />

  <div class="row">
    <div class="col-md-2">NZOR Data Cache Job</div>
    <div class="col-md-7">
      <app-batch-job-report
        [jobReport]="NZORDataCacheJobReport"
      ></app-batch-job-report>
    </div>
    <div class="col-md-2">
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="executeJob('NZORDataCacheBatchJob')"
        [disabled]="NZORDataCacheJobReport.batchJobStatus !== 'IDLE'"
      >
        Execute Now
      </button>
      <button
        type="button"
        class="btn btn-danger btn-block"
        (click)="deleteData('NZORDataCacheBatchJob')"
        [disabled]="NZORDataCacheJobReport.batchJobStatus !== 'IDLE'"
      >
        Delete Data
      </button>
    </div>
  </div>

  <!--<div class="row">
    <div class="col-md-2">NZOR Name Matching Job</div>
    <div class="col-md-7">
      <app-batch-job-report [jobReport]="NameMatchingJobReport"></app-batch-job-report>
    </div>
    <div class="col-md-2">
      <button type="button" class="btn btn-primary btn-block" (click)="executeJob('NameMatchingBatchJob')" [disabled]="!executeBtnEnabled">Execute Now</button>
      <button type="button" class="btn btn-danger btn-block" (click)="deleteData('NameMatchingBatchJob')" [disabled]="!executeBtnEnabled">Delete Data</button>
    </div>
  </div>-->

  <div class="row">
    <div class="col-md-2">NZTCS Species Migration</div>

    <div class="col-md-7">
      <app-batch-job-report
        [jobReport]="NZTCSSpeciesMigrationJobReport"
      ></app-batch-job-report>
    </div>

    <div class="col-md-2">
      <!--
      <button type="button" class="btn btn-primary btn-block" (click)="executeJob('NZTCSSpeciesMigrationBatchJob')" [disabled]="NameMatchingJobReport.batchJobStatus != 'IDLE'">Execute Now</button>
      <button type="button" class="btn btn-danger btn-block" (click)="deleteData('NZTCSSpeciesMigrationBatchJob')" [disabled]="NameMatchingJobReport.batchJobStatus != 'IDLE'">Delete Data</button>
-->
      <!-- DISABLED: - not expected to be run again -->
      <button
        type="button"
        class="btn btn-primary btn-block"
        (click)="executeJob('NZTCSSpeciesMigrationBatchJob')"
        [disabled]="true || NameMatchingJobReport.batchJobStatus !== 'IDLE'"
      >
        Execute Now
      </button>
      <button
        type="button"
        class="btn btn-danger btn-block"
        (click)="deleteData('NZTCSSpeciesMigrationBatchJob')"
        [disabled]="true || NameMatchingJobReport.batchJobStatus !== 'IDLE'"
      >
        Delete Data
      </button>
    </div>
  </div>
</div>
