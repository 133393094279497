import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VersionData } from '../domain/nztcs/version-data';

@Injectable()
export class VersionService {
  constructor(private httpClient: HttpClient) {}

  getVersion() {
    return this.httpClient.get<VersionData>('/rest/version');
  }
}
