/* eslint-disable brace-style */
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SpeciesSourceComponent } from '../species-source-component';
import { NztcsSpeciesEntity } from '../../../domain/nztcs/nztcs-species-entity';
import { NZORNameService } from '../../../nzor/service/nzorname.service';
import { NzorSpeciesSearchComponent } from './nzor-species-search/nzor-species-search.component';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nzorspecies-source',
  templateUrl: './nzorspecies-source.component.html',
})
export class NZORSpeciesSourceComponent
  implements OnInit, SpeciesSourceComponent
{
  @Input()
  nztcsSpecies: NztcsSpeciesEntity;

  @ViewChild('nzorSpeciesSearchComponent', { static: true })
  nzorSpeciesSearchComponent: NzorSpeciesSearchComponent;

  nzorName: any;

  faAngleDoubleLeft = faAngleDoubleLeft;

  constructor(private nzorNameService: NZORNameService) {}

  ngOnInit() {
    this.loadNZORSpecies();

    this.nzorSpeciesSearchComponent.speciesSelectedEvent.subscribe(
      (speciesId) => {
        console.log('Source got species selected event: ' + speciesId);
        this.nztcsSpecies.nzorId = speciesId;
        this.loadNZORSpecies();
      }
    );
  }

  loadNZORSpecies() {
    if (this.nztcsSpecies && this.nztcsSpecies.nzorId) {
      this.nzorNameService
        .findByIdExtended(this.nztcsSpecies.nzorId)
        .subscribe((nzorName) => {
          this.nzorName = nzorName;
        });
    }
  }

  searchAction() {
    this.nzorSpeciesSearchComponent.show();
  }

  copyAllAction() {}

  disconnectAction() {
    this.nztcsSpecies.nzorId = null;
    this.nzorName = null;
  }
}
