import { Component, Input, OnInit } from '@angular/core';
import { NztcsTaxonName } from '../../domain/nztcs/nztcs-taxon-name';

@Component({
  selector: 'app-taxon-name-list',
  templateUrl: './taxon-name-list.component.html',
  styleUrls: ['./taxon-name-list.component.scss'],
})
export class TaxonNameListComponent implements OnInit {
  @Input()
  taxonName: NztcsTaxonName;

  @Input()
  alternateColor = false;

  constructor() {}

  ngOnInit() {}
}
