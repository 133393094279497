<div class="card mb-3">
  <div class="card-body species-source-heading-background">
    <div class="row">
      <div class="col-md-1">
        <button
          class="btn btn-sm btn-success"
          type="button"
          (click)="copyAllAction()"
        >
          <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
        </button>
      </div>
      <div class="col-md-8">
        <h5 class="card-title">Bioweb Species</h5>
        <small><b>Bioweb ID:</b> {{ nztcsSpecies.biowebSpeciesId }}</small>
      </div>
      <div class="col-md-3">
        <button
          class="btn btn-sm btn-block btn-primary"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          class="btn btn-sm btn-block btn-danger"
          (click)="disconnectAction()"
          *ngIf="nztcsSpecies.biowebSpeciesId"
        >
          Disconnect
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="biowebSpecies">
  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Taxonomy</h5>
      <div class="a-container">
        <div class="a-row">
          <div class="a-form-label mb-4">Hierarchy</div>
          <div class="a-cell-value">
            <app-bioweb-taxon-name-list
              [taxon]="biowebSpecies.taxon"
            ></app-bioweb-taxon-name-list>
          </div>
        </div>
      </div>

      <app-copy-attribute-field
        [sourceLabel]="'Species Type'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'speciesType'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'speciesType'"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Formal Name</h5>

      <!-- spacer -->
      <app-copy-attribute-field
        [sourceLabel]="null"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="null"
        [targetEntity]="null"
        [targetAttribute]="null"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Scientific Name'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'scientificName'"
        [targetEntity]="null"
        [targetAttribute]="null"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Genus'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'genus'"
        [targetEntity]="null"
        [targetAttribute]="null"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Species Name'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'speciesName'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'speciesEpithet'"
        (copy)="onCopy($event)"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Trinomial Type'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'trinomialType'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'trinomialType'"
        (copy)="onCopy($event)"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Trinomial'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'trinomial'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'trinomialEpithet'"
        (copy)="onCopy($event)"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Naming Authority'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'namingAuthority'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'namingAuthority'"
        (copy)="onCopy($event)"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Scientific Name Reliability'"
        [sourceEntity]="biowebSpecies"
        [sourceAttributeConverter]="convertNameReliability"
        [sourceAttribute]="'scientificNameReliability'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'taxonomicStatus'"
        (copy)="onCopy($event)"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Other Names</h5>

      <app-copy-attribute-field
        [sourceLabel]="'Common Name'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'commonName'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'commonName'"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Māori Name'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'maoriName'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'maoriName'"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Alternate Names'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'speciesAlternativeName'"
        [sourceAttributeChild]="'alternativeName'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'alternativeNames'"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Attributes</h5>

      <app-copy-attribute-field
        [sourceLabel]="'Bio Status'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'bioStatusCategory'"
        [sourceAttributeConverter]="convertBioStatus"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'biostatusCategory'"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="'Environment'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'environment'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'environmentCategory'"
      >
      </app-copy-attribute-field>

      <app-copy-attribute-field
        [sourceLabel]="''"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="''"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="''"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Notes</h5>

      <app-copy-attribute-field
        [sourceLabel]="'Notes'"
        [sourceEntity]="biowebSpecies"
        [sourceAttribute]="'notes'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'notes'"
      >
      </app-copy-attribute-field>
    </div>
  </div>
</div>

<app-bioweb-species-search
  #biowebSpeciesSearchComponent
></app-bioweb-species-search>
