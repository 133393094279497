import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TinymceUtils } from '../../../tinymce/tinymce-utils';

@Component({
  selector: 'app-edit-species-name',
  templateUrl: './edit-species-name.component.html',
})
export class EditSpeciesNameComponent implements OnInit {
  @Input()
  editableName: string;

  @Output()
  editNameEvent = new EventEmitter();

  @ViewChild('editSpeciesNameModal', { static: true })
  public editSpeciesNameModal: ModalDirective;

  editorSettings = TinymceUtils.nameEditorSettings;

  constructor() {}

  ngOnInit() {
    this.editorSettings.height = null;
  }

  start() {
    this.editSpeciesNameModal.show();
  }

  onOkay() {
    this.editNameEvent.next(this.editableName);
    this.onCancel();
  }

  onCancel() {
    this.editSpeciesNameModal.hide();
  }
}
