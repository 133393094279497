import { Component, OnInit, ViewChild } from '@angular/core';
import { NztcsSpeciesService } from '../service/nztcs-species.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { SpeciesSourceItem } from './species-source-item';
import { SpeciesSourceService } from './species-source.service';
import { CalculateSpeciesNameRequest } from '../service/calculate-species-name-request';
import { EditSpeciesNameComponent } from './edit-species-name/edit-species-name.component';
import { TinymceUtils } from '../../tinymce/tinymce-utils';
import { Environment } from '../../domain/nztcs/environment';
import { TaxonSelectComponent } from './taxon-select/taxon-select.component';
import { NztcsTaxonEntityService } from '../../nztcs-taxon-name/service/nztcs-taxon-entity.service';
import { faPencilAlt, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-nztcs-species-edit',
  templateUrl: './nztcs-species-edit.component.html',
})
export class NztcsSpeciesEditComponent implements OnInit {
  @ViewChild('editNameAndAuthorityModal')
  editNameAndAuthorityModal: EditSpeciesNameComponent;

  @ViewChild('editScientificNameModal')
  editScientificNameModal: EditSpeciesNameComponent;

  @ViewChild('taxonSelectModal', { static: true })
  taxonSelectModal: TaxonSelectComponent;
  nztcsSpeciesId: number;
  selectedSpecies: NztcsSpeciesEntity;
  speciesTypes: string[];
  trinomialTypes: string[];
  taxonomicStatusList: string[];
  bioStatusOptions: string[];
  environmentOptions: string[];
  lifeModeOptions: string[];

  speciesSourceItems: SpeciesSourceItem[];

  responseMessages: any[];

  faPencil = faPencilAlt;
  faSync = faSyncAlt;

  editorSettings = TinymceUtils.editorSettings;

  constructor(
    private nztcsSpeciesService: NztcsSpeciesService,
    private speciesSourceService: SpeciesSourceService,
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.speciesTypes = this.nztcsSpeciesService.getSpeciesTypes();
    this.trinomialTypes = this.nztcsSpeciesService.getTrinomialTypes();
    this.taxonomicStatusList =
      this.nztcsTaxonEntityService.getTaxonomicStatusList();
    this.bioStatusOptions = this.nztcsSpeciesService.getBioStatusOptions();
    this.environmentOptions = Environment.VALUES;
    this.lifeModeOptions = this.nztcsSpeciesService.getLifeModeOptions();
    this.route.params.subscribe((params) => {
      this.nztcsSpeciesId = +params['nztcsSpeciesId'];
      if (this.nztcsSpeciesId > 0) {
        console.log('load species ' + this.nztcsSpeciesId);
        this.loadSpecies();
      } else {
        console.log('new species ');
        this.newSpecies();
      }
    });

    this.taxonSelectModal.taxonSelectedEvent.subscribe((nztcsTaxonId) => {
      this.nztcsTaxonEntityService
        .findNztcsTaxonNameEntityByPrimaryKey(nztcsTaxonId)
        .subscribe((nztcsTaxonName) => {
          this.selectedSpecies.taxonName = nztcsTaxonName;
          this.changeName();
        });
    });
  }

  loadSpecies() {
    this.nztcsSpeciesService
      .getNztcsSpecies(this.nztcsSpeciesId)
      .subscribe((nztcsSpecies) => {
        this.selectedSpecies = nztcsSpecies;
        this.speciesSourceItems =
          this.speciesSourceService.getSpeciesSourceList(this.selectedSpecies);
        this.speciesSourceItems = [];
      });
  }

  saveAction() {
    this.nztcsSpeciesService
      .saveNztcsSpecies(this.selectedSpecies)
      .subscribe((response) => {
        this.responseMessages = response.responseMessages;
        this.selectedSpecies = response.model;
        if (this.responseMessages.length === 0) {
          this.nztcsSpeciesId = this.selectedSpecies.nztcsSpeciesId;
          this.router.navigate(['/nztcs-species/' + this.nztcsSpeciesId]);
        }
      });
  }

  cancelAction() {
    if (this.nztcsSpeciesId > 0) {
      this.router.navigate(['nztcs-species/' + this.nztcsSpeciesId]);
    } else {
      this.router.navigate(['nztcs-species-search']);
    }
  }

  onBiowebNameCopy() {
    this.changeName();
  }

  changeName() {
    const request = new CalculateSpeciesNameRequest();
    request.taxonName = this.selectedSpecies.taxonName.taxonName;
    request.speciesEpithet = this.selectedSpecies.speciesEpithet;
    request.trinomialType = this.selectedSpecies.trinomialType;
    request.trinomialEpithet = this.selectedSpecies.trinomialEpithet;
    request.namingAuthority = this.selectedSpecies.namingAuthority;
    request.speciesType = this.selectedSpecies.speciesType;

    this.nztcsSpeciesService
      .calculateSpeciesName(request)
      .subscribe((response) => {
        this.selectedSpecies.nameAndAuthority = response.model.fullName;
        this.selectedSpecies.scientificName = response.model.shortName;
      });
  }

  editNameAndAuthority() {
    this.editNameAndAuthorityModal.start();
  }

  editScientificName() {
    this.editScientificNameModal.start();
  }

  editNameAndAuthorityUpdateEvent(newName: any) {
    console.log('editNameAndAuthorityUpdateEvent: ' + newName);
    this.selectedSpecies.nameAndAuthority = newName;
  }

  editScientificNameUpdateEvent(newName: any) {
    console.log('editScientificNameUpdateEvent: ' + newName);
    this.selectedSpecies.scientificName = newName;
  }

  editTaxon() {
    this.taxonSelectModal.show();
  }

  private newSpecies() {
    this.selectedSpecies = new NztcsSpeciesEntity();
  }
}
