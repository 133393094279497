import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class CodeValueService {
  constructor(private http: HttpClient) {}

  //@RequestMapping(value="/", method= RequestMethod.GET)
  getEditableCodeTypes() {
    return this.http.get('/rest/codeValue/');
  }

  //@RequestMapping(value="/{codeType}", method= RequestMethod.GET)
  getCodeValueMetaData(codeType: string) {
    return this.http.get('/rest/codeValue/' + codeType);
  }

  //@RequestMapping(value="/", method= RequestMethod.POST)
  saveCodeValue(codeValue: any) {
    return this.http.post('/rest/codeValue/', codeValue);
  }

  //@RequestMapping(value="/remove", method= RequestMethod.POST)
  removeCodeValue(codeValue: any) {
    return this.http.post('/rest/codeValue/remove', codeValue);
  }
}
