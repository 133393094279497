<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Content Edit</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="saveAction()"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm"
          (click)="cancelAction()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div *ngIf="content">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label
                class="a-form-label"
                style="width: 33%; text-align: left"
                for="linkLabel"
                >Link Label</label
              >
              <label class="a-form-label" style="width: 66%; text-align: right"
                >Copy Link:
                <a [routerLink]="'/content/' + content.name">{{
                  content.linkLabel
                }}</a></label
              >
              <input
                type="text"
                class="form-control"
                id="linkLabel"
                [(ngModel)]="content.linkLabel"
                name="linkLabel"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="a-form-label" for="name">URL Name</label>
              <input
                type="text"
                class="form-control"
                id="name"
                [(ngModel)]="content.name"
                name="name"
              />
            </div>
          </div>

          <div class="col-md-4">
            <div class="form-group">
              <label class="a-form-label" for="visibility">Visibility</label>
              <select
                class="form-control"
                id="visibility"
                name="visibility"
                [(ngModel)]="content.visibility"
              >
                <option
                  *ngFor="let visibilityOption of visibilityOptionList"
                  [ngValue]="visibilityOption"
                >
                  {{ visibilityOption }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <app-text-editor
          #textEditorComponent
          [height]="600"
          [context]="getContext()"
          [attachmentsEnabled]="content.contentId"
          [text]="content.html"
        ></app-text-editor>
      </div>
    </div>
  </div>
</div>
