import { Injectable } from '@angular/core';
import { ReportSearchCriteria } from './report-search-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { ReportService } from './report.service';

@Injectable()
export class ReportSearchCtxService {
  criteria = new ReportSearchCriteria();
  response: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  constructor(private reportService: ReportService) {}

  search() {
    this.reportService
      .search(this.criteria)
      .subscribe((pagedResponse: PagedResponse) => {
        this.response = pagedResponse;
        this.response.numberOfPages = Math.ceil(
          pagedResponse.total / this.criteria.pageSize
        );
      });
  }

  clear() {
    this.criteria = new ReportSearchCriteria();
  }
}
