import { Component, Input, OnInit } from '@angular/core';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { NztcsSpeciesService } from '../service/nztcs-species.service';

@Component({
  selector: 'app-nztcs-species-assessment-view',
  templateUrl: './nztcs-species-assessment-view.component.html',
})
export class NztcsSpeciesAssessmentViewComponent implements OnInit {
  @Input()
  nztcsSpeciesId: number;

  species: NztcsSpeciesEntity;

  constructor(private nztcsSpeciesService: NztcsSpeciesService) {}

  ngOnInit() {
    this.nztcsSpeciesService
      .getNztcsSpecies(this.nztcsSpeciesId)
      .subscribe((response) => {
        this.species = response;
      });
  }
}
