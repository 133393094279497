import { Injectable } from '@angular/core';
import { NztcsSpeciesService } from '../service/nztcs-species.service';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { NztcsSpeciesSearchCriteria } from './nztcs-species-search-criteria';
import { NztcsSpeciesNameComparisonService } from '../service/nztcs-species-name-comparison.service';

@Injectable()
export class NztcsSpeciesSearchCtxService {
  criteria = new NztcsSpeciesSearchCriteria();
  response: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  relatedAssessments = {};

  constructor(
    private nztcsSpeciesService: NztcsSpeciesService,
    private nztcsSpeciesNameComparisonService: NztcsSpeciesNameComparisonService
  ) {}

  hasSearchResults(): boolean {
    return this.response && this.response.searchResults.length > 0;
  }

  search() {
    this.nztcsSpeciesService
      .findByCriteria(this.criteria)
      .subscribe((pagedResponse: PagedResponse) => {
        this.response = pagedResponse;
        this.response.numberOfPages = Math.ceil(
          pagedResponse.total / this.criteria.pageSize
        );
        this.findRelatedAssessments(this.response);
      });
  }

  findRelatedAssessments(searchResponse: any) {
    if (searchResponse && searchResponse.searchResults) {
      searchResponse.searchResults.forEach((species) => {
        this.nztcsSpeciesService
          .findNztcsSpeciesUsage(species.nztcsSpeciesId)
          .subscribe((usageResponse) => {
            this.relatedAssessments[species.nztcsSpeciesId] =
              this.findUniqueAndDifferentNames(
                species.nameAndAuthority,
                usageResponse
              );
          });
      });
    }
  }

  findUniqueAndDifferentNames(baseName: string, usageResponse: any): string[] {
    const uniqueNameList = [];
    const baseNameStripped =
      this.nztcsSpeciesNameComparisonService.stripIrrelevantTokens(baseName);
    usageResponse.forEach((usage) => {
      const nextName = usage.assessmentName;
      const nextNameStripped =
        this.nztcsSpeciesNameComparisonService.stripIrrelevantTokens(nextName);
      const areNamesSimilar = baseNameStripped === nextNameStripped;
      if (!areNamesSimilar) {
        let uniqueName = true;
        for (const nextUniqueName of uniqueNameList) {
          const nextUniqueNameStripped =
            this.nztcsSpeciesNameComparisonService.stripIrrelevantTokens(
              nextUniqueName
            );
          if (nextNameStripped === nextUniqueNameStripped) {
            uniqueName = false;
          }
        }

        if (uniqueName) {
          uniqueNameList.push(nextName);
        }
      }
    });
    return uniqueNameList;
  }

  clear() {
    this.criteria = new NztcsSpeciesSearchCriteria();
  }
}
