import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-messages-view',
  templateUrl: './messages-view.component.html',
  styleUrls: ['./messages-view.component.scss'],
})
export class MessagesViewComponent implements OnInit {
  @Input()
  messages;

  constructor() {}

  ngOnInit() {}
}
