import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { BiowebSpeciesSearchCriteria } from '../../../../species/service/bioweb-species-search-criteria';
import { SpeciesService } from '../../../../species/service/species.service';

@Component({
  selector: 'app-bioweb-species-search',
  templateUrl: './bioweb-species-search.component.html',
  styleUrls: ['bioweb-species-search.component.scss'],
})
export class BiowebSpeciesSearchComponent implements OnInit {
  @ViewChild('biowebSpeciesSearchModal', { static: true })
  biowebSpeciesSearchModal: ModalDirective;

  @Output()
  speciesSelectedEvent = new EventEmitter();

  criteria = new BiowebSpeciesSearchCriteria();
  pagedSearchResponse: any;

  constructor(private speciesService: SpeciesService) {}

  ngOnInit() {
    this.searchAction();
  }

  show() {
    this.biowebSpeciesSearchModal.show();
  }

  selectAction(biowebSpeciesId) {
    this.speciesSelectedEvent.next(biowebSpeciesId);
    this.cancelAction();
  }

  cancelAction() {
    this.biowebSpeciesSearchModal.hide();
  }

  searchAction() {
    this.speciesService.findByCriteria(this.criteria).subscribe((response) => {
      this.pagedSearchResponse = response;
    });
  }

  clearAction() {
    this.criteria = new BiowebSpeciesSearchCriteria();
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.criteria.pageNumber = event.page;
    this.searchAction();
  }
}
