<div class="container-fluid">
  <div class="row justify-content-between">
    <div class="col-md-4">
      <h2 id="viewHdg">Tags</h2>
    </div>
    <div class="col-md-3"></div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <table class="table table-bordered table-sm">
        <thead>
          <tr>
            <th>Tags</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tag of tags">
            <td>{{ tag.tagName }}</td>
            <td>
              <button
                *ngIf="!tag.system"
                class="btn btn-sm btn-success"
                (click)="editTagAction(tag)"
              >
                <fa-icon [icon]="editIcon"></fa-icon>
              </button>
              <button
                *ngIf="!tag.system"
                class="btn btn-sm btn-danger"
                (click)="deleteTagAction(tag)"
              >
                <fa-icon [icon]="deleteIcon"></fa-icon>
              </button>
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <button class="btn btn-success" (click)="addNewTagAction()">
                <fa-icon [icon]="newIcon"></fa-icon>
                Add New Tag
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-tag-edit
  (tagChangedEvent)="onTagChangedEvent()"
  #tagEditComponent
></app-tag-edit>
