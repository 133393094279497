<div
  bsModal
  #taxonNameMoveModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Move Taxon Name</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div *ngIf="responseMessages && responseMessages.length > 0">
          <alert type="danger" *ngFor="let msg of responseMessages">{{
            msg.description
          }}</alert>
        </div>

        <div class="row" *ngIf="selectedTaxon">
          <div class="col-md-10">
            <form (ngSubmit)="moveAction()" #moveForm="ngForm" novalidate>
              <div class="card">
                <div class="card-header">Selected Taxon</div>
                <div class="card-body" *ngIf="selectedTaxon">
                  <!--<span *ngFor="let child of [selectedTaxon]; let last = last"><a routerLink="/nztcs-taxon/{{child.nztcsTaxonId}}">{{child.taxonName}}</a> [{{child.taxonRank}}]<span *ngIf="!last">, </span></span>-->
                  <div class="row">
                    <div class="col-md-4"><b>Name</b></div>
                    <div class="col-md-8">{{ selectedTaxon.taxonName }}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-4"><b>Rank</b></div>
                    <div class="col-md-8">{{ selectedTaxon.taxonRank }}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-4"><b>Species Type</b></div>
                    <div class="col-md-8">{{ selectedTaxon.speciesType }}</div>
                  </div>
                </div>
              </div>

              <br />

              <div class="row">
                <div class="col-md-4" id="selectNewParent">
                  <b>Select new parent:</b>
                </div>
                <div class="col-md-8">
                  <input
                    class="form-control"
                    id="nameTxtFld"
                    name="nameTxtFld"
                    autocomplete="off"
                    aria-labelledby="selectNewParent"
                    [(ngModel)]="destinationInput"
                    [typeahead]="dataSource"
                    [typeaheadWaitMs]="100"
                    (typeaheadOnSelect)="onSelect($event)"
                    typeaheadOptionField="taxonName"
                  />
                </div>
              </div>

              <br />

              <div class="card">
                <div class="card-header">New Parent</div>
                <div class="card-body" *ngIf="destinationTaxon">
                  <div class="row">
                    <div class="col-md-4"><b>Name</b></div>
                    <div class="col-md-8">{{ destinationTaxon.taxonName }}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-4"><b>Rank</b></div>
                    <div class="col-md-8">{{ destinationTaxon.taxonRank }}</div>
                  </div>

                  <div class="row">
                    <div class="col-md-4"><b>Species Type</b></div>
                    <div class="col-md-8">
                      {{ destinationTaxon.speciesType }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2">
                <alert type="danger" *ngIf="moveValid !== null && !moveValid"
                  >Invalid Move. Selected Parent must be one level higher than
                  the selected Taxa.</alert
                >
                <alert
                  type="warning"
                  *ngIf="
                    moveValid &&
                    speciesTypeIsDifferent !== null &&
                    speciesTypeIsDifferent
                  "
                  >WARNING: Species Type is different to parent. This Taxon and
                  all children (including Species) will be updated to match the
                  new parent Taxon.</alert
                >
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
            <button
              id="saveBtn"
              type="button"
              (click)="moveAction()"
              [disabled]="!moveValid"
              class="btn btn-primary mr-2"
            >
              Move
            </button>
            <button
              id="cancelBtn"
              type="button"
              (click)="cancelAction()"
              class="btn btn-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
