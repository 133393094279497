<div class="container-fluid">
  <h1>Taxa Search</h1>
  <hr />

  <form>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <label for="nameCriteriaTxtFld">Name</label>
            <input
              type="text"
              class="form-control"
              id="nameCriteriaTxtFld"
              name="nameCriteriaTxtFld"
              [(ngModel)]="searchCtx.criteria.name"
            />
          </div>

          <div class="col-md-6">
            <label for="rankTxtFld">Rank</label>
            <select
              class="form-control"
              id="rankTxtFld"
              name="rankTxtFld"
              [(ngModel)]="searchCtx.criteria.rank"
            >
              <option [ngValue]="null"></option>
              <option *ngFor="let rank of taxonRanks" [ngValue]="rank">
                {{ rank }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-6">
            <label for="speciesTypeTxtFld">Species Type</label>
            <select
              class="form-control"
              id="speciesTypeTxtFld"
              name="speciesTypeTxtFld"
              [(ngModel)]="searchCtx.criteria.speciesType"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let speciesType of speciesTypes"
                [ngValue]="speciesType"
              >
                {{ speciesType }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          type="button"
          class="btn btn-light btn-block"
          (click)="clearAction()"
        >
          Clear
        </button>
        <button
          type="button"
          class="btn btn-success btn-block"
          (click)="newAction()"
          *ngIf="nameEditor"
        >
          New...
        </button>
      </div>
    </div>
  </form>

  <br />

  <div class="row">
    <div class="col-md-6">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Taxon</th>
              <th>Rank</th>
              <th>Species Type</th>
            </tr>
          </thead>
          <tbody *ngIf="searchCtx.hasSearchResults()">
            <tr *ngFor="let taxon of searchCtx.response.searchResults">
              <td>
                <a routerLink="/nztcs-taxon/{{ taxon.nztcsTaxonId }}">
                  <span [innerHTML]="taxon.taxonName"></span>
                </a>
              </td>
              <td>{{ taxon.taxonRank }}</td>
              <td>{{ taxon.speciesType }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!searchCtx.hasSearchResults()">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="searchCtx.criteria.pageSize"
        [(ngModel)]="searchCtx.criteria.pageNumber"
        [totalItems]="searchCtx.response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ searchCtx.response.total }}, Page:
      {{ searchCtx.response.page }} of {{ searchCtx.response.numberOfPages }}
    </div>
  </div>
</div>
