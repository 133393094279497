import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Content } from '../service/content';
import { ContentService } from '../service/content.service';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { User } from '../../authentication/service/user';
import { ContentEditDialogComponent } from '../content-edit-dialog/content-edit-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-content-view',
  templateUrl: './content-view.component.html',
})
export class ContentViewComponent implements OnInit {
  @Input()
  contentName: string;

  @ViewChild('editContentModal', { static: true })
  public editContentModal: ContentEditDialogComponent;

  currentUser: User;
  content: Content;
  unsafeHtml: any;

  editContentAvailable = false;
  showEditBtn = false;

  editIcon = faPencilAlt;

  constructor(
    private sanitizer: DomSanitizer,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private contentService: ContentService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.editContentAvailable = currentUser && currentUser.administrator;
    });

    if (this.contentName) {
      this.loadContent();
    } else {
      this.route.params.subscribe((params) => {
        this.contentName = params['contentName'];
        this.loadContent();
      });
    }
  }

  loadContent() {
    this.contentService.findByName(this.contentName).subscribe((content) => {
      this.content = content;
      this.unsafeHtml = this.sanitizer.bypassSecurityTrustHtml(content.html);
      if (this.currentUser.administrator && this.content.html.length < 5) {
        this.content.html = '<i>Placeholder for content...</i>';
      }
    });
  }

  editContent() {
    this.editContentModal.start();
  }

  onContentChangeEvent() {
    this.loadContent();
  }

  onShowEditButton(showBtn: boolean) {
    this.showEditBtn = showBtn;
  }
}
