import { Component, OnInit, ViewChild } from '@angular/core';
import { NztcsSpeciesService } from '../service/nztcs-species.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NztcsSpeciesUsage } from '../service/nztcs-species-usage';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { User } from '../../authentication/service/user';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import {
  ActionEvent,
  ActionState,
  MessageDialogComponent,
} from '../../common/message-dialog/message-dialog.component';

@Component({
  selector: 'app-nztcs-species-view',
  templateUrl: './nztcs-species-view.component.html',
})
export class NztcsSpeciesViewComponent implements OnInit {
  @ViewChild('messageDialog', { static: true })
  messageDialog: MessageDialogComponent;

  nztcsSpeciesId: number;
  selectedSpecies: NztcsSpeciesEntity;
  relatedAssessments: NztcsSpeciesUsage[] = null; // set to null to make it easier to disable at start

  currentUser: User;
  nameEditor = false;

  constructor(
    private nztcsSpeciesService: NztcsSpeciesService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.nztcsSpeciesId = +params['nztcsSpeciesId'];
      this.loadSpecies();
      this.loadRelatedAssessments();
    });

    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.nameEditor = this.currentUser && this.currentUser.nameEditor;
    });
  }

  loadSpecies() {
    this.nztcsSpeciesService
      .getNztcsSpecies(this.nztcsSpeciesId)
      .subscribe((nztcsSpecies) => {
        this.selectedSpecies = nztcsSpecies;
      });
  }

  loadRelatedAssessments() {
    this.nztcsSpeciesService
      .findNztcsSpeciesUsage(this.nztcsSpeciesId)
      .subscribe((relatedAssessments) => {
        this.relatedAssessments = relatedAssessments;
      });
  }

  editAction() {
    this.router.navigate(['nztcs-species-edit/' + this.nztcsSpeciesId]);
  }

  deleteAction() {
    const selectedSpecies = this.nztcsSpeciesId;
    const messageText =
      'Are you sure you want to delete this Species record. This action cannot be undone.';
    this.messageDialog.start(messageText, selectedSpecies);
  }

  onDeleteSpeciesConfirmed(actionEvent: ActionEvent) {
    if (actionEvent.state === ActionState.CONFIRMED) {
      this.nztcsSpeciesService
        .removeNztcsSpecies(actionEvent.payload)
        .subscribe((response) => {
          console.log('Species deleted');
          this.router.navigate(['/nztcs-species-search']);
        });
    }
  }
}
