<div class="container-fluid">
  <h1 class="page-header">Data Exports</h1>
  <hr />

  <div class="row">
    <div class="col-md-8">
      <table class="table">
        <thead>
          <tr>
            <th>Request Timestamp</th>
            <th>Progress</th>
            <th>Status</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody *ngIf="descriptorList?.length > 0">
          <tr *ngFor="let exportDescriptor of descriptorList">
            <td>{{ exportDescriptor.timestamp | date: 'dd-MMM HH:mm:ss' }}</td>
            <td>
              <progressbar
                [max]="100"
                [value]="exportDescriptor.percentageComplete"
              >
                <span style="color: white; white-space: nowrap"
                  >{{ exportDescriptor.percentageComplete }} %</span
                >
              </progressbar>
            </td>
            <td>{{ exportDescriptor.status }}</td>
            <td>
              <a
                style="color: white"
                (click)="downloadExportCsv(exportDescriptor.id)"
                class="btn btn-sm btn-success"
                *ngIf="exportDescriptor.status === 'COMPLETE'"
                >Download</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-md-2">
      <button
        type="submit"
        class="btn btn-primary btn-block"
        (click)="refreshAction()"
      >
        Refresh
      </button>
    </div>
  </div>
</div>
