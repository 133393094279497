import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { FileItem } from 'ng2-file-upload/file-upload/file-item.class';
import { ParsedResponseHeaders } from 'ng2-file-upload/file-upload/file-uploader.class';
import { AttachmentSearchCriteria } from '../service/attachment-search-criteria';
import { AttachmentService } from '../service/attachment.service';
import {
  ActionEvent,
  ActionState,
  MessageDialogComponent,
} from '../../common/message-dialog/message-dialog.component';
import {AuthenticationService} from '../../authentication/service/authentication.service';
import {
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

// const URL = '/api/';
// const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';
const URL = '/rest/attachment/files';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  @Output()
  fileUploadEvent = new EventEmitter();

  private static nextId: number = 1;

  @Input()
  context: string;

  @Input()
  publicallyVisible: boolean = false;

  @ViewChild('confirmUploadDialog', { static: true })
  confirmUploadDialog: MessageDialogComponent;

  @ViewChild('fileErrorDialog', { static: true })
  fileErrorDialog: MessageDialogComponent;

  uploader: FileUploader;
  hasBaseDropZoneOver: boolean;
  hasAnotherDropZoneOver: boolean;
  response: string;
  responseType: string;
  componentId: number;

  removeIcon = faXmark;

  errorsTimeout: any /*Timeout*/ = null;
  erroredFiles: string[] = [];

  constructor(
    private attachmentService: AttachmentService,
    private authenticationService: AuthenticationService
  ) {
    // Hack to allow the function to be passed in an object to the message dialog
    this.closeFileErrors = this.closeFileErrors.bind(this);
    this.componentId = FileUploadComponent.nextId++;
  }

  ngOnInit(): void {
    this.uploader = new FileUploader({
      allowedMimeType: [
        'application/msword', // .doc
        'application/pdf', // .pdf
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
        'application/vnd.ms-excel', // .xls
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
        'image/jpeg', // .jpe?g
        'image/png', // .png
        'text/csv' // .csv
      ],
      url: URL,
      authToken: this.authenticationService.getAuthToken(),
      disableMultipart: false, // 'DisableMultipart' must be 'true' for formatDataFunction to be called.
      formatDataFunctionIsAsync: false,
      additionalParameter: {
        context: this.context,
        publicallyVisible: this.publicallyVisible
      },
      formatDataFunction: async (item) => {
        new Promise((resolve, reject) => {
          resolve({
            name: item._file.name,
            length: item._file.size,
            contentType: item._file.type,
            date: new Date()
          });
        });
      },
    });

    this.hasBaseDropZoneOver = false;
    this.hasAnotherDropZoneOver = false;

    this.response = '';

    this.uploader.response.subscribe((res) => {
      try {
        res = JSON.parse(res);
        if (res.error) {
          this.response = res.message;
          this.responseType = 'danger';
        } else {
          this.response = res.object.filename + " uploaded successfully";
          this.responseType = 'success';
        }
      } catch {
        this.response = res;
        this.responseType = 'secondary';
      }
    });

    this.uploader.onSuccessItem = (
      item: FileItem,
      response: string,
      status: number,
      headers: ParsedResponseHeaders
    ) => {
      console.log('Got success item: ' + status);
      this.fileUploadEvent.next('FileUploaded');
    };

    this.uploader.onWhenAddingFileFailed = (file, filter, options) => {
      if (this.errorsTimeout !== null) {
        clearTimeout(this.errorsTimeout);
      }
      this.erroredFiles.push(file.name);
      this.errorsTimeout = setTimeout(this.showErrors.bind(this), 100);
    };

    this.uploader.onAfterAddingFile = () => {
      this.response = null;
      this.responseType = null;
    };
  }

  showErrors() {
    this.fileErrorDialog.show();
    this.errorsTimeout = null;
  }

  closeFileErrors() {
    this.erroredFiles = [];
    this.fileErrorDialog.hide();
  }

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }

  clearResponse() {
    this.response = null;
    this.responseType = null;
  }

  removeFile(item: FileItem) {
    this.uploader.removeFromQueue(item);
  }

  uploadAction(item: FileItem) {
    // check if there are any existing files with this filename in or outside of this context
    const criteria = new AttachmentSearchCriteria();
    criteria.nameCriteria = item.file.name;
    criteria.publicallyVisible = this.publicallyVisible;
    this.attachmentService
      .findByCriteria(criteria)
      .subscribe((response: any) => {
        if (response.searchResults && response.searchResults.length > 0) {
          let sameContext = false;
          let differentContext = false;
          response.searchResults.forEach((attachment) => {
            if (attachment.context === this.context) {
              sameContext = true;
            } else {
              differentContext = true;
            }
          });

          let msg = '';
          if (sameContext) {
            msg +=
              'There is already a file uploaded for this context with the same name. Uploading it again will update the current file for this context.';
          }

          if (sameContext && differentContext) {
            if (msg.length > 0) {
              msg += ' ';
            }
            msg +=
              'There is also a file in a different context with the same name. Uploading this file will not update the other file.';
          }

          if (!sameContext && differentContext) {
            if (msg.length > 0) {
              msg += ' ';
            }
            msg +=
              'There is another file in a different context with the same name. Uploading it again will create a new file in the current context.';
          }

          this.confirmUploadDialog.start(msg, item);
        } else {
          item.upload();
        }
      });
  }

  uploadActionConfirmed(actionEvent: ActionEvent) {
    if (actionEvent.state === ActionState.CONFIRMED) {
      const item: FileItem = actionEvent.payload;
      item.upload();
    }
  }
}
