import { Component, OnInit, ViewChild } from '@angular/core';
import { TagService } from '../service/tag.service';
import { Tag } from '../../domain/nztcs/tag';
import { TagEditComponent } from '../tag-edit/tag-edit.component';
import {
  faPencilAlt,
  faPlus,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-tag-list',
  templateUrl: './tag-list.component.html',
})
export class TagListComponent implements OnInit {
  @ViewChild('tagEditComponent', { static: true })
  tagEditComponent: TagEditComponent;

  tags: Tag[];

  editIcon = faPencilAlt;
  deleteIcon = faTrashAlt;
  newIcon = faPlus;

  constructor(private tagService: TagService) {}

  ngOnInit() {
    this.loadTags();
  }

  loadTags() {
    this.tagService.findAll().subscribe((tags) => (this.tags = tags));
  }

  addNewTagAction() {
    this.tagEditComponent.editTag(new Tag());
  }

  editTagAction(tag: Tag) {
    this.tagEditComponent.editTag(tag);
  }

  deleteTagAction(tag: Tag) {
    console.log('Delete tag: ' + tag.tagId);
    if (tag.tagId) {
      this.tagService.remove(tag.tagId, true).subscribe(() => {
        this.loadTags();
      });
    }
  }

  onTagChangedEvent() {
    this.loadTags();
  }
}
