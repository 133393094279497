import { Component, OnInit } from '@angular/core';
import { NameMatchSearchCtxService } from '../service/name-match-search-ctx.service';

@Component({
  selector: 'app-name-match-search',
  templateUrl: './name-match-search.component.html',
  styleUrls: ['./name-match-search.component.css'],
})
export class NameMatchSearchComponent implements OnInit {
  constructor(public searchCtx: NameMatchSearchCtxService) {}

  ngOnInit() {
    this.searchAction();
  }

  public searchAction() {
    this.searchCtx.search();
  }

  public clearAction() {
    this.searchCtx.clear();
  }

  onPageChanged(event: any): void {
    this.searchCtx.criteria.pageNumber = event.page;
    this.searchAction();
  }
}
