<div
  class="container-fluid p-0"
  *ngIf="assessment && assessment.model && report && report.model"
>
  <br />

  <div class="row">
    <div class="col-md-4"><b>Species Name</b></div>
    <div class="col-md-8">
      <span [innerHTML]="assessment.model.speciesAssessmentName"></span>
      <button
        type="button"
        class="btn btn-success btn-sm"
        data-toggle="tooltip"
        data-placement="top"
        title="Edit this assessment's species name"
        (click)="editSpeciesNameAction()"
        *ngIf="editNamesAvailable"
      >
        <fa-icon [icon]="editIcon"></fa-icon>
      </button>
      <app-name-source
        [nameSource]="assessment.model.nameSource"
      ></app-name-source>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"><b>Edit Status</b></div>
    <div class="col-md-8">
      <div>
        <app-status-badge
          [statusValue]="assessment.model.editStatus"
        ></app-status-badge>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"><b>Report</b></div>
    <div class="col-md-8">
      <a routerLink="/reports/{{ report.model.reportId }}">{{
        report.model.name
      }}</a>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-4"><b>Population State</b></div>
    <div class="col-md-8" *ngIf="assessment.model.populationState">
      {{ assessment.model.populationState | lowercase }}
    </div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Population Trend</b></div>
    <div
      class="col-md-8"
      *ngIf="assessment.model.populationTrend"
      tooltip="{{ assessment.model.populationTrend.description }}"
    >
      {{ assessment.model.populationTrend.title }}
      <small
        ><i>{{ populationTrendLabel }}</i></small
      >
    </div>
  </div>
  <div class="row">
    <div class="col-md-4"><b>Population Size</b></div>
    <div
      class="col-md-8"
      *ngIf="assessment.model.populationSize"
      tooltip="{{ assessment.model.populationSize.description }}"
    >
      {{ assessment.model.populationSize.title }}
      <small>
        <i>{{
          assessment.model.popSizeConfidence !== null
            ? '(' + assessment.model.popSizeConfidence.title + ' confidence)'
            : null
        }}</i>
      </small>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-4"><b>Conservation Status</b></div>
    <div
      class="col-md-4"
      *ngIf="assessment.model.category"
      tooltip="{{ assessment.model.category.description }}"
    >
      {{ assessment.model.category.title }}
    </div>
    <div class="col-md-4">
      <label class="control-label"><b>Umbrella Category</b></label>
      <div class="form-control bg-light h-auto" *ngIf="assessment.model.category">
        {{ assessment.model.category.umbrellaCategory }}
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"></div>
    <div class="col-md-4"></div>
    <div class="col-md-4">
      <label class="control-label"><b>{{ report.model.reportType.code === 'R' ? 'Criteria Code' : 'Criteria'}}</b></label>
      <div class="form-control bg-light h-auto">{{ assessment.model.criteria }}</div>
    </div>
  </div>

  <hr />

  <div class="row">
    <div class="col-md-4"><b>Status Change</b></div>
    <div class="col-md-8" *ngIf="assessment.model.statusChange">
      {{ assessment.model.statusChange.title }}
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"><b>Change Reason</b></div>
    <div class="col-md-8" *ngIf="assessment.model.changeReason">
      {{ assessment.model.changeReason.title }}
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"><b>Qualifiers</b></div>
    <div class="col-md-8" *ngIf="assessment.model.qualifiers">
      <span *ngFor="let qualifier of assessment.model.qualifiers">
        <span
          class="badge badge-secondary"
          tooltip="{{ qualifier.description }}"
          >{{ qualifier.title }}</span
        >
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4"><b>Notes</b></div>
    <div class="col-md-8" [innerHTML]="assessment.model.publicNotes"></div>
  </div>

  <div class="row" *ngIf="privateNotesDisplayed">
    <div class="col-md-4"><b>Private Notes</b></div>
    <div class="col-md-8" [innerHTML]="assessment.model.notes"></div>
  </div>
</div>

<app-edit-name
  #editNameModal
  [assessmentId]="assessment.model.assessmentId"
  [assessmentName]="assessment.model.speciesAssessmentName"
  (editNameEvent)="onEditNameEvent($event)"
>
</app-edit-name>
