<div class="container-fluid">
  <h2>Person search</h2>
  <hr />

  <form class="form-inline">
    <div class="form-group">
      <label class="mr-3" for="nameCriteriaTxtFld">Name</label>
      <input
        id="nameCriteriaTxtFld"
        type="text"
        class="form-control mr-3"
        [(ngModel)]="personCriteria.name"
        name="nameCriteriaTxtFld"
      />
    </div>

    <button type="submit" (click)="searchAction()" class="btn btn-primary mr-3">
      Search
    </button>
    <button type="button" (click)="clearAction()" class="btn btn-light mr-3">
      Clear
    </button>
    <button
      type="button"
      (click)="newPersonAction()"
      class="btn btn-success mr-3"
    >
      New Person
    </button>
  </form>

  <br />

  <h3>Search Results</h3>

  <table class="table table-bordered">
    <thead>
      <tr>
        <td>Name</td>
        <td>Username / Email</td>
      </tr>
    </thead>
    <tbody *ngIf="response.searchResults.length > 0">
      <tr *ngFor="let person of response.searchResults">
        <td>
          <a routerLink="/person-edit/{{ person.personId }}"
            >{{ person.firstName }} {{ person.familyName }}</a
          >
        </td>
        <td>{{ person.userName }}</td>
      </tr>
    </tbody>

    <tbody *ngIf="response.searchResults.length === 0">
      <td colspan="100">No matching search results found.</td>
    </tbody>
  </table>

  <pagination
    [maxSize]="5"
    [itemsPerPage]="personCriteria.pageSize"
    [(ngModel)]="personCriteria.pageNumber"
    [totalItems]="response.total"
    (pageChanged)="onPageChanged($event)"
  >
  </pagination>
</div>
