<div class="container-fluid">
  <h1>Reports</h1>
  <hr />

  <form class="form-inline" autocomplete="off">
    <div class="form-group w-50">
      <label class="mr-3" for="reportNameTxtFld">Report Name</label>

      <input
        type="text"
        style="width: 450px !important"
        class="form-control"
        id="reportNameTxtFld"
        name="reportNameTxtFld"
        [(ngModel)]="searchCtx.criteria.reportName"
        [typeahead]="reportNames"
        (change)="searchAction()"
      />
    </div>

    <div class="form-group mr-3">
      <div>
        <label>
          <input
            type="checkbox"
            [(ngModel)]="searchCtx.criteria.showArchived"
            (click)="onShowArchivedChanged()"
            name="chkBx1"
            id="chkBx1"
          />Show Archived
        </label>
      </div>
    </div>

    <button type="button" class="btn btn-light mr-3" (click)="clearAction()">
      Clear
    </button>
    <button
      type="button"
      class="btn btn-success mr-3"
      (click)="newReportAction()"
      *ngIf="currentUser?.administrator"
    >
      New Report
    </button>
  </form>

  <br />

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Report Name</th>
              <th>Year Assessed</th>
              <th>Year Published</th>
              <th>Edit Status</th>
              <th>Published Status</th>
            </tr>
          </thead>
          <tbody *ngIf="hasSearchResults()">
            <tr *ngFor="let report of searchCtx.response.searchResults">
              <td>
                <a routerLink="/reports/{{ report.reportId }}">{{
                  report.name
                }}</a>
              </td>
              <td>{{ report.yearAssessed }}</td>
              <td>{{ report.yearPublished }}</td>
              <td>
                <app-status-badge
                  [statusValue]="report.editStatus"
                ></app-status-badge>
              </td>
              <td>
                <app-status-badge
                  [statusValue]="report.publishedStatus"
                ></app-status-badge>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="!hasSearchResults()">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="searchCtx.criteria.pageSize"
        [(ngModel)]="searchCtx.criteria.pageNumber"
        [totalItems]="searchCtx.response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ searchCtx.response.total }}, Page:
      {{ searchCtx.response.page }} of {{ searchCtx.response.numberOfPages }}
    </div>
  </div>
</div>
