import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TagService } from '../../../tag/service/tag.service';
import { AssessmentService } from '../../service/assessment.service';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-edit-tags',
  templateUrl: './edit-tags.component.html',
})
export class EditTagsComponent implements OnInit {
  @Input()
  assessmentId: number;

  @Input()
  assessmentTags: any = [];

  @Output()
  assessmentTagsChange = new EventEmitter<any[]>();

  @ViewChild('editTagsModal', { static: true })
  public editTagsModal: ModalDirective;

  availableTags: any = [];
  selectedTags: any = [];

  selectedAvailableItems: any = [];
  selectedCurrentItems: any = [];

  addTagIcon = faChevronLeft;
  removeTagIcon = faChevronRight;

  constructor(
    private tagService: TagService,
    private assessmentService: AssessmentService
  ) {}

  static indexOfTag(tag, tagArray) {
    let index = -1;
    for (let i = 0; i < tagArray.length; i++) {
      if (tag.tagId === tagArray[i].tagId) {
        index = i;
      }
    }
    return index;
  }

  ngOnInit() {
    this.refreshAvailableTags();
  }

  showEditTags() {
    this.editTagsModal.show();
    this.refreshAvailableTags();
  }

  onCancelEditTags() {
    this.editTagsModal.hide();
  }

  refreshAvailableTags() {
    this.selectedTags = [];
    this.tagService.findAll().subscribe((response) => {
      this.availableTags = response;

      // add all of the assessment's tags to a scope variable, okay/cancel with either apply changes to the assessment (or not)
      for (const nextTag of this.assessmentTags) {
        this.selectedTags.push(nextTag);

        // remove from available list
        for (let j = this.availableTags.length - 1; j >= 0; j--) {
          const nextAvailableTag = this.availableTags[j];
          if (nextAvailableTag.tagId === nextTag.tagId) {
            this.availableTags.splice(j, 1);
          }
        }
      }
    });
  }

  onTagSelectChange(tagSelected: any, tagArray: any[]) {
    //console.log('onTagSelectChange() - ' + JSON.stringify(tagSelected));
    // not sure if this event is needed anymore
  }

  addTagAction() {
    for (const nextTag of this.selectedAvailableItems) {
      // add to selectedTags
      this.selectedTags.push(nextTag);

      // remove from available list
      this.availableTags.splice(
        EditTagsComponent.indexOfTag(nextTag, this.availableTags),
        1
      );
    }

    // reset the selection
    this.selectedAvailableItems.length = 0;
  }

  removeTagAction() {
    for (let i = this.selectedCurrentItems.length - 1; i >= 0; i--) {
      const nextTag = this.selectedCurrentItems[i];

      // remove from selected list
      this.selectedTags.splice(
        EditTagsComponent.indexOfTag(nextTag, this.selectedTags),
        1
      );

      // add to available tags
      this.availableTags.push(nextTag);
    }

    // reset the selection
    this.selectedCurrentItems.length = 0;
  }

  okayAction() {
    // clear existing tags
    this.assessmentTags.length = 0;

    // apply all edits made to the assessment
    for (const selectedTag of this.selectedTags) {
      this.assessmentTags.push(selectedTag);
    }

    this.assessmentService
      .saveTagsOnAssessment(this.assessmentId, this.assessmentTags)
      .subscribe((updatedTags) => {
        this.assessmentTags = updatedTags;
        this.assessmentTagsChange.emit(this.assessmentTags);
      });

    this.onCancelEditTags();
  }
}
