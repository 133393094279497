import { Injectable } from '@angular/core';
import { Content } from './content';
import { HttpClient } from '@angular/common/http';
import { ContentSearchCriteria } from '../content-search/content-search-criteria';

@Injectable()
export class ContentService {
  constructor(private http: HttpClient) {}

  findByName(name: string) {
    return this.http.get<Content>('/rest/content/' + name);
  }

  findByCriteria(criteria: ContentSearchCriteria) {
    return this.http.post('/rest/content/findContentByCriteria', criteria);
  }

  save(content: Content) {
    return this.http.post<any>('/rest/content', content);
  }

  findContentLinks() {
    const contentSearchCriteria = new ContentSearchCriteria();
    contentSearchCriteria.pageSize = 999;
    return this.findByCriteria(contentSearchCriteria);
  }

  removeContent(contentId: number) {
    return this.http.delete('/rest/content' + contentId);
  }

  getContentVisibilityOptions() {
    return ['HIDDEN_LINK', 'PUBLIC', 'AUTHENTICATED', 'ADMIN'];
  }
}
