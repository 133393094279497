<div class="container-fluid">
  <h2>NZOR Name Matches</h2>
  <hr />

  <form class="form-inline">
    <div class="form-group">
      <label class="mr-3" for="nameCriteria">Species Name</label>
      <input
        class="form-control mr-3"
        id="nameCriteria"
        name="nameCriteria"
        [(ngModel)]="searchCtx.criteria.scientificName"
      />
    </div>
    <button class="btn btn-primary mr-3" (click)="searchAction()">
      Search
    </button>
    <button class="btn btn-light mr-3" (click)="clearAction()">Clear</button>
  </form>

  <br />

  <div class="row">
    <div class="col-md-3">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h5 class="panel-title">Match Result</h5>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-md-6">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="searchCtx.criteria.mrExact"
                    name="chkBx1"
                    id="chkBx1"
                  />
                  Exact
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="searchCtx.criteria.mrIgnored"
                    id="chkBx2"
                  />
                  Ignored
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="searchCtx.criteria.mrMultiple"
                    id="chkBx3"
                  />
                  Multiple
                </label>
              </div>
            </div>
            <div class="col-md-6">
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="searchCtx.criteria.mrSkipped"
                    id="chkBx4"
                  />
                  Skipped
                </label>
              </div>
              <div class="checkbox">
                <label>
                  <input
                    type="checkbox"
                    [(ngModel)]="searchCtx.criteria.mrUnmatched"
                    id="chkBx5"
                  />
                  Unmatched
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-3">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h5 class="panel-title">NZOR Relationship</h5>
        </div>
        <div class="panel-body">
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="searchCtx.criteria.nrAuto"
                id="chkBx6"
              />
              Auto
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="searchCtx.criteria.nrAssigned"
                id="chkBx7"
              />
              Assigned
            </label>
          </div>
          <div class="checkbox">
            <label>
              <input
                type="checkbox"
                [(ngModel)]="searchCtx.criteria.nrIgnored"
                id="chkBx8"
              />
              Ignored
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <br />

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>Species ID</th>
              <th>Scientific Name</th>
              <th>Match Result</th>
              <th>NZOR ID</th>
              <th>NZOR Formatted Name</th>
            </tr>
          </thead>
          <tbody *ngIf="searchCtx.response.searchResults.length > 0">
            <tr *ngFor="let match of searchCtx.response.searchResults">
              <td>{{ match.speciesID }}</td>
              <td>
                <a routerLink="/name-match-detail/{{ match.speciesID }}">{{
                  match.scientificName
                }}</a>
              </td>
              <td>{{ match.matchResult }}</td>
              <td>
                <small
                  ><a href="http://nzor.org.nz/names/{{ match.nzorID }}">{{
                    match.nzorID
                  }}</a></small
                >
              </td>
              <td><span [innerHTML]="match.nzorFormattedName"></span></td>
            </tr>
          </tbody>

          <tbody *ngIf="searchCtx.response.searchResults.length === 0">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="searchCtx.criteria.pageSize"
        [(ngModel)]="searchCtx.criteria.pageNumber"
        [totalItems]="searchCtx.response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ searchCtx.response.total }}, Page:
      {{ searchCtx.criteria.pageNumber }} of
      {{ searchCtx.response.numberOfPages }}
    </div>
  </div>
</div>
