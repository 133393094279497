import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { EditorComponent } from '@tinymce/tinymce-angular';
import {
  faFileImage,
  faLink,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { TinymceUtils } from '../tinymce/tinymce-utils';
import { AttachmentService } from '../attachment/service/attachment.service';
import { AttachmentSearchCriteria } from '../attachment/service/attachment-search-criteria';
import { AttachmentEntity } from '../domain/nztcs/attachment-entity';
import {PagedResponse} from '../domain/response/PagedResponse';

@Component({
  selector: 'app-text-editor',
  templateUrl: './text-editor.component.html',
})
export class TextEditorComponent implements OnInit {
  @Input()
  text: string;

  @Input()
  context: string;

  @Input()
  height = 300;

  @Input()
  attachmentsEnabled: boolean;

  @Input()
  publicallyVisible: boolean = false;

  @Output()
  attachmentsChangedEvent = new EventEmitter();

  @ViewChild('editorComponent', { static: true })
  editorComponent: EditorComponent;

  editorSettings: any;
  attachmentsResponse: PagedResponse<AttachmentEntity>;

  linkIcon = faLink;
  imageIcon = faFileImage;
  deleteIcon = faTrashAlt;

  constructor(private attachmentService: AttachmentService) {}

  ngOnInit() {
    this.editorSettings = this.getEditorSettings();
    this.loadAttachments();
  }

  getEditorSettings() {
    const clone = Object.assign({}, TinymceUtils.editorSettings);
    clone.height = this.height;
    return clone;
  }

  loadAttachments() {
    const criteria = new AttachmentSearchCriteria();
    criteria.context = this.context;
    criteria.pageSize = 999;
    if (this.publicallyVisible) {
      criteria.publicallyVisible = true;
    }
    this.attachmentService
      .findByCriteria(criteria)
      .subscribe((response) => {
        this.attachmentsResponse = response;
      });
  }

  attachmentsChanged() {
    console.log('Text-editor: attachmentsChanged()');
    this.attachmentsChangedEvent.next('Changed');
    this.loadAttachments();
  }

  onInsertLinkAction(attachment: AttachmentEntity) {
    this.editorComponent.editor.execCommand(
      'mceInsertLink',
      false,
      '/rest/attachment/files/' + attachment.filename
    );
  }

  onDeleteAttachment(attachment: AttachmentEntity) {
    this.attachmentService.remove(attachment.attachmentId).subscribe(() => {
      this.attachmentsChanged();
    });
  }

  onInsertImageAction(attachment: any) {
    this.editorComponent.editor.execCommand(
      'mceInsertContent',
      false,
      '<img src="/rest/attachment/files/' + attachment.filename + '">'
    );
  }
}
