import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AttachmentSearchCriteria } from './attachment-search-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { AttachmentEntity } from '../../domain/nztcs/attachment-entity';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  constructor(private http: HttpClient) {}

  findByCriteria(criteria: AttachmentSearchCriteria) {
    return this.http.post<PagedResponse<AttachmentEntity>>(
      '/rest/attachment/findAttachmentByCriteria',
      criteria
    );
  }

  remove(attachmentId: number) {
    return this.http.delete('/rest/attachment/' + attachmentId);
  }
}
