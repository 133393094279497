import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TinymceUtils } from '../../tinymce/tinymce-utils';
import { Content } from '../service/content';
import { ContentService } from '../service/content.service';

@Component({
  selector: 'app-content-edit-dialog',
  templateUrl: './content-edit-dialog.component.html',
})
export class ContentEditDialogComponent implements OnInit {
  @Input()
  contentName: string;

  @ViewChild('editContentModal', { static: true })
  public editContentModal: ModalDirective;

  @Output()
  contentChangeEvent = new EventEmitter();

  content: Content;

  editorSettings = TinymceUtils.editorSettings;

  constructor(private contentService: ContentService) {}

  ngOnInit() {}

  start() {
    this.contentService.findByName(this.contentName).subscribe((content) => {
      this.content = content;
      this.editContentModal.show();
    });
  }

  onSave() {
    this.contentService.save(this.content).subscribe(() => {
      this.onCancel();
      this.contentChangeEvent.next();
    });
  }

  onCancel() {
    this.editContentModal.hide();
  }
}
