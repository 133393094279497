<div class="container-fluid">
  <h2>Conservation Status Assessments - Search</h2>
  <hr />

  <form autocomplete="off">
    <div class="row">
      <div class="col-md-10">
        <div class="row">
          <div class="col-md-6">
            <label class="control-label" for="reportNameTxtFld"
              >Report Name</label
            >
            <input
              type="text"
              class="form-control"
              id="reportNameTxtFld"
              name="reportNameTxtFld"
              [(ngModel)]="searchCtx.criteria.reportName"
              [typeahead]="reportNames"
            />
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label class="form-control-label" for="chkBx1"
                >Show Archived</label
              >
              <input
                class="form-check"
                type="checkbox"
                [(ngModel)]="searchCtx.criteria.showArchived"
                (click)="onShowArchivedChanged()"
                name="chkBx1"
                id="chkBx1"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="control-label" for="umbrellaCategory"
              >Umbrella Category</label
            >
            <select
              class="form-control"
              id="umbrellaCategory"
              name="umbrellaCategory"
              [(ngModel)]="searchCtx.criteria.umbrellaCategory"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let umbrellaCategory of umbrellaCategories"
                [ngValue]="umbrellaCategory"
              >
                {{ umbrellaCategory }}
              </option>
            </select>
          </div>
          <div class="col-md-3">
            <app-category-tags></app-category-tags>
          </div>
          <div class="col-md-3" *ngIf="currentUser.authenticated">
            <label class="control-label" for="editStatusSelect"
              >Edit Status</label
            >
            <select
              class="form-control"
              id="editStatusSelect"
              name="editStatusSelect"
              [(ngModel)]="searchCtx.criteria.editStatus"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let editStatus of editStatusOptions"
                [ngValue]="editStatus"
              >
                {{ editStatus }}
              </option>
            </select>
          </div>

          <div class="col-md-3" *ngIf="currentUser.authenticated">
            <label class="control-label" for="tagSelect">Tag</label>
            <select
              class="form-control"
              id="tagSelect"
              name="tagSelect"
              [(ngModel)]="searchCtx.criteria.tag"
            >
              <option [ngValue]="null"></option>
              <option *ngFor="let tag of availableTags" [ngValue]="tag">
                {{ tag.tagName }}
              </option>
            </select>
          </div>
        </div>

        <div class="row">
          <div class="col-md-3">
            <label class="control-label" for="nameTxtFld">Name</label>
            <input
              class="form-control"
              id="nameTxtFld"
              name="nameTxtFld"
              [(ngModel)]="searchCtx.criteria.assessmentName"
              [typeahead]="dataSource"
              [typeaheadAsync]="true"
              [typeaheadWaitMs]="100"
              [typeaheadOptionsLimit]="16"
            />
          </div>

          <div class="col-md-3">
            <label class="control-label" for="environmentSelect"
              >Environment</label
            >
            <select
              class="form-control"
              id="environmentSelect"
              name="environmentSelect"
              [(ngModel)]="searchCtx.criteria.environment"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let environment of environmentOptions"
                [ngValue]="environment"
              >
                {{ environment }}
              </option>
              <option [ngValue]="'Is Blank'">Is Blank</option>
            </select>
          </div>

          <div class="col-md-3">
            <label class="control-label" for="taxonomicStatus"
              >Taxonomic Status</label
            >
            <select
              class="form-control"
              id="taxonomicStatus"
              name="taxonomicStatus"
              [(ngModel)]="searchCtx.criteria.taxonomicStatus"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let taxonomicStatus of taxonomicStatuses"
                [ngValue]="taxonomicStatus"
              >
                {{ taxonomicStatus }}
              </option>
            </select>
          </div>

          <div class="col-md-3">
            <label for="speciesTypeTxtFld">Species Type</label>
            <select
              class="form-control"
              id="speciesTypeTxtFld"
              name="speciesTypeTxtFld"
              [(ngModel)]="searchCtx.criteria.speciesType"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let speciesType of speciesTypes"
                [ngValue]="speciesType"
              >
                {{ speciesType }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="submit"
          class="btn btn-primary btn-block"
          (click)="searchAction()"
        >
          Search
        </button>
        <button class="btn btn-light btn-block" (click)="clearAction()">
          Clear
        </button>
        <button class="btn btn-success btn-block" (click)="exportAction()">
          Export
        </button>
      </div>
    </div>
  </form>

  <br />

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Report</th>
              <th>Species Name</th>
              <th>Common Name</th>
              <th>Umbrella Category</th>
              <th>Conservation Status</th>
              <!--<th>Criteria</th>-->
              <th>Pop. Size</th>
              <th>Pop. Trend</th>
              <th>Qualifiers</th>
              <th>Change</th>
              <!--<th>Reason</th>-->
              <th>Status</th>
            </tr>
          </thead>
          <tbody *ngIf="searchCtx.response.searchResults === null">
            <tr>
              <td colspan="12">Search not yet executed.</td>
            </tr>
          </tbody>

          <tbody *ngIf="searchCtx.response.searchResults.length === 0">
            <tr>
              <td colspan="12">No search results found.</td>
            </tr>
          </tbody>

          <tbody *ngIf="searchCtx.response.searchResults.length > 0">
            <tr *ngFor="let searchResult of searchCtx.response.searchResults">
              <td>
                <a routerLink="/reports/{{ searchResult.reportId }}">{{
                  searchResult.reportName
                }}</a>
              </td>
              <td>
                <a routerLink="/assessments/{{ searchResult.assessmentId }}">
                  <span [innerHTML]="searchResult.assessmentName"></span>
                </a>
                <ng-container
                  *ngIf="
                    !nztcsSpeciesNameComparisonService.areTheSame(
                      searchResult.assessmentName,
                      searchResult.nztcsSpeciesName
                    )
                  "
                >
                  <br />
                  <span [innerHTML]="searchResult.nztcsSpeciesName"></span>
                </ng-container>
              </td>
              <td>{{ searchResult.commonName }}</td>
              <td>{{ searchResult.categoryTitle }}</td>
              <td>{{ searchResult.conservationStatusTitle }}</td>
              <!--<td>{{searchResult.criteriaTitle}}</td>-->
              <td>{{ searchResult.popSizeTitle }}</td>
              <td>{{ searchResult.popTrendTitle }}</td>
              <td>{{ searchResult.qualifiers }}</td>
              <td>{{ searchResult.statusChangeTitle }}</td>
              <!--<td>{{searchResult.changeReasonTitle}}</td>-->
              <td>
                <app-status-badge
                  [statusValue]="searchResult.editStatus"
                ></app-status-badge>
                <app-status-badge
                  [statusValue]="searchResult.publishedStatus"
                ></app-status-badge>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="searchCtx.criteria.pageSize"
        [(ngModel)]="searchCtx.criteria.pageNumber"
        [totalItems]="searchCtx.response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>
    <div class="col-md-6 text-right">
      Total Results: {{ searchCtx.response.total }}, Page:
      {{ searchCtx.criteria.pageNumber }} of
      {{ searchCtx.response.numberOfPages }}
    </div>
  </div>
</div>
