<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">View Taxon</h2>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-md-4">
      <div class="card" *ngIf="dto && dto.nztcsTaxonEntity">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h5>Taxon</h5>
            </div>
            <div class="col-md-6" *ngIf="nameEditor">
              <div class="btn-group d-inline">
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  (click)="editAction()"
                >
                  Edit
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-sm"
                  (click)="moveTaxonAction()"
                >
                  Move
                </button>
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="deleteAction()"
                  [disabled]="
                    !dto ||
                    (dto.children && dto.children.length > 0) ||
                    (dto.speciesList && dto.speciesList.length > 0)
                  "
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-md-4"><b>Name</b></div>
            <div class="col-md-8">{{ dto.nztcsTaxonEntity.taxonName }}</div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Rank</b></div>
            <div class="col-md-8">{{ dto.nztcsTaxonEntity.taxonRank }}</div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Species Type</b></div>
            <div class="col-md-6">{{ dto.nztcsTaxonEntity.speciesType }}</div>
            <div class="col-md-2">
              <button
                class="btn btn-sm btn-success"
                type="button"
                (click)="changeSpeciesType()"
                *ngIf="nameEditor"
              >
                <fa-icon [icon]="faPencil"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card" *ngIf="dto && dto.parent">
        <h5 class="card-header">Parent</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4"><b>Name</b></div>
            <div class="col-md-8">
              <a routerLink="/nztcs-taxon/{{ dto.parent.nztcsTaxonId }}">{{
                dto.parent.taxonName
              }}</a>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Rank</b></div>
            <div class="col-md-8">{{ dto.parent.taxonRank }}</div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Species Type</b></div>
            <div class="col-md-8">{{ dto.parent.speciesType }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4" *ngIf="dto && dto.children && dto.children.length > 0">
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h5>Child Taxa</h5>
            </div>
            <div class="col-md-6" align="right"></div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" *ngFor="let child of dto.children">
              <span
                ><a routerLink="/nztcs-taxon/{{ child.nztcsTaxonId }}">{{
                  child.taxonName
                }}</a></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="row mt-4"
    *ngIf="dto && dto.speciesList && dto.speciesList.length > 0"
  >
    <div class="col-md-8">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h5>Species</h5>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" *ngFor="let species of dto.speciesList">
              <span
                ><a
                  routerLink="/nztcs-species/{{ species.nztcsSpeciesId }}"
                  [innerHTML]="species.scientificName"
                ></a
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-taxon-name-move-dialog
  #taxonNameMoveDialogComponent
></app-taxon-name-move-dialog>
<app-change-species-type-dialog
  #changeSpeciesTypeDialogComponent
></app-change-species-type-dialog>
