<div class="container-fluid">
  <div class="row justify-content-between">
    <div class="col-md-4">
      <h2 id="viewHdg">Code Values</h2>
    </div>
    <div class="col-md-3">
      <form class="form-inline">
        <label class="mr-3" for="editableCodeTypesSelect">Code Type</label>
        <select
          type="text"
          class="form-control"
          id="editableCodeTypesSelect"
          name="editableCodeTypesSelect"
          (change)="updateSelectedCodeType($event)"
          [compareWith]="compareOnCode"
          [(ngModel)]="selectedCodeType"
        >
          <option
            *ngFor="let codeType of editableCodeTypes"
            [ngValue]="codeType"
          >
            {{ codeType.name }}
          </option>
        </select>
      </form>
    </div>
  </div>
  <hr />

  <h4>{{ selectedCodeType.name }}</h4>

  <div class="row">
    <div class="col-md-10">
      <table class="table table-bordered">
        <thead>
          <tr>
            <td>Code</td>
            <td>Title</td>
            <td>Description</td>
            <td>Active</td>
            <td>In Use</td>
            <td>Actions</td>
          </tr>
        </thead>
        <tbody *ngIf="codeValues && codeValues.length > 0">
          <tr *ngFor="let codeValue of codeValues">
            <td>{{ codeValue.code }}</td>
            <td>{{ codeValue.title }}</td>
            <td>{{ codeValue.description }}</td>
            <td>{{ codeValue.active | yesNo }}</td>
            <td>{{ codeValue.inUse | yesNo }}</td>
            <td>
              <div class="btn-group">
                <button
                  class="btn btn-sm btn-primary"
                  (click)="changeActive(codeValue, true)"
                >
                  <fa-icon [icon]="activateIcon"></fa-icon>
                </button>
                <button
                  class="btn btn-sm btn-primary"
                  (click)="changeActive(codeValue, false)"
                >
                  <fa-icon [icon]="deactivateIcon"></fa-icon>
                </button>
              </div>
              <div class="btn-group">
                <button
                  class="btn btn-sm btn-success"
                  (click)="editCodeValueAction(codeValue)"
                >
                  <fa-icon [icon]="editIcon"></fa-icon>
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  (click)="deleteCodeValueAction(codeValue)"
                  *ngIf="!codeValue.inUse"
                >
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <button class="btn btn-success" (click)="addNewCodeValueAction()">
                <fa-icon [icon]="newIcon"></fa-icon>
                Add New Code
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<app-code-value-edit
  (codeValueUpdated)="codeValueUpdatedEvent($event)"
  #codeValueEditComponent
></app-code-value-edit>
