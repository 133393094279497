<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Species Details</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="saveAction()"
        >
          Save
        </button>
        <button
          type="button"
          class="btn btn-light btn-sm"
          (click)="cancelAction()"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="selectedSpecies">
    <div class="col-md-5">
      <tabset>
        <tab heading="NZTCS">
          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3 species-source-heading-background">
                <div class="card-body">
                  <h5 class="card-title">NZTCS Species</h5>
                  <small style="visibility: hidden"
                    ><b>Spacer</b> spacer not visible</small
                  >
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="responseMessages && responseMessages.length > 0">
            <alert type="danger" *ngFor="let msg of responseMessages">
              {{ msg.description }}
            </alert>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Taxonomy</h5>
                  <div class="a-container">
                    <div class="a-row">
                      <div class="a-form-label mb-4">Hierarchy</div>
                      <div class="a-cell-value">
                        <!-- mb-4 so the button doesn't push the rows out of alignment -->
                        <app-taxon-name-list
                          [taxonName]="selectedSpecies.taxonName"
                        ></app-taxon-name-list>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="speciesType"
                      >Species Type</label
                    >
                    <select
                      class="form-control"
                      id="speciesType"
                      name="speciesType"
                      [(ngModel)]="selectedSpecies.speciesType"
                      (change)="changeName()"
                    >
                      <option
                        *ngFor="let speciesType of speciesTypes"
                        [ngValue]="speciesType"
                      >
                        {{ speciesType }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Formal Name</h5>

                  <div class="form-group">
                    <label class="a-form-label">Name and Authority</label>
                    <div class="input-group">
                      <!-- you can't render html inside of an input field, so we'll make the div look like an non-editable input field -->
                      <div
                        class="form-control bg-light h-auto"
                        [innerHTML]="selectedSpecies.nameAndAuthority"
                      ></div>
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          (click)="editNameAndAuthority()"
                        >
                          <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                      </div>
                      <button
                        class="btn btn-sm btn-success ml-2"
                        type="button"
                        (click)="changeName()"
                      >
                        <fa-icon [icon]="faSync"></fa-icon>
                      </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="a-form-label">Scientific Name</label>
                    <div class="input-group">
                      <!-- you can't render html inside of an input field, so we'll make the div look like an non-editable input field -->
                      <div
                        class="form-control bg-light h-auto"
                        [innerHTML]="selectedSpecies.scientificName"
                      ></div>
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          (click)="editScientificName()"
                        >
                          <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                      </div>
                      <button
                        class="btn btn-sm btn-success ml-2"
                        type="button"
                        (click)="changeName()"
                      >
                        <fa-icon [icon]="faSync"></fa-icon>
                      </button>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="a-form-label">Genus</label>
                    <!--<input type="text" class="form-control" id="genus" [(ngModel)]="selectedSpecies.taxon.taxon" name="genus" (change)="changeName()">
                    <button class="btn btn-sm btn-success" type="button" (click)="editTaxon()">
                      <fa-icon [icon]="faPencil"></fa-icon>
                    </button>-->

                    <div class="input-group">
                      <div
                        class="form-control bg-light h-auto"
                        [innerHTML]="selectedSpecies?.taxonName?.taxonName"
                      ></div>
                      <div class="input-group-append">
                        <button
                          class="btn btn-success"
                          type="button"
                          (click)="editTaxon()"
                        >
                          <fa-icon [icon]="faPencil"></fa-icon>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="speciesEpithet"
                      >Species Epithet</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="speciesEpithet"
                      [(ngModel)]="selectedSpecies.speciesEpithet"
                      name="speciesEpithet"
                      (change)="changeName()"
                    />
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="trinomialType"
                      >Trinomial Type</label
                    >
                    <select
                      class="form-control"
                      id="trinomialType"
                      name="trinomialType"
                      [(ngModel)]="selectedSpecies.trinomialType"
                      (change)="changeName()"
                    >
                      <option [ngValue]="null"></option>
                      <option
                        *ngFor="let trinomialType of trinomialTypes"
                        [ngValue]="trinomialType"
                      >
                        {{ trinomialType }}
                      </option>
                    </select>
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="trinomial"
                      >Trinomial Epithet</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="trinomial"
                      [(ngModel)]="selectedSpecies.trinomialEpithet"
                      name="trinomial"
                      (change)="changeName()"
                    />
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="namingAuthority"
                      >Naming Authority</label
                    >
                    <input
                      type="text"
                      class="form-control"
                      id="namingAuthority"
                      [(ngModel)]="selectedSpecies.namingAuthority"
                      name="namingAuthority"
                      (change)="changeName()"
                    />
                  </div>

                  <div class="form-group">
                    <label class="a-form-label" for="taxonomicStatus"
                      >Taxonomic Status</label
                    >
                    <select
                      class="form-control"
                      id="taxonomicStatus"
                      name="taxonomicStatus"
                      [(ngModel)]="selectedSpecies.taxonomicStatus"
                    >
                      <option
                        *ngFor="let taxonomicStatus of taxonomicStatusList"
                        [ngValue]="taxonomicStatus"
                      >
                        {{ taxonomicStatus }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Other Names</h5>
                  <div class="a-container">
                    <div class="form-group">
                      <label class="a-form-label" for="commonName"
                        >Common Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="commonName"
                        [(ngModel)]="selectedSpecies.commonName"
                        name="commonName"
                      />
                    </div>

                    <div class="form-group">
                      <label class="a-form-label" for="maoriName"
                        >Māori Name</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="maoriName"
                        [(ngModel)]="selectedSpecies.maoriName"
                        name="maoriName"
                      />
                    </div>

                    <div class="form-group">
                      <label class="a-form-label" for="alternativeNames"
                        >Alternative Names</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="alternativeNames"
                        [(ngModel)]="selectedSpecies.alternativeNames"
                        name="alternativeNames"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Attributes</h5>

                  <div class="a-container">
                    <div class="form-group">
                      <label class="a-form-label" for="bioStatusCategory"
                        >Biostatus</label
                      >
                      <select
                        class="form-control"
                        id="bioStatusCategory"
                        name="bioStatusCategory"
                        [(ngModel)]="selectedSpecies.biostatusCategory"
                      >
                        <option
                          *ngFor="let bioStatusOption of bioStatusOptions"
                          [ngValue]="bioStatusOption"
                        >
                          {{ bioStatusOption }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label class="a-form-label" for="environmentSelect"
                        >Environment</label
                      >
                      <select
                        class="form-control"
                        id="environmentSelect"
                        name="environmentSelect"
                        [(ngModel)]="selectedSpecies.environmentCategory"
                      >
                        <option
                          *ngFor="let environment of environmentOptions"
                          [ngValue]="environment"
                        >
                          {{ environment }}
                        </option>
                      </select>
                    </div>

                    <div class="form-group">
                      <label class="a-form-label" for="generationTimeInYears"
                        >Generation Time</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id="generationTimeInYears"
                        [(ngModel)]="selectedSpecies.generationTimeInYears"
                        name="generationTimeInYears"
                      />
                    </div>

                    <div
                      class="form-group"
                      *ngIf="selectedSpecies.speciesType === 'Fungus'"
                    >
                      <label class="a-form-label" for="lifeMode"
                        >Life Mode</label
                      >
                      <select
                        class="form-control"
                        id="lifeMode"
                        name="lifeMode"
                        [(ngModel)]="selectedSpecies.lifeMode"
                      >
                        <option [ngValue]="null"></option>
                        <option
                          *ngFor="let lifeMode of lifeModeOptions"
                          [ngValue]="lifeMode"
                        >
                          {{ lifeMode }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="card mb-3">
                <div class="card-body">
                  <h5 class="card-title">Notes</h5>
                  <editor
                    [init]="editorSettings"
                    [(ngModel)]="selectedSpecies.notes"
                  ></editor>
                </div>
              </div>
            </div>
          </div>
        </tab>
      </tabset>
    </div>

    <div class="col-md-5">
      <!--<app-species-source-list [speciesSourceItems]="speciesSourceItems"></app-species-source-list>-->
      <tabset>
        <tab heading="BioWeb">
          <app-bioweb-species-source
            [nztcsSpecies]="selectedSpecies"
            (copy)="onBiowebNameCopy($event)"
          ></app-bioweb-species-source>
        </tab>
        <tab heading="NZOR">
          <app-nzorspecies-source
            [nztcsSpecies]="selectedSpecies"
          ></app-nzorspecies-source>
        </tab>
      </tabset>
    </div>
  </div>
</div>

<div *ngIf="selectedSpecies">
  <app-edit-species-name
    #editNameAndAuthorityModal
    [editableName]="selectedSpecies.nameAndAuthority"
    (editNameEvent)="editNameAndAuthorityUpdateEvent($event)"
  ></app-edit-species-name>
  <app-edit-species-name
    #editScientificNameModal
    [editableName]="selectedSpecies.scientificName"
    (editNameEvent)="editScientificNameUpdateEvent($event)"
  ></app-edit-species-name>
</div>

<app-taxon-select #taxonSelectModal></app-taxon-select>
