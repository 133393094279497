<div class="card">
  <div class="card-header">
    <h4>Species Details</h4>
  </div>
  <div class="card-body" *ngIf="species">
    <div class="row">
      <div class="col-md-4"><b>Name and Authority</b></div>
      <div class="col-md-8">
        <a routerLink="/nztcs-species/{{ species.nztcsSpeciesId }}">
          <span [innerHTML]="species.nameAndAuthority"></span>
        </a>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Trinomial Type</b></div>
      <div class="col-md-8">{{ species.trinomialType }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Trinomial Epithet</b></div>
      <div class="col-md-8">{{ species.trinomialEpithet }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Taxonomy</b></div>
      <div class="col-md-8">
        <app-taxon-name-list
          [taxonName]="species.taxonName"
        ></app-taxon-name-list>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Taxonomic Status</b></div>
      <div class="col-md-8">{{ species.taxonomicStatus }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Common Name</b></div>
      <div class="col-md-8">{{ species.commonName }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Māori Name</b></div>
      <div class="col-md-8">{{ species.maoriName }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Alternative Names</b></div>
      <div class="col-md-8">{{ species.alternativeNames }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Species Type</b></div>
      <div class="col-md-8">{{ species.speciesType }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Bio Status</b></div>
      <div class="col-md-8">{{ species.biostatusCategory }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Environment</b></div>
      <div class="col-md-8">{{ species.environmentCategory }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Generation Time</b></div>
      <div class="col-md-8">
        <span *ngIf="species && species.generationTimeInYears">
          {{ species.generationTimeInYears }} (years)
        </span>
      </div>
    </div>

    <div class="row" *ngIf="species.speciesType === 'Fungus'">
      <div class="col-md-4"><b>Life Mode</b></div>
      <div class="col-md-8">{{ species.lifeMode }}</div>
    </div>

    <div class="row">
      <div class="col-md-4"><b>Notes</b></div>
      <div class="col-md-8">
        <div [innerHTML]="species.notes"></div>
      </div>
    </div>
  </div>
</div>
