import { takeUntil } from 'rxjs/operators';
import {
  AfterViewChecked,
  Component,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, NgForm } from '@angular/forms';

import { PersonService } from '../service/person.service';
import { ResponseCargo } from '../../domain/response/ResponseCargo';
import { PersonEntity } from '../../domain/person/person-entity';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { Subject } from 'rxjs';
import { User } from '../../authentication/service/user';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ResetPasswordComponent } from './reset-password/reset-password.component';

const validateEmail = (c: FormControl) =>
  c.value && c.value.indexOf('@') > -1
    ? null
    : {
        validateEmail: {
          valid: false,
        },
      };

@Component({
  selector: 'app-person-edit',
  templateUrl: 'person-edit.component.html',
  styleUrls: ['person-edit.component.css'],
})
export class PersonEditComponent implements OnInit, AfterViewChecked {
  @ViewChild('personForm')
  currentForm: NgForm;

  @ViewChild('resetPasswordComponent', { static: true })
  resetPasswordComponent: ResetPasswordComponent;
  editMode = false;
  personId: number;
  person = PersonEntity.newPerson();
  responseMessages: any[];
  resetPasswordRequired = false;

  personForm: NgForm;

  currentUser: User;

  deleteEnabled = false;

  formErrors = {
    username: '',
    emailAddress: '',
  };

  validationMessages = {
    username: {
      required: 'Username is required.',
      minlength: 'NXXXame must be at least 4 characters long.',
      maxlength: 'NXXXame cannot be more than 24 characters long.',
      forbiddenName: 'XXXSomeone named "Bob" cannot be a hero.',
    },

    emailAddress: {
      required: 'Email Address is required.',
    },
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public personService: PersonService,
    public authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );

    // Router subscriptions don't need ngUnscribe pattern
    this.route.params.subscribe((params) => {
      this.personId = +params['personId'];

      if (this.personId && this.personId > -1) {
        this.loadPerson();
      } else {
        this.newPerson();
      }
    });
  }

  ngAfterViewChecked() {
    this.formChanged();
  }

  formChanged() {
    if (this.currentForm === this.personForm) {
      return;
    }

    this.personForm = this.currentForm;

    if (this.personForm) {
      this.personForm.valueChanges.subscribe((data) =>
        this.onValueChanged(data)
      );
    }
  }

  onValueChanged(data?: any) {
    if (!this.personForm) {
      return;
    }

    const form = this.personForm.form;

    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        // clear previous error message (if any)
        this.formErrors[field] = '';
        const control = form.get(field);

        if (control && control.dirty && !control.valid) {
          const messages = this.validationMessages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += messages[key];
            }
          }
        }
      }
    }
  }

  loadPerson() {
    this.editMode = false;
    this.personService
      .getPerson(this.personId)
      .subscribe((responseCargo: any) => {
        this.updateResponseCargo(responseCargo);
      });
  }

  newPerson() {
    const responseCargo = new ResponseCargo(PersonEntity.newPerson());
    this.updateResponseCargo(responseCargo);
    this.resetPasswordRequired = true;
    this.editMode = true;
  }

  updateResponseCargo(responseCargo: ResponseCargo) {
    this.setPerson(responseCargo.model);
    this.responseMessages = responseCargo.responseMessages;
  }

  setPerson(person: PersonEntity) {
    this.person = person;
  }

  onEdit() {
    this.editMode = true;
  }

  onSave() {
    this.personService.savePerson(this.person).subscribe((responseCargo) => {
      this.updateResponseCargo(responseCargo);
      // TODO: need to allow for warnings, not just zero messages
      if (responseCargo.responseMessages.length === 0) {
        this.editMode = false;
        if (this.resetPasswordRequired) {
          this.onNewPassword();
        }
      }
    });
  }

  onCancel() {
    if (this.person.personId > 0) {
      this.loadPerson();
    } else {
      this.router.navigate(['/person-search']);
    }
  }

  onResetPassword() {
    this.resetPasswordComponent.startReset(this.person.userName);
  }

  onNewPassword() {
    this.resetPasswordComponent.startNewPassword(this.person.userName);
  }

  onResetPasswordEvent() {
    this.router.navigate(['/person-edit/' + this.person.personId]);
  }

  deleteEnabledEvent(event: any) {
    this.deleteEnabled = event;
  }

  onDelete() {
    this.personService.delete(this.personId).subscribe(
      (response) => {
        console.log('Delete successful');
        this.router.navigate(['/person-search']);
      },
      (error) => {
        console.log('Unable to delete person');
      }
    );
  }
}
