import { Injectable } from '@angular/core';

@Injectable()
export class ReportOptionsService {
  constructor() {}

  getFilterOptions() {
    return [
      {
        name: 'All Species',
        value: 'ALL',
      },
      {
        name: 'Included',
        value: 'INCLUDED',
      },
      {
        name: 'Excluded',
        value: 'EXCLUDED',
      },
      {
        name: 'Undefined',
        value: 'UNDEFINED',
      },
    ];
  }

  getRoleOptions() {
    return [
      {
        name: 'Administrator',
        value: 'ADMINISTRATOR',
      },
      {
        name: 'Panel Chair',
        value: 'CHAIR',
      },
      {
        name: 'Panel Member',
        value: 'MEMBER',
      },
    ];
  }
}
