import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NztcsTaxonEntityDTO } from '../service/nztcs-taxon-entity-dto';
import { TaxonNameMoveDialogComponent } from './taxon-name-move-dialog/taxon-name-move-dialog.component';
import { ChangeSpeciesTypeDialogComponent } from './change-species-type-dialog/change-species-type-dialog.component';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { User } from '../../authentication/service/user';
import { NztcsTaxonEntityService } from '../service/nztcs-taxon-entity.service';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-taxon-name-view',
  templateUrl: './taxon-name-view.component.html',
})
export class TaxonNameViewComponent implements OnInit {
  @Input()
  nztcsTaxonId: number;

  @ViewChild('taxonNameMoveDialogComponent', { static: true })
  taxonNameMoveDialogComponent: TaxonNameMoveDialogComponent;

  @ViewChild('changeSpeciesTypeDialogComponent', { static: true })
  changeSpeciesTypeDialogComponent: ChangeSpeciesTypeDialogComponent;

  dto: NztcsTaxonEntityDTO;

  faPencil = faPencilAlt;

  currentUser: User;
  nameEditor = false;

  constructor(
    private nztcsTaxonEntityService: NztcsTaxonEntityService,
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.nztcsTaxonId = +params['nztcsTaxonId'];
      this.loadNztcsTaxonEntityDTO();
    });

    this.changeSpeciesTypeDialogComponent.changeSpeciesTypeEvent.subscribe(
      () => {
        this.loadNztcsTaxonEntityDTO();
      }
    );

    this.taxonNameMoveDialogComponent.taxonMovedEvent.subscribe(() => {
      this.loadNztcsTaxonEntityDTO();
    });

    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.nameEditor = this.currentUser && this.currentUser.nameEditor;
    });
  }

  editAction() {
    this.router.navigate(['/nztcs-taxon-edit/' + this.nztcsTaxonId]);
  }

  moveTaxonAction() {
    this.taxonNameMoveDialogComponent.show(this.dto.nztcsTaxonEntity);
  }

  /*moveChildrenAction() {
    if (this.dto && this.dto.children) {
      const selectedChildren = [];
      this.dto.children.forEach((child) => {
        if (child.selected) {
          selectedChildren.push(child);
        }
      });

      this.taxonNameMoveDialogComponent.show(selectedChildren);
    }
  }*/

  deleteAction() {
    // TODO
  }

  /*selectAllAction() {
    this.selectAll = !this.selectAll;
    if (this.dto && this.dto.children) {
      this.dto.children.forEach((child) => {
        child.selected = this.selectAll;
      });
    }
  }*/

  changeSpeciesType() {
    this.changeSpeciesTypeDialogComponent.show(this.nztcsTaxonId);
  }

  private loadNztcsTaxonEntityDTO() {
    if (this.nztcsTaxonId) {
      this.nztcsTaxonEntityService
        .findNztcsTaxonEntityDTO(this.nztcsTaxonId)
        .subscribe((dto) => (this.dto = dto));
    }
  }
}
