import { Component, OnInit } from '@angular/core';
import { NameMatchSearchCtxService } from '../service/name-match-search-ctx.service';
import { NZORNameDetailsService } from '../service/nzorname-details.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzorNameCriteria } from '../service/nzor-name-criteria';
import { NZORNameService } from '../service/nzorname.service';
import { User } from '../../authentication/service/user';
import { Subject } from 'rxjs';
import { AuthenticationService } from '../../authentication/service/authentication.service';

@Component({
  selector: 'app-name-match-detail',
  templateUrl: './name-match-detail.component.html',
  styleUrls: ['./name-match-detail.component.css'],
})
export class NameMatchDetailComponent implements OnInit {
  currentUser: User;

  nzorNameCriteria = new NzorNameCriteria();

  details: any = {
    nzorNamesSearchResponse: {},
    speciesNZORName: {},
    species: {},
  };

  totalItems: any;
  selectedNzorName: any;
  selectedRowIdx = -1;

  speciesId: any;

  constructor(
    public searchCtx: NameMatchSearchCtxService,
    private nzorNameDetailsService: NZORNameDetailsService,
    private nzorNameService: NZORNameService,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );

    this.route.params.subscribe((params) => {
      this.speciesId = +params['speciesId'];

      this.nzorNameDetailsService
        .getNameDetails(this.speciesId)
        .subscribe((speciesNZORNameDetails: any) => {
          this.details = speciesNZORNameDetails;

          const nzorNames =
            speciesNZORNameDetails.nzorNamesSearchResponse.searchResults;
          if (nzorNames.length > 0) {
            this.selectedNzorName = nzorNames[0];
            this.totalItems = nzorNames.length;
          }
        });

      this.selectedRowIdx = -1;
      const searchResults =
        this.searchCtx.criteria.searchResponse.searchResults;
      if (searchResults.length > 0) {
        for (let i = 0; i < searchResults.length; i++) {
          const nextSpeciesMatch = searchResults[i];
          if (nextSpeciesMatch.speciesID === this.speciesId) {
            this.selectedRowIdx = i;
            break;
          }
        }
      }
    });
  }

  searchNZORNames(resetCurrentPage: boolean) {
    console.log('searchNZORNames() - ' + JSON.stringify(this.nzorNameCriteria));

    if (resetCurrentPage) {
      this.nzorNameCriteria.pageNumber = 1;
    }

    this.nzorNameService
      .findByCriteria(this.nzorNameCriteria)
      .subscribe((nzorNameSearchResponse: any) => {
        this.details.nzorNamesSearchResponse = nzorNameSearchResponse;
        this.totalItems = Number(nzorNameSearchResponse.total);
      });
  }

  navigatePrevAvailable(): boolean {
    return this.searchCtx.navigatePrevAvailable(this.speciesId);
  }

  navigateNextAvailable(): boolean {
    return this.searchCtx.navigateNextAvailable(this.speciesId);
  }

  navigateByRow(direction: number) {
    this.searchCtx.navigateByRow(direction, this.speciesId);
  }

  selectName(nzorName) {
    this.selectedNzorName = nzorName;
    console.log('selected name ' + this.selectedNzorName);
  }

  updateName(nzorRelationship) {
    const speciesId = this.details.species.speciesId;
    const nzorNameId = this.selectedNzorName.nameId;

    this.nzorNameDetailsService
      .updateSpeciesNZORName(speciesId, nzorNameId, nzorRelationship)
      .subscribe((speciesNZORName) => {
        this.details.speciesNZORName = speciesNZORName;
      });
  }

  onPageChanged(event: any) {
    this.nzorNameCriteria.pageNumber = event.page;
    this.searchNZORNames(false);
  }
}
