<div
  bsModal
  #nzorSpeciesSearchModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">NZOR Species Search</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!--<app-messages-view [messages]="responseMessages"></app-messages-view>-->

        <form class="form-inline">
          <div class="form-group w-50">
            <label class="mr-3" for="nameCriteriaTxtFld">Name</label>
            <input
              type="text"
              style="width: 200px !important"
              class="form-control"
              id="nameCriteriaTxtFld"
              name="nameCriteriaTxtFld"
              [(ngModel)]="criteria.nameCriteria"
            />
          </div>

          <button
            type="button"
            class="btn btn-primary mr-3"
            (click)="searchAction()"
          >
            Search
          </button>
          <button
            type="button"
            class="btn btn-light mr-3"
            (click)="clearAction()"
          >
            Clear
          </button>
        </form>

        <br />

        <div class="row" *ngIf="pagedSearchResponse">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered table-sm small">
                <thead>
                  <tr>
                    <th>Species Name</th>
                  </tr>
                </thead>
                <tbody
                  *ngIf="
                    pagedSearchResponse && pagedSearchResponse.searchResults
                  "
                >
                  <tr
                    *ngFor="let species of pagedSearchResponse.searchResults"
                    (click)="selectAction(species.nameId)"
                  >
                    <td [innerHTML]="species.formattedPartialName"></td>
                  </tr>
                </tbody>

                <tbody
                  *ngIf="
                    pagedSearchResponse && !pagedSearchResponse.searchResults
                  "
                >
                  <td colspan="100">No matching search results found.</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="pagedSearchResponse">
          <div class="col-md-6">
            <pagination
              [maxSize]="5"
              [itemsPerPage]="criteria.pageSize"
              [(ngModel)]="criteria.pageNumber"
              [totalItems]="pagedSearchResponse.total"
              (pageChanged)="onPageChanged($event)"
            >
            </pagination>
          </div>

          <div class="col-md-6 text-right" *ngIf="pagedSearchResponse">
            Total Results: {{ pagedSearchResponse.total }}, Page:
            {{ pagedSearchResponse.page }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
