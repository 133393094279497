<!-- Yes, this looks like it's not mapped correctly, See NZTCS-237 -->
<label class="control-label" for="categorySelect">Conservation Status</label>

<select
  class="form-control"
  id="categorySelect"
  name="categorySelect"
  (change)="onChange($event.target.value, $event.target)"
>
  <option [ngValue]="null"></option>
  <option *ngFor="let category of categoryList" [ngValue]="category">
    {{ category.title }}
  </option>
</select>

<span *ngIf="searchCtx.criteria">
  <span
    *ngFor="let code of searchCtx.criteria.categoryCode"
    class="badge badge-secondary mr-1"
    (mouseenter)="setCategoryTagActiveMapValue(code, true)"
    (mouseleave)="setCategoryTagActiveMapValue(code, false)"
  >
    {{ getCategoryName(code) }}
    <a
      href="#"
      class="bg-secondary text-light"
      (click)="removeCategoryFromSearch(code)"
      *ngIf="isCategoryTagActive(code)"
    >
      <fa-icon [icon]="removeIcon"></fa-icon>
    </a>
  </span>
</span>
