import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AssessmentSearchCriteria } from '../../../assessment/service/assessment-search-criteria';
import { AssessmentService } from '../../../assessment/service/assessment.service';
import { SpeciesSelectorComponent } from '../species-selector/species-selector.component';
import {
  ActionEvent,
  ActionState,
  MessageDialogComponent,
} from '../../../common/message-dialog/message-dialog.component';
import { ReportService } from '../../service/report.service';

@Component({
  selector: 'app-assessment-selector',
  templateUrl: './assessment-selector.component.html',
})
export class AssessmentSelectorComponent implements OnInit {
  @ViewChild('speciesSelectorComponent', { static: true })
  speciesSelectorComponent: SpeciesSelectorComponent;

  @ViewChild('messageDialog', { static: true })
  messageDialog: MessageDialogComponent;

  assessmentSearchCriteria = new AssessmentSearchCriteria();
  assessmentSearchResponse: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  private _reportName;
  private _reportId;
  private _modifable;

  constructor(
    private assessmentService: AssessmentService,
    private reportService: ReportService
  ) {}

  get modifable() {
    return this._modifable;
  }

  @Input()
  set modifable(value) {
    this._modifable = value;
  }

  @Input()
  public set reportName(reportName: string) {
    this._reportName = reportName;
  }

  @Input()
  public set reportId(reportId: string) {
    this._reportId = reportId;
  }

  ngOnInit() {
    this.loadAssessments();
  }

  /**
   * Must be called directly from loadReport() in the parent ReportDetailComponent, otherwise assessments don't refresh
   * properly after updateNames() has been called.
   */
  loadAssessments() {
    this.assessmentSearchCriteria.reportId = this._reportId;
    // this.assessmentSearchCriteria.reportName = this._reportName;
    this.assessmentSearchCriteria.showArchived = true; // always show archived assessments on the report page (so duplicates can be managed)
    this.assessmentService
      .findByCriteria(this.assessmentSearchCriteria)
      .subscribe((response) => {
        this.assessmentSearchResponse = response;
      });
  }

  onAddSpecies() {
    this.speciesSelectorComponent.start(this._reportId);
  }

  getSelectedAssessmentsList(): number[] {
    const selectedAssessmentsList: number[] = [];
    for (const nextAssessment of this.assessmentSearchResponse.searchResults) {
      if (nextAssessment.selected) {
        selectedAssessmentsList.push(nextAssessment.assessmentId);
      }
    }

    return selectedAssessmentsList;
  }

  onConfirmRemoveAssessments() {
    const selectedAssessmentsList = this.getSelectedAssessmentsList();
    const messageText =
      'You are about to remove ' +
      selectedAssessmentsList.length +
      ' assessment records. Are you sure you want to delete these Assessment (not Species) records. This action cannot be undone.';
    this.messageDialog.start(messageText, selectedAssessmentsList);
  }

  onDeleteAssessmentsActionEvent(actionEvent: ActionEvent) {
    if (actionEvent.state === ActionState.CONFIRMED) {
      this.reportService
        .removeAssessments(this._reportId, this.getSelectedAssessmentsList())
        .subscribe(() => {
          this.loadAssessments();
        });
    }
  }

  onSpeciesNameChanged() {
    this.loadAssessments();
  }

  onReset() {
    this.loadAssessments();
  }

  onPageChanged(event: any) {
    this.assessmentSearchCriteria.pageNumber = event.page;
    this.loadAssessments();
  }

  onSpeciesSelectorEvent($event: any) {
    if ($event === 'Closed') {
      this.loadAssessments();
    }
  }
}
