<div
  bsModal
  #changeSpeciesTypeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Change Species Type</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!--<app-messages-view [messages]="responseMessages"></app-messages-view>-->
        <p>
          Change the species type of this taxon and all child taxa. Also changes
          the Species type of all dependent Species.
        </p>

        <div class="row" *ngIf="taxonName">
          <div class="col-md-10">
            <form (ngSubmit)="changeAction()" #editForm="ngForm" novalidate>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="speciesType">Species Type</label>
                  <select
                    class="form-control"
                    id="speciesType"
                    name="speciesType"
                    [(ngModel)]="taxonName.speciesType"
                  >
                    <option
                      *ngFor="let speciesType of speciesTypeList"
                      [ngValue]="speciesType"
                    >
                      {{ speciesType }}
                    </option>
                  </select>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered table-sm small">
                <thead>
                  <tr>
                    <th>Species Type</th>
                    <th>Taxa</th>
                    <th>Species</th>
                  </tr>
                </thead>
                <tbody *ngIf="speciesTypeMap && !searchInProgress">
                  <tr *ngFor="let speciesType of getSpeciesTypeKeys()">
                    <td>{{ speciesType }}</td>
                    <td>
                      <span
                        *ngFor="
                          let taxon of speciesTypeMap.taxonMap[speciesType];
                          last as last
                        "
                        >{{ taxon.taxonName
                        }}<span *ngIf="!last">, </span></span
                      >
                      <br /><b
                        >Total =
                        <span *ngIf="speciesTypeMap.taxonMap[speciesType]">{{
                          speciesTypeMap.taxonMap[speciesType].length
                        }}</span></b
                      >
                    </td>
                    <td>
                      <span
                        *ngFor="
                          let species of speciesTypeMap.speciesMap[speciesType];
                          last as last
                        "
                        >{{ species.speciesEpithet
                        }}<span *ngIf="!last">, </span></span
                      >
                      <br /><b
                        >Total =
                        <span *ngIf="speciesTypeMap.speciesMap[speciesType]">{{
                          speciesTypeMap.speciesMap[speciesType].length
                        }}</span></b
                      >
                    </td>
                  </tr>
                </tbody>

                <tbody *ngIf="this.searchInProgress">
                  <app-wait-spinner
                    [showSpinner]="this.searchInProgress"
                  ></app-wait-spinner>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <hr />

        <div class="row">
          <div class="col-md-12">
            <app-wait-spinner
              [showSpinner]="changeInProgress"
            ></app-wait-spinner>
            <button
              id="saveBtn"
              type="button"
              (click)="changeAction()"
              class="btn btn-primary mr-2"
            >
              Change
            </button>
            <button
              id="cancelBtn"
              type="button"
              (click)="cancelAction()"
              class="btn btn-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
