import { Injectable } from '@angular/core';
import { NzorNameCriteria } from './nzor-name-criteria';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NZORNameService {
  constructor(private http: HttpClient) {}

  findById(nzorNameId: string) {
    return this.http.get('/rest/nzorname/' + nzorNameId);
  }

  findByIdExtended(nzorNameId: string) {
    return this.http.get('/rest/nzorname/extended/' + nzorNameId);
  }

  findCommonNames(nzorNameId: string) {
    return this.http.get('/rest/nzorname/commonNames/' + nzorNameId);
  }

  findByCriteria(nzorNameCriteria: NzorNameCriteria) {
    return this.http.post('/rest/nzorname/criteria', nzorNameCriteria);
  }

  getCommonNames(nzorNameId: string) {
    return this.http.get('/rest/nzorname/commonNames/' + nzorNameId);
  }
}
