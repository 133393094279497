export class TinymceUtils {
  static editorSettings: any = {
    branding: false,
    height: 300,
    link_assume_external_targets: false,
    relative_urls: false,
    remove_script_host: true,
    tinymceScriptURL: '/assets/tinymce/tinymce.min.js',
    theme_url: '/assets/tinymce/themes/modern/theme.min.js',
    skin_url: '/assets/tinymce/skins/lightgray',
    plugins: 'contextmenu textcolor colorpicker table link image',
    menubar: 'file edit insert view format',
    toolbar:
      'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image | forecolor backcolor',
  };

  static nameEditorSettings: any = {
    branding: false,
    height: 100,
    forced_root_block: '',
    formats: {
      italic: { inline: 'i' }
    },
    link_assume_external_targets: false,
    relative_urls: false,
    remove_script_host: true,
    extended_valid_elements: 'i/em',
    tinymceScriptURL: '/assets/tinymce/tinymce.min.js',
    theme_url: '/assets/tinymce/themes/modern/theme.min.js',
    skin_url: '/assets/tinymce/skins/lightgray',
    plugins: '',
    menubar: '',
    toolbar:
      'undo redo | bold italic',
  };
}
