import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportSearchCtxService } from '../service/report-search-ctx.service';
import { ReportService } from '../service/report.service';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { Subject } from 'rxjs';
import { User } from '../../authentication/service/user';
import { Router } from '@angular/router';

@Component({
  selector: 'app-report-search',
  templateUrl: './report-search.component.html',
  styleUrls: ['./report-search.component.scss'],
})
export class ReportSearchComponent implements OnInit {
  reportNames: string[];

  currentUser: User;

  constructor(
    public searchCtx: ReportSearchCtxService,
    private reportService: ReportService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
    this.refreshReportNames(this.searchCtx.criteria.showArchived, false);
    this.searchAction();
  }

  refreshReportNames(showArchived: boolean, performSearch: boolean) {
    this.reportService.getReportNames(showArchived).subscribe((reportNames) => {
      this.reportNames = reportNames;
      if (performSearch) {
        this.searchAction();
      }
    });
  }

  /**
   * Still need to figure out how to receive model change events properly. When someone changes
   * the showArchived flag get the right list of report names for the typeahead criteria. At the
   * moment the change hasn't been applied when the (click) event is fired so the value we want
   * is the exact opposite of what is currently is. See also same method in assessment search.
   */
  onShowArchivedChanged() {
    console.log('showArchived = ' + this.searchCtx.criteria.showArchived);
    this.refreshReportNames(!this.searchCtx.criteria.showArchived, true);
  }

  hasSearchResults(): boolean {
    return (
      this.searchCtx.response &&
      this.searchCtx.response.searchResults.length > 0
    );
  }

  onPageChanged(event: any) {
    this.searchCtx.criteria.pageNumber = event.page;
    this.searchAction();
  }

  searchAction() {
    this.searchCtx.search();
  }

  clearAction() {
    this.searchCtx.clear();
    this.searchAction();
  }

  newReportAction() {
    this.router.navigate(['/reports/-1']);
  }
}
