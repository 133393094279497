export class Content {
  contentId: number;
  linkLabel: string;
  visibility: string;
  name: string;
  html: string;
  lastUpdated: any;

  constructor() {}
}
