<div class="card">
  <div class="card-header">
    <h4>Comments</h4>
  </div>
  <div class="card-body">
    <div *ngFor="let comment of commentList">
      <div class="row">
        <div class="col-md-6">
          <fa-icon [icon]="personIcon"></fa-icon> {{ comment.personName }}
        </div>
        <div class="col-md-6 text-right">
          <small
            ><em>{{ comment.editDate | date: 'd-MMM-yyyy h:mm a' }}</em></small
          >
          <div class="btn-group ml-2" *ngIf="!editMode">
            <button
              class="btn btn-success btn-sm"
              (click)="editCommentAction(comment)"
            >
              <fa-icon [icon]="editIcon"></fa-icon>
            </button>
            <button
              class="btn btn-danger btn-sm"
              (click)="removeCommentAction(comment)"
            >
              <fa-icon [icon]="deleteIcon"></fa-icon>
            </button>
          </div>
        </div>
        <br />
      </div>

      <div class="row">
        <div class="col-md-12">
          <div [innerHTML]="comment.commentText"></div>
        </div>
      </div>

      <hr />
    </div>

    <div *ngIf="editMode">
      <div class="row">
        <div class="col-md-6 mb-3">
          <fa-icon [icon]="personIcon"></fa-icon> {{ editedComment.personName }}
        </div>
        <div class="col-md-6 text-right">
          <small
            ><em>{{
              editedComment.editDate | date: 'd-MMM-yyyy h:mm a'
            }}</em></small
          >
        </div>
        <br />
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-text-editor
            #textEditorComponent
            [context]="'comment:' + editedComment.commentId"
            [text]="editedComment.commentText"
            [attachmentsEnabled]="editedComment.commentId"
          ></app-text-editor>

          <div class="row">
            <div class="col-md-6 mt-2">
              <button
                type="button"
                class="btn btn-primary"
                (click)="saveCommentAction()"
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-light"
                (click)="cancelSaveCommentAction()"
              >
                Cancel
              </button>
            </div>
          </div>
          <hr />
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-success"
      (click)="addNewCommentAction()"
      *ngIf="addCommentAvailable && !editMode"
    >
      Add Comment
    </button>
  </div>
</div>
