<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Species Details</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1" *ngIf="nameEditor">
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="editAction()"
        >
          Edit
        </button>
        <button
          type="button"
          class="btn btn-danger btn-sm"
          (click)="deleteAction()"
          [disabled]="
            relatedAssessments === null || relatedAssessments.length > 0
          "
        >
          Delete
        </button>
      </div>
    </div>
  </div>

  <hr />

  <div class="row" *ngIf="selectedSpecies">
    <div class="col-md-5">
      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Taxonomy</h5>
              <div class="a-container">
                <div class="a-row">
                  <div class="a-cell-label">Hierarchy</div>
                  <div class="a-cell-value">
                    <app-taxon-name-list
                      [taxonName]="selectedSpecies.taxonName"
                    ></app-taxon-name-list>
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Species Type</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.speciesType }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Formal Name</h5>
              <div class="a-container">
                <div class="a-row">
                  <div class="a-cell-label">Name and Authority</div>
                  <div
                    class="a-cell-value"
                    [innerHTML]="selectedSpecies.nameAndAuthority"
                  ></div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Scientific Name</div>
                  <div
                    class="a-cell-value"
                    [innerHTML]="selectedSpecies.scientificName"
                  ></div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Genus</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.taxonName.taxonName }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Species Epithet</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.speciesEpithet }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Trinomial Type</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.trinomialType }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Trinomial Epithet</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.trinomialEpithet }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Naming Authority</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.namingAuthority }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Taxonomic Status</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.taxonomicStatus }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Other Names</h5>
              <div class="a-container">
                <div class="a-row">
                  <div class="a-cell-label">Common Name</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.commonName }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Māori Name</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.maoriName }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Alternative Names</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.alternativeNames }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Attributes</h5>

              <div class="a-container">
                <div class="a-row">
                  <div class="a-cell-label">Bio Status</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.biostatusCategory }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Environment</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.environmentCategory }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">Generation Time</div>
                  <div class="a-cell-value">
                    <span
                      *ngIf="
                        selectedSpecies && selectedSpecies.generationTimeInYears
                      "
                    >
                      {{ selectedSpecies.generationTimeInYears }} (years)
                    </span>
                  </div>
                </div>

                <div
                  class="a-row"
                  *ngIf="selectedSpecies.speciesType === 'Fungus'"
                >
                  <div class="a-cell-label">Life Mode</div>
                  <div class="a-cell-value">{{ selectedSpecies.lifeMode }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Notes</h5>
              <div [innerHTML]="selectedSpecies.notes"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-7">
      <div class="card mb-3">
        <div class="card-body">
          <h5 class="card-title">Related Assessments</h5>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table
                  class="table table-striped table-bordered table-sm small"
                >
                  <thead>
                    <tr>
                      <th>Report Name</th>
                      <th>Conservation Status</th>
                      <th>Year Published</th>
                      <th>Report Status</th>
                    </tr>
                  </thead>
                  <tbody *ngIf="relatedAssessments">
                    <tr *ngFor="let speciesUsage of relatedAssessments">
                      <td>
                        <a
                          routerLink="/assessments/{{
                            speciesUsage.assessmentId
                          }}"
                          >{{ speciesUsage.reportName }}</a
                        >
                        <br />
                        <span [innerHTML]="speciesUsage.assessmentName"></span>
                      </td>
                      <td>{{ speciesUsage.conservationStatus }}</td>
                      <td>{{ speciesUsage.yearPublished }}</td>
                      <td>
                        <app-status-badge
                          [statusValue]="speciesUsage.editStatus"
                        ></app-status-badge
                        >&nbsp;<app-status-badge
                          [statusValue]="speciesUsage.publishedStatus"
                        ></app-status-badge>
                      </td>
                    </tr>
                  </tbody>

                  <tbody *ngIf="!relatedAssessments">
                    <td colspan="100">No matching search results found.</td>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="card mb-3">
            <div class="card-body">
              <h5 class="card-title">Source Data</h5>

              <div class="a-container">
                <div class="a-row">
                  <div class="a-cell-label">Bioweb</div>
                  <div class="a-cell-value">
                    {{ selectedSpecies.biowebSpeciesId }}
                  </div>
                </div>

                <div class="a-row">
                  <div class="a-cell-label">NZOR</div>
                  <div class="a-cell-value" *ngIf="selectedSpecies.nzorId">
                    <a
                      target="_blank"
                      [attr.href]="
                        'http://nzor.org.nz/names/' + selectedSpecies.nzorId
                      "
                      >{{ selectedSpecies.nzorId }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-message-dialog
  #messageDialog
  [header]="'Confirm Delete'"
  [messageText]="
    'Are you sure you want to delete this Species record. This action cannot be undone.'
  "
  [actionLabel]="'Delete'"
  [actionClass]="'btn btn-danger'"
  (actionEvent)="onDeleteSpeciesConfirmed($event)"
>
</app-message-dialog>
