import { Component, Input, OnInit } from '@angular/core';
import { ChartConfiguration, ChartData, TooltipItem } from 'chart.js';
import { StatisticsService } from '../service/statistics.service';
import { UmbrellaCategoryStatistic } from '../service/umbrella-category-statistic';

@Component({
  selector: 'app-umbrella-category-view',
  templateUrl: './umbrella-category-view.component.html',
  styleUrls: ['./umbrella-category-view.component.scss'],
})
export class UmbrellaCategoryViewComponent implements OnInit {
  @Input()
  reportId!: number;

  totalCount!: number;

  public barChartOptions: ChartConfiguration<'bar'>['options'] = {
    indexAxis: 'y',
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'Number of species by Umbrella Category',
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => this.labelCallback(tooltipItem),
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          callback: (tickValue: string | number) => {
            if (typeof tickValue === 'number' && tickValue % 1 === 0) {
              return tickValue;
            }
          },
        },
      },
    },
  };

  public barChartData!: ChartData<'bar'>;

  statisticMap = new Map<string, UmbrellaCategoryStatistic>();

  constructor(private statisticsService: StatisticsService) {}

  ngOnInit() {
    this.statisticsService
      .getUmbrellaCategory(this.reportId)
      .subscribe((response) => {
        this.createStatisticMap(response);
        this.updateChart(response);
      });
  }

  onExport() {
    let exportUrl = '/rest/statistics/umbrellaCategory/export';
    if (this.reportId) {
      exportUrl += '?reportId=' + this.reportId;
    }
    window.location.href = exportUrl;
  }

  private createStatisticMap(statisticList: UmbrellaCategoryStatistic[]) {
    if (statisticList) {
      this.statisticMap = new Map<string, UmbrellaCategoryStatistic>();
      statisticList.forEach((statistic) => {
        this.statisticMap.set(statistic.umbrellaCategory, statistic);
      });
    }
  }

  private labelCallback(tooltipItem: TooltipItem<'bar'>): string | string[] {
    const umbrellaCategoryLabel = tooltipItem.label;
    const umbrellaCategory = this.statisticMap.get(umbrellaCategoryLabel);
    const umbrellaCategoryStatsList = umbrellaCategory?.categoryStatisticList;
    const label: string[] = [];
    umbrellaCategoryStatsList?.forEach((category) => {
      label.push(category.category + ': ' + category.count);
    });

    if (
      umbrellaCategoryStatsList !== undefined &&
      umbrellaCategoryStatsList.length > 1
    ) {
      label.push('---------');
      label.push(
        umbrellaCategory?.umbrellaCategory + ': ' + umbrellaCategory?.count
      );
    }

    return label;
  }

  private updateChart(response: UmbrellaCategoryStatistic[]) {
    this.barChartData = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [
            'rgba(255, 99, 132, 0.5)',
            'rgba(255, 159, 64, 0.5)',
            'rgba(255, 205, 86, 0.5)',
            'rgba(75, 192, 192, 0.5)',
            'rgba(54, 162, 235, 0.5)',
            'rgba(153, 102, 255, 0.5)',
            'rgba(201, 203, 207, 0.5)',
          ],
          hoverBackgroundColor: [
            'rgba(255, 99, 132, 0.7)',
            'rgba(255, 159, 64, 0.7)',
            'rgba(255, 205, 86, 0.7)',
            'rgba(75, 192, 192, 0.7)',
            'rgba(54, 162, 235, 0.7)',
            'rgba(153, 102, 255, 0.7)',
            'rgba(201, 203, 207, 0.7)',
          ],
        },
      ],
    };

    this.totalCount = 0;
    for (const nextStat of response) {
      this.barChartData.datasets[0].data.push(nextStat.count);

      // the api doesn't provide this total but we can calculate it here and now.
      this.totalCount += nextStat.count;

      // note we actually add the labels to a different variable
      this.barChartData?.labels?.push(nextStat.umbrellaCategory);
    }
  }
}
