import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CodeValueService } from '../service/code-value.service';

@Component({
  selector: 'app-code-value-edit',
  templateUrl: './code-value-edit.component.html',
  styleUrls: ['./code-value-edit.component.scss'],
})
export class CodeValueEditComponent implements OnInit {
  @ViewChild('codeValueEditModal', { static: true })
  codeValueEditModal: ModalDirective;

  @Output()
  codeValueUpdated = new EventEmitter();

  codeValue: any;

  codeEditable = false;

  responseMessages;

  constructor(private codeValueService: CodeValueService) {}

  ngOnInit() {}

  startEdit(codeValue: any) {
    this.codeValue = codeValue;
    this.codeEditable = codeValue.objectId == null;
    this.responseMessages = null;
    this.codeValueEditModal.show();
  }

  confirmAction() {
    this.codeValueService
      .saveCodeValue(this.codeValue)
      .subscribe((response: any) => {
        this.responseMessages = response.responseMessages;
        if (this.responseMessages.length === 0) {
          this.cancelAction();
        }
      });
  }

  cancelAction() {
    this.codeValueEditModal.hide();
  }

  onHide() {
    this.codeValueUpdated.next('CodeValuesChanged');
  }
}
