import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TinymceUtils } from '../../../tinymce/tinymce-utils';
import { AssessmentService } from '../../service/assessment.service';

@Component({
  selector: 'app-edit-name',
  templateUrl: './edit-name.component.html',
})
export class EditNameComponent implements OnInit {
  @Input()
  assessmentId: number;

  @Input()
  assessmentName = '';

  @Output()
  editNameEvent = new EventEmitter();

  @ViewChild('editNameModal', { static: true })
  public editNameModal: ModalDirective;

  editorSettings = TinymceUtils.editorSettings;

  constructor(private assessmentService: AssessmentService) {}

  ngOnInit() {
    this.editorSettings.height = null;
  }

  start() {
    this.editNameModal.show();
  }

  onOkay() {
    this.assessmentService
      .changeAssessmentName(this.assessmentId, this.assessmentName)
      .subscribe(() => {
        this.editNameEvent.next(this.assessmentName);
        this.onCancel();
      });
  }

  onCancel() {
    this.editNameModal.hide();
  }
}
