/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';

interface Action {
  handler: () => void;
  label: string;
  classes?: string;
}

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})
export class MessageDialogComponent implements OnInit {
  @Input()
  header: string;

  @Input()
  messageText: string;

  @Input()
  actionLabel: string;

  @Input()
  actionClass: string;

  @Input()
  actionPayload: any;

  @Input()
  actions: Action[];

  @Output()
  actionEvent = new EventEmitter<ActionEvent>();

  @ViewChild('messageDialogModal', { static: true })
  public messageDialogModal: ModalDirective;

  constructor() {}

  ngOnInit() {}

  /**
   * messageText can be supplied statically using the @Input variable, or dynamically through the start() method.
   */
  start(messageText?: string, actionPayload?: any) {
    this.messageText = messageText;
    this.actionPayload = actionPayload;
    this.messageDialogModal.show();
  }

  show() {
    this.messageDialogModal.show();
  }

  hide() {
    this.messageDialogModal.hide();
  }

  onCancel() {
    this.actionEvent.emit(
      new ActionEvent(ActionState.CANCELLED, this.actionPayload)
    );
    this.messageDialogModal.hide();
  }

  onAction() {
    // send event...
    this.actionEvent.emit(
      new ActionEvent(ActionState.CONFIRMED, this.actionPayload)
    );
    this.messageDialogModal.hide();
  }
}

export class ActionEvent {
  constructor(public state: ActionState, public payload: any) {}
}

export enum ActionState {
  CANCELLED,
  CONFIRMED,
}
