/* eslint-disable @typescript-eslint/naming-convention */
import { Subscription, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BatchControlService } from '../service/batch-control.service';

@Component({
  selector: 'app-batch-control',
  templateUrl: './batch-control.component.html',
  styleUrls: ['./batch-control.component.css'],
})
export class BatchControlComponent implements OnInit, OnDestroy {
  NZORDataCacheJobReport: any = {};
  NameMatchingJobReport: any = {};
  NZTCSSpeciesMigrationJobReport: any = {};

  pollingSub: Subscription = new Subscription();

  constructor(private batchControlService: BatchControlService) {}

  ngOnInit() {
    this.startPolling();
  }

  updateView(response: any) {
    this.NZORDataCacheJobReport = response['NZORDataCacheBatchJob'];
    this.NameMatchingJobReport = response['NameMatchingBatchJob'];
    this.NZTCSSpeciesMigrationJobReport =
      response['NZTCSSpeciesMigrationBatchJob'];

    if (
      this.NZORDataCacheJobReport.batchJobStatus === 'IDLE' &&
      this.NameMatchingJobReport.batchJobStatus === 'IDLE' &&
      this.NZTCSSpeciesMigrationJobReport.batchJobStatus === 'IDLE'
    ) {
      this.stopPolling();
    }
  }

  executeJob(jobName: string) {
    this.batchControlService.executeJob(jobName).subscribe(() => {
      console.log('Execute ' + jobName);
      this.startPolling();
    });
  }

  deleteData(jobName: string) {
    this.batchControlService.deleteData(jobName).subscribe(() => {
      console.log('DeleteData ' + jobName);
    });
  }

  startPolling() {
    this.stopPolling();

    console.log('Start polling');
    this.pollingSub = new Subscription();
    this.pollingSub.add(
      interval(5000)
        .pipe(
          startWith(5000),
          switchMap(() => this.batchControlService.refresh())
        )
        .subscribe((nextResponse) => {
          console.log('Poll: ' + new Date());
          this.updateView(nextResponse);
        })
    );
  }

  stopPolling() {
    console.log('Stop polling');
    if (this.pollingSub) {
      this.pollingSub.unsubscribe();
      this.pollingSub = null;
    }
  }

  ngOnDestroy(): void {
    this.stopPolling();
  }
}
