import { Component, OnInit } from '@angular/core';
import { User } from '../../authentication/service/user';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { ContentSearchCriteria } from './content-search-criteria';
import { ContentService } from '../service/content.service';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-content-search',
  templateUrl: './content-search.component.html',
})
export class ContentSearchComponent implements OnInit {
  currentUser: User;

  criteria = new ContentSearchCriteria();
  response: any;

  editIcon = faPencilAlt;
  deleteIcon = faTrashAlt;

  constructor(
    private router: Router,
    private contentService: ContentService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
    });
    this.searchAction();
  }

  onPageChanged(event: any) {
    this.criteria.pageNumber = event.page;
    this.searchAction();
  }

  clearAction() {
    this.criteria = new ContentSearchCriteria();
    this.searchAction();
  }

  searchAction() {
    this.contentService
      .findByCriteria(this.criteria)
      .subscribe((response) => (this.response = response));
  }

  newAction() {
    this.router.navigate(['content-edit/__new__']);
  }

  editAction(content: any) {
    this.router.navigate(['content-edit/' + content.name]);
  }

  deleteAction(content: any) {}
}
