import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NZORNameDetailsService {
  constructor(private http: HttpClient) {}

  getNameDetails(speciesId: number) {
    return this.http.get('/rest/species/nzor/details/' + speciesId);
  }

  updateSpeciesNZORName(
    speciesId: number,
    nzorNameId: string,
    nzorRelationshipStr: string
  ) {
    const updateRequest = {
      speciesId,
      nzorNameId,
      nzorRelationshipStr,
    };
    return this.http.post('/rest/species/nzor/updateName', updateRequest);
  }
}
