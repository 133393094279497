import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class NztcsSpeciesNameComparisonService {
  constructor() {}

  areTheSame(speciesName1: string, speciesName2: string): boolean {
    const s1 = this.stripIrrelevantTokens(speciesName1);
    const s2 = this.stripIrrelevantTokens(speciesName2);
    const result = s1 === s2;
    if (!result) {
      // console.log('s1 - [' + s1 + '] ');
      // console.log('s2 - [' + s2 + '] ');
    }
    return result;
  }

  stripIrrelevantTokens(name: string): string {
    if (name) {
      const tokens = ['(', ')', ','];
      for (const nextToken of tokens) {
        name = name.replace(nextToken, '');
      }
      name = name.replace(/\s/g, '');
      name = this.stripHtml(name);
    }
    return name;
  }

  private stripHtml(nameHtml: string): string {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = nameHtml;
    return tmp.textContent || tmp.innerText || '';
  }
}
