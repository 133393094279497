import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { SpeciesNameService } from './species-name.service';
import { SpeciesNameSearchCriteria } from './species-name-search-criteria';
import { ReportService } from '../../service/report.service';

@Component({
  selector: 'app-species-selector',
  templateUrl: './species-selector.component.html',
})
export class SpeciesSelectorComponent implements OnInit {
  @ViewChild('speciesSelectorModal', { static: true })
  public speciesSelectorModal: ModalDirective;

  @Output()
  speciesSelectorEvent = new EventEmitter();

  searchResponse: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  criteria = new SpeciesNameSearchCriteria();

  reportId: number;

  selectAll = false;
  addRequestInProgress = false;

  constructor(
    private speciesNameService: SpeciesNameService,
    private reportService: ReportService
  ) {}

  ngOnInit() {}

  start(reportId: number) {
    this.reportId = reportId;
    this.speciesSelectorModal.show();
  }

  onSearch() {
    this.criteria.reportId = this.reportId;
    this.speciesNameService
      .findSpeciesNames(this.criteria)
      .subscribe((response) => {
        this.searchResponse = response;

        // after updating the searchResults, toggle the selected flag based on the "Select All" current value
        this.onSelectAll();
      });
  }

  onMatchAgainstReportChange() {
    this.onSearch();
  }

  onClear() {
    this.criteria = new SpeciesNameSearchCriteria();
    this.onSearch();
  }

  onAdd() {
    this.addRequestInProgress = true;

    if (this.selectAll) {
      const fullCriteria = Object.assign({}, this.criteria);
      fullCriteria.pageNumber = 1;
      fullCriteria.pageSize = 99999;
      this.speciesNameService
        .findSpeciesNames(fullCriteria)
        .subscribe((response) => {
          this.addAssessments(true, response);
        });
    } else {
      this.addAssessments(false, this.searchResponse);
    }
  }

  onClose() {
    this.speciesSelectorModal.hide();
    this.speciesSelectorEvent.emit('Closed');
  }

  onPageChanged(event: any): void {
    // must update pageNumber from event before search
    this.criteria.pageNumber = event.page;
    this.onSearch();
  }

  onSelectAll() {
    for (const nextSearchResult of this.searchResponse.searchResults) {
      if (!nextSearchResult.includedInReport) {
        nextSearchResult.selected = this.selectAll;
      }
    }
  }

  private addAssessments(selectAll: boolean, searchResponse: any) {
    const speciesList: number[] = [];
    for (const nextSearchResult of searchResponse.searchResults) {
      if (
        (selectAll && !nextSearchResult.includedInReport) ||
        nextSearchResult.selected
      ) {
        speciesList.push(nextSearchResult.speciesId);
      }
    }

    this.reportService
      .addAssessments(this.reportId, speciesList)
      .subscribe(() => {
        console.log('addAssessments completed');
        this.onSearch();
        this.addRequestInProgress = false;
      });
  }
}
