import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PersonService } from './person/service/person.service';
import { AuthenticationService } from './authentication/service/authentication.service';
import { NameMatchSearchCtxService } from './nzor/service/name-match-search-ctx.service';
import { BatchControlService } from './batch/service/batch-control.service';
import { NZORNameDetailsService } from './nzor/service/nzorname-details.service';
import { NZORNameService } from './nzor/service/nzorname.service';
import { ReportService } from './report/service/report.service';
import { AssessmentService } from './assessment/service/assessment.service';
import { ReportSearchCtxService } from './report/service/report-search-ctx.service';
import { AssessmentSearchCtxService } from './assessment/service/assessment-search-ctx.service';
import { TagService } from './tag/service/tag.service';
import { User } from './authentication/service/user';
import { ReportOptionsService } from './report/service/report-options.service';
import { SpeciesService } from './species/service/species.service';
import { SpeciesNzorNameService } from './species/service/species-nzor-name.service';
import { CommentService } from './comments/service/comment.service';
import { AssessmentExportService } from './assessment/service/assessment-export.service';
import { StatisticsService } from './statistics/service/statistics.service';
import { CodeValueService } from './code/service/code-value.service';
import { HomeService } from './home/service/home.service';
import { SpeciesNameService } from './report/report-detail/species-selector/species-name.service';
import { LoginComponent } from './authentication/login/login.component';
import { JwtHelperService } from '@auth0/angular-jwt';
import { VersionService } from './version/version.service';
import { VersionData } from './domain/nztcs/version-data';
import { NztcsSpeciesSearchCtxService } from './nztcs-species/nztcs-species-search/nztcs-species-search-ctx.service';
import { faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { NztcsSpeciesService } from './nztcs-species/service/nztcs-species.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [
    PersonService,
    NameMatchSearchCtxService,
    BatchControlService,
    CodeValueService,
    CommentService,
    NZORNameDetailsService,
    NZORNameService,
    ReportService,
    ReportSearchCtxService,
    ReportOptionsService,
    AssessmentService,
    AssessmentSearchCtxService,
    AssessmentExportService,
    TagService,
    SpeciesNameService,
    SpeciesNzorNameService,
    SpeciesService,
    NztcsSpeciesService,
    NztcsSpeciesSearchCtxService,
    StatisticsService,
    HomeService,
  ],
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('loginComponent', { static: true })
  loginComponent: LoginComponent;

  currentUser: User;
  tokenWatcherInterval: any;

  versionData: VersionData;

  loginIcon = faSignInAlt;
  logoutIcon = faSignOutAlt;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private jwtHelper: JwtHelperService,
    private versionService: VersionService
  ) {}

  ngOnInit(): void {
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
    this.startTokenWatcher();
    this.checkForExpiredToken();

    this.versionService
      .getVersion()
      .subscribe((versionData) => (this.versionData = versionData));
  }

  ngAfterViewInit(): void {
    this.authenticationService.loginRequest$.subscribe((loginRequestEvent) =>
      this.loginComponent.startLogin(loginRequestEvent)
    );
  }

  isActive(path: string): boolean {
    return this.router.url.indexOf(path) > -1;
  }

  /**
   * We have our own isLoggedIn() function independently of the AuthenticationService so that any state
   * changes funnel through our subscription to the currentUser rather than the internal state of the
   * service.
   */
  isLoggedIn() {
    return this.currentUser && this.currentUser.token;
  }

  isLoginLinkVisible() {
    return !this.isLoggedIn();
  }

  isLogoutLinkVisible() {
    return this.isLoggedIn();
  }

  onLogin() {
    this.startTokenWatcher();
    this.authenticationService.startLogin('/assessment-search');
  }

  onLogout() {
    this.stopTokenWatcher();
    this.authenticationService.logout();
    this.router.navigate(['home']);
  }

  startTokenWatcher() {
    console.log('startTokenWatcher()');
    this.tokenWatcherInterval = setInterval(() => {
      this.checkForExpiredToken();
    }, 10000);
  }

  stopTokenWatcher() {
    console.log('stopTokenWatcher()');
    if (this.tokenWatcherInterval) {
      clearInterval(this.tokenWatcherInterval);
      this.tokenWatcherInterval = null;
    }
  }

  checkForExpiredToken() {
    console.log('checkForExpiredToken()');
    if (this.jwtHelper.tokenGetter()) {
      if (this.jwtHelper.isTokenExpired()) {
        console.log('TokenExpired....');
        this.onLogout();
      }
    } else {
      this.stopTokenWatcher();
    }
  }

  isNameDisplayed() {
    return this.authenticationService.isLoggedIn();
  }
}
