<div class="container-fluid">
  <h2>NZTCS Contacts</h2>
  <hr />

  <p>
    For all enquiries email
    <a href="mailto:threatstatus@doc.govt.nz">threatstatus@doc.govt.nz</a>
  </p>

  <p>Emails will be answered Monday to Friday between 8:30am and 4:30pm</p>
</div>
