<div
  bsModal
  #editTagsModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Tags</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCancelEditTags()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
            <label>Current Tags</label>
            <select
              multiple
              size="6"
              class="form-control"
              [(ngModel)]="selectedCurrentItems"
            >
              <option *ngFor="let item of selectedTags" [ngValue]="item">
                {{ item.tagName }}
              </option>
            </select>
          </div>

          <div class="col-md-1">
            <label>&nbsp;</label>
            <div class="row">
              <button
                type="button"
                class="btn btn-light btn-sm btn-block my-1"
                (click)="addTagAction()"
              >
                <fa-icon [icon]="addTagIcon"></fa-icon>
              </button>
            </div>
            <div class="row">
              <button
                type="button"
                class="btn btn-light btn-sm btn-block my-1"
                (click)="removeTagAction()"
              >
                <fa-icon [icon]="removeTagIcon"></fa-icon>
              </button>
            </div>
          </div>

          <div class="col-md-5">
            <label>Available Tags</label>
            <select
              multiple
              size="6"
              class="form-control"
              [(ngModel)]="selectedAvailableItems"
              (change)="
                onTagSelectChange($event.target, selectedAvailableItems)
              "
            >
              <option *ngFor="let item of availableTags" [ngValue]="item">
                {{ item.tagName }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-primary" (click)="okayAction()">Okay</button>
        <button class="btn btn-light" (click)="onCancelEditTags()">
          Cancel
        </button>
      </div>
    </div>
  </div>
</div>
