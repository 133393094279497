import {
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { SpeciesSourceItem } from '../species-source-item';
import { SpeciesSourceDirective } from '../species-source.directive';
import { SpeciesSourceComponent } from '../species-source-component';

@Component({
  selector: 'app-species-source-list',
  templateUrl: './species-source-list.component.html',
  styleUrls: ['species-source-list.component.scss'],
})
export class SpeciesSourceListComponent implements OnInit {
  @Input()
  speciesSourceItems: SpeciesSourceItem[];

  @ViewChild(SpeciesSourceDirective)
  speciesSourceHost: SpeciesSourceDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    this.loadComponents();
  }

  loadComponents() {
    const viewContainerRef = this.speciesSourceHost.viewContainerRef;
    viewContainerRef.clear();

    this.speciesSourceItems.forEach((item) => {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(item.component);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      (componentRef.instance as SpeciesSourceComponent).nztcsSpecies =
        item.nztcsSpecies;
    });
  }
}
