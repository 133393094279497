export class AttachmentSearchCriteria {
  nameCriteria: string;
  context: string;
  publicallyVisible: boolean;

  pageNumber = 1;
  pageSize = 25;

  constructor() {}
}
