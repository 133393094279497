<nav
  class="navbar navbar-expand navbar-dark"
>
  <a class="navbar-brand" href="/">
    <img src="/assets/nztcs_logo.png" title="New Zealand Threat Classification System"/>
  </a>

  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarsExample02"
    aria-controls="navbarsExample02"
    aria-expanded="false"
    aria-label="Toggle navigation"
  >
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse navbar-text">
    <ul class="navbar-nav mr-auto"></ul>

    <div class="mr-2" *ngIf="isNameDisplayed() && currentUser">
      {{ currentUser.name }}
    </div>

    <div *ngIf="isLoginLinkVisible()">
      <a href="#" (click)="onLogin()">
        <fa-icon [icon]="loginIcon"></fa-icon> Login
      </a>
    </div>

    <div *ngIf="isLogoutLinkVisible()">
      <a href="#" (click)="onLogout()">
        <fa-icon [icon]="logoutIcon"></fa-icon> Logout
      </a>
    </div>
  </div>
</nav>

<div class="container-fluid">
  <div class="row">
    <div class="col-1">
      <ul class="nav flex-column">
        <li class="nav-item">
          <a routerLink="/home" routerLinkActive="active">Home</a>
        </li>
        <li class="nav-item">
          <a routerLink="/contact" routerLinkActive="active">Contact</a>
        </li>
        <li class="nav-item">
          <a routerLink="/report-search" routerLinkActive="active">Reports</a>
        </li>
        <li class="nav-item">
          <a routerLink="/assessment-search" routerLinkActive="active"
            >Assessments</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/nztcs-species-search" routerLinkActive="active"
            >Species</a
          >
        </li>
        <li class="nav-item">
          <a routerLink="/nztcs-taxon-search" routerLinkActive="active">Taxa</a>
        </li>
        <li class="nav-item">
          <a routerLink="/assessment-export" routerLinkActive="active"
            >Exports</a
          >
        </li>
        <li class="nav-item" *ngIf="currentUser?.nameEditor">
          <a routerLink="/name-match-search" routerLinkActive="active">NZOR</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.administrator">
          <a routerLink="/batch-control" routerLinkActive="active">Batch</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.administrator">
          <a routerLink="/code-value" routerLinkActive="active">Codes</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.administrator">
          <a routerLink="/content-search" routerLinkActive="active">Content</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.administrator">
          <a routerLink="/attachment-search" routerLinkActive="active"
            >Attachments</a
          >
        </li>
        <li class="nav-item" *ngIf="currentUser?.authenticated">
          <a routerLink="/person-search" routerLinkActive="active">People</a>
        </li>
        <li class="nav-item" *ngIf="currentUser?.administrator">
          <a routerLink="/tags" routerLinkActive="active">Tags</a>
        </li>

        <app-content-links></app-content-links>

        <div *ngIf="versionData" class="a-small-note">
          Version: {{ versionData.version }}
        </div>
      </ul>
    </div>
    <div class="col-11">
      <ng-progress #progressBar color="#ff0000" [spinner]="false"></ng-progress>
      <!-- Routed views go here -->
      <router-outlet></router-outlet>
    </div>
  </div>
</div>

<app-login #loginComponent (loginEvent)="onLogin()"></app-login>
