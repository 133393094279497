import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-nzortaxon-name-list',
  templateUrl: './nzortaxon-name-list.component.html',
})
export class NZORTaxonNameListComponent implements OnInit {
  @Input()
  nzorName;

  constructor() {}

  ngOnInit() {}

  namesInReverse(): any[] {
    return this.nzorName.classificationNames.reverse();
  }
}
