import { Component, OnInit } from '@angular/core';
import { AssessmentExportService } from '../assessment/service/assessment-export.service';
import { Router } from '@angular/router';
import { AssessmentSearchCriteria } from '../assessment/service/assessment-search-criteria';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  constructor(
    private exportService: AssessmentExportService,
    private router: Router
  ) {}

  ngOnInit() {}

  onExportAllData() {
    const criteria = new AssessmentSearchCriteria();
    criteria.reportEditStatus = 'PUBLISHED';
    criteria.reportPublishedStatus = 'CURRENT';

    this.exportService.startExport(criteria).subscribe(
      (response) => {
        console.log(response);
        this.router.navigate(['/assessment-export']);
      },
      (errData) => {
        console.log(errData);
        this.router.navigate(['/assessment-export']);
      }
    );
  }
}
