import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PersonService } from '../service/person.service';

@Component({
  selector: 'app-panel-membership',
  templateUrl: './panel-membership.component.html',
  styleUrls: ['./panel-membership.component.scss'],
})
export class PanelMembershipComponent implements OnChanges {
  @Input()
  personId: number;

  @Output()
  deleteEnabledEvent = new EventEmitter();

  panelMembershipList: any[];

  constructor(private personService: PersonService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.personId) {
      this.personService
        .findPanelMembership(this.personId)
        .subscribe((response: any) => {
          this.panelMembershipList = response;
          const deleteEnabled =
            this.panelMembershipList && this.panelMembershipList.length === 0;
          this.deleteEnabledEvent.next(deleteEnabled);
        });
    }
  }
}
