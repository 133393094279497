import { Tag } from '../../domain/nztcs/tag';

export class AssessmentSearchCriteria {
  reportId: number;
  reportName: string;
  showArchived = false;
  reportEditStatus: string;
  reportPublishedStatus: string;

  umbrellaCategory: string;
  categoryCode: any[] = [];
  editStatus: string;
  tag: Tag;

  assessmentName: string;
  environment: string;
  taxonomicStatus: string;
  speciesType: string;

  loadPreviousAssessmentDetails: boolean;
  pageNumber: number;
  pageSize = 20;
}
