import { Component, OnInit } from '@angular/core';
import { AssessmentExportService } from '../service/assessment-export.service';
import { Subscription, Observable, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-assessment-export',
  templateUrl: './assessment-export.component.html',
})
export class AssessmentExportComponent implements OnInit {
  descriptorList: any = {};
  pollingSub: Subscription = new Subscription();

  exportCsv = "href = '/rest/assessmentExport/getExportData/'";

  constructor(private exportService: AssessmentExportService) {}

  ngOnInit() {
    this.startPolling();
  }

  refreshAction() {
    this.exportService.refresh().subscribe((descriptors) => {
      console.log(descriptors);
      this.descriptorList = descriptors;
    });
  }

  startPolling() {
    /*this.pollingSub = Observable.interval(10000).switchMap(() => {
      return this.exportService.refresh();
    }).subscribe(
      (nextResponse) => {
        console.log('Poll: ' + new Date());
        this.updateView(nextResponse);
      });*/

    this.pollingSub.unsubscribe();
    this.pollingSub = new Subscription();
    this.pollingSub.add(
      interval(5000)
        .pipe(
          startWith(0),
          switchMap(() => this.exportService.refresh())
        )
        .subscribe((nextResponse) => {
          console.log('Poll: ' + new Date());
          this.updateView(nextResponse);
        })
    );
  }

  stopPolling() {
    this.pollingSub.unsubscribe();
  }

  updateView(response: any) {
    this.descriptorList = response;

    let statusCheck = false;
    for (const des of this.descriptorList) {
      if (des.status === 'PENDING') {
        statusCheck = true;
      }
    }

    if (statusCheck) {
      console.log('Poll: ' + new Date());
    } else {
      console.log('Stopped polling');
      this.stopPolling();
    }
  }

  downloadExportCsv(id: string) {
    window.location.href = '/rest/assessmentExport/getExportData/' + id;
  }
}
