<div
  bsModal
  #resetPasswordModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Reset Password</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCancelResetPassword()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="!nextPassword">
        Are you sure you want reset this person's password?
        <hr />
        <button
          id="confirmResetPasswordBtn"
          type="button"
          (click)="onConfirmResetPassword(resetPasswordModal)"
          class="btn btn-danger"
        >
          Reset
        </button>
        <button
          id="cancelResetPasswordBtn"
          type="button"
          (click)="onCancelResetPassword(resetPasswordModal)"
          class="btn btn-light"
        >
          Cancel
        </button>
      </div>
      <div class="modal-body" *ngIf="nextPassword">
        The password for this person is now: <strong>{{ nextPassword }}</strong>
        <hr />
        <button
          id="dismissOkBtn"
          type="button"
          (click)="onDismissOk()"
          class="btn btn-success"
        >
          Ok
        </button>
      </div>
    </div>
  </div>
</div>
