import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { User } from '../../authentication/service/user';
import { CommentService } from '../service/comment.service';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { TinymceUtils } from '../../tinymce/tinymce-utils';
import {
  faPencilAlt,
  faTrashAlt,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { TextEditorComponent } from '../../text-editor/text-editor.component';

@Component({
  selector: 'app-comment-list',
  templateUrl: './comment-list.component.html',
})
export class CommentListComponent implements OnInit {
  @ViewChild('textEditorComponent')
  textEditorComponent: TextEditorComponent;

  @Input()
  addCommentAvailable = true;

  @Output()
  commentListCount = new EventEmitter();

  commentList: any[];
  editMode = false;
  editedComment: any;

  editorSettings = TinymceUtils.editorSettings;

  personIcon = faUserAlt;
  editIcon = faPencilAlt;
  deleteIcon = faTrashAlt;

  currentUser: User;

  private _assessmentId: number;

  constructor(
    private authenticationService: AuthenticationService,
    private commentService: CommentService
  ) {}

  get assessmentId(): number {
    return this._assessmentId;
  }

  @Input()
  set assessmentId(value: number) {
    this._assessmentId = value;
    this.loadComments();
  }

  ngOnInit() {
    this.authenticationService.currentUser$.subscribe((currentUser) => {
      this.currentUser = currentUser;
      this.loadComments();
    });
  }

  loadComments() {
    if (this.currentUser && this.currentUser.authenticated) {
      this.commentService
        .getCommentsByAssessmentId(this._assessmentId)
        .subscribe((response: any) => {
          this.commentList = response;
          this.commentListCount.next(
            this.commentList ? this.commentList.length : 0
          );
        });
    }
  }

  editCommentAction(comment: any) {
    this.editedComment = comment;
    this.editMode = true;
  }

  removeCommentAction(comment: any) {
    this.commentService
      .removeComment(comment.commentId)
      .subscribe((removeResponse) => {
        this.loadComments();
      });
  }

  addNewCommentAction() {
    const newComment = {
      assessmentId: this._assessmentId,
      personName: this.currentUser.name,
      editDate: new Date(),
    };

    this.editedComment = newComment;
    this.editMode = true;
  }

  saveCommentAction() {
    this.editedComment.commentText = this.textEditorComponent.text;
    this.commentService
      .saveComment(this.editedComment)
      .subscribe((response) => {
        this.editMode = false;
        this.loadComments();
      });
  }

  cancelSaveCommentAction() {
    this.editMode = false;
    this.loadComments();
  }
}
