import { Component, OnInit } from '@angular/core';
import { AssessmentSearchCtxService } from '../service/assessment-search-ctx.service';
import { ReportService } from '../../report/service/report.service';
import { Tag } from '../../domain/nztcs/tag';
import { EditStatus } from '../../domain/nztcs/edit-status';
import { TagService } from '../../tag/service/tag.service';
import { AuthenticationService } from '../../authentication/service/authentication.service';
import { User } from '../../authentication/service/user';
import { UmbrellaCategories } from '../../domain/nztcs/umbrella-categories';
import { TaxonomicStatus } from '../../domain/nztcs/taxonomic-status';
import { AssessmentExportService } from '../service/assessment-export.service';
import { Router } from '@angular/router';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { Environment } from '../../domain/nztcs/environment';
import { AssessmentService } from '../service/assessment.service';
import { NztcsSpeciesNameComparisonService } from '../../nztcs-species/service/nztcs-species-name-comparison.service';

@Component({
  selector: 'app-assessment-search',
  templateUrl: './assessment-search.component.html',
})
export class AssessmentSearchComponent implements OnInit {
  reportNames: string[];

  editStatusOptions = EditStatus.VALUES;

  availableTags: Tag[];
  umbrellaCategories = UmbrellaCategories.VALUES;
  taxonomicStatuses = TaxonomicStatus.VALUES;
  speciesTypes: string[];

  environmentOptions: string[];
  dataSource: Observable<any>;

  currentUser: User;

  constructor(
    public searchCtx: AssessmentSearchCtxService,
    private assessmentService: AssessmentService,
    private reportService: ReportService,
    private tagService: TagService,
    private authenticationService: AuthenticationService,
    private exportService: AssessmentExportService,
    private nztcsSpeciesService: NztcsSpeciesService,
    public nztcsSpeciesNameComparisonService: NztcsSpeciesNameComparisonService,
    private router: Router
  ) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.searchCtx.criteria.assessmentName);
    }).pipe(mergeMap((token: string) => this.getNameTokensAsObservable(token)));
  }

  getNameTokensAsObservable(token: string): Observable<any> {
    return this.assessmentService.findSpeciesNamesByNameCriteria(token);
  }

  ngOnInit() {
    this.speciesTypes = this.nztcsSpeciesService.getSpeciesTypes();
    this.environmentOptions = Environment.VALUES;
    this.authenticationService.currentUser$.subscribe(
      (currentUser) => (this.currentUser = currentUser)
    );
    this.refreshReportNames(this.searchCtx.criteria.showArchived, false);
    this.tagService
      .findAll()
      .subscribe((tagList) => (this.availableTags = tagList));
    this.searchAction();
  }

  searchAction() {
    this.searchCtx.search();
  }

  clearAction() {
    this.searchCtx.clear();
    this.searchAction();
  }

  onPageChanged(event: any): void {
    if (event.page && this.searchCtx.criteria) {
      this.searchCtx.criteria.pageNumber = event.page;
      this.searchAction();
    }
  }

  refreshReportNames(showArchived: boolean, performSearch: boolean) {
    this.reportService.getReportNames(showArchived).subscribe((reportNames) => {
      this.reportNames = reportNames;
      if (performSearch) {
        this.searchAction();
      }
    });
  }

  /**
   * See report search for more details about this...
   */
  onShowArchivedChanged() {
    console.log('showArchived = ' + this.searchCtx.criteria.showArchived);
    this.refreshReportNames(!this.searchCtx.criteria.showArchived, true);
  }

  exportAction() {
    this.exportService.startExport(this.searchCtx.criteria).subscribe(
      (response) => {
        console.log(response);
        this.router.navigate(['/assessment-export']);
      },
      (errData) => {
        console.log(errData);
        this.router.navigate(['/assessment-export']);
      }
    );
  }
}
