<div
  bsModal
  #editNameModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Name</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="onCancel()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <editor [init]="editorSettings" [(ngModel)]="assessmentName"></editor>
      </div>

      <div class="modal-footer">
        <button
          class="btn btn-primary"
          [disabled]="!assessmentName || assessmentName.length === 0"
          (click)="onOkay()"
        >
          Okay
        </button>
        <button class="btn btn-light" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</div>
