/* eslint-disable @typescript-eslint/naming-convention */
import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
})
export class StatusBadgeComponent implements OnChanges {
  @Input()
  statusValue: string;

  statusClassMap = {};

  constructor() {}

  /**
   * Cheating here a little and merging the Edit status values and the Published status values into
   * the same map, even though they are potentially different.
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    this.statusClassMap = {
      'badge-success':
        this.statusValue === 'DRAFT' ||
        this.statusValue === 'PENDING' ||
        this.statusValue === 'IN_PROGRESS' ||
        this.statusValue === 'CURRENT',
      'badge-primary': this.statusValue === 'PUBLISHED',
      'badge-warning': this.statusValue === 'COMPLETED',

      'badge-secondary': this.statusValue === 'ARCHIVED',
    };
  }
}
