import { Injectable } from '@angular/core';
import { SpeciesSourceItem } from './species-source-item';
import { BiowebSpeciesSourceComponent } from './bioweb-species-source/bioweb-species-source.component';
import { NZORSpeciesSourceComponent } from './nzorspecies-source/nzorspecies-source.component';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';

@Injectable({
  providedIn: 'root',
})
export class SpeciesSourceService {
  constructor() {}

  getSpeciesSourceList(nztcsSpecies: NztcsSpeciesEntity) {
    return [
      new SpeciesSourceItem(BiowebSpeciesSourceComponent, nztcsSpecies),
      new SpeciesSourceItem(NZORSpeciesSourceComponent, nztcsSpecies),
    ];
  }
}
