<div class="form-group" [ngClass]="{ hiddenSpacer: !sourceLabel }">
  <label class="a-form-label">{{ sourceLabel }}</label>
  <div class="input-group">
    <div class="input-group-prepend" *ngIf="targetEntity && targetAttribute">
      <button
        class="btn btn-outline-success"
        type="button"
        (click)="copyAction()"
      >
        <fa-icon [icon]="faAngleLeft"></fa-icon>
      </button>
    </div>
    <div class="input-group-prepend" *ngIf="!targetEntity || !targetAttribute">
      <button class="btn btn-success" type="button" disabled="true">
        &nbsp;
      </button>
    </div>
    <div class="form-control bg-light h-auto">{{ getValue() }}</div>
  </div>
</div>
