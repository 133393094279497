<div
  bsModal
  #codeValueEditModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  (onHide)="onHide()"
  aria-labelledby="mySmallModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Edit Code Value</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <app-messages-view [messages]="responseMessages"></app-messages-view>

        <div class="row" *ngIf="codeValue">
          <div class="col-md-10">
            <form (ngSubmit)="onSave()" #editCodeValueForm="ngForm" novalidate>
              <div class="row">
                <div class="form-group col-md-6">
                  <label for="code">Code</label>
                  <input
                    type="text"
                    class="form-control"
                    id="code"
                    name="code"
                    [(ngModel)]="codeValue.code"
                    [disabled]="!codeEditable"
                  />
                </div>

                <div class="form-group col-md-6">
                  <label for="title">Title</label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    name="title"
                    [(ngModel)]="codeValue.title"
                  />
                </div>
              </div>

              <div class="row">
                <div class="form-group col-md-12">
                  <label for="code">Description</label>
                  <textarea
                    rows="6"
                    class="form-control"
                    id="description"
                    name="description"
                    [(ngModel)]="codeValue.description"
                  ></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <hr />
            <button
              id="confirmResetPasswordBtn"
              type="button"
              (click)="confirmAction()"
              class="btn btn-success"
            >
              Save
            </button>
            <button
              id="cancelResetPasswordBtn"
              type="button"
              (click)="cancelAction()"
              class="btn btn-light"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
