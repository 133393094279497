<span>
  <span tooltip="{{ taxon.taxonLevel.levelName }}"
    >{{ taxon.taxonName }}<span *ngIf="taxon.parentTaxon"> / </span></span
  >
  <span *ngIf="taxon.parentTaxon">
    <app-bioweb-taxon-name-list
      [taxon]="taxon.parentTaxon"
    ></app-bioweb-taxon-name-list>
  </span>
</span>
