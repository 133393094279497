import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PersonService } from '../../service/person.service';
import { ResponseCargo } from '../../../domain/response/ResponseCargo';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  @ViewChild('resetPasswordModal', { static: true })
  public resetPasswordModal: ModalDirective;

  @Output()
  resetPasswordEvent = new EventEmitter();

  userName: string;
  nextPassword: string;

  constructor(private personService: PersonService) {}

  ngOnInit() {}

  startReset(userName: string) {
    this.userName = userName;
    this.resetPasswordModal.show();
  }

  startNewPassword(userName: string) {
    this.userName = userName;
    this.onConfirmResetPassword(true);
  }

  onConfirmResetPassword(showModal: boolean = false) {
    this.personService.resetPassword(this.userName).subscribe((response) => {
      this.nextPassword = response.model;
      this.resetPasswordEvent.next('ResetComplete');
      if (showModal) {
        this.resetPasswordModal.show();
      }
    });
  }

  onCancelResetPassword() {
    this.nextPassword = null;
    this.resetPasswordModal.hide();
  }

  onDismissOk() {
    this.nextPassword = null;
    this.resetPasswordModal.hide();
  }
}
