<div
  bsModal
  #taxonSelectModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title pull-left">Taxon Select</h4>
        <button
          type="button"
          class="close pull-right"
          aria-label="Close"
          (click)="cancelAction()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body">
        <!--<app-messages-view [messages]="responseMessages"></app-messages-view>-->

        <form>
          <div class="row">
            <div class="col-md-10">
              <div class="row">
                <div class="col-md-6">
                  <label for="nameCriteriaTxtFld">Name</label>
                  <input
                    type="text"
                    class="form-control"
                    id="nameCriteriaTxtFld"
                    name="nameCriteriaTxtFld"
                    [(ngModel)]="criteria.name"
                  />
                </div>

                <div class="col-md-6">
                  <label for="rankTxtFld">Rank</label>
                  <select
                    class="form-control"
                    id="rankTxtFld"
                    name="rankTxtFld"
                    [(ngModel)]="criteria.rank"
                  >
                    <option [ngValue]="null"></option>
                    <option *ngFor="let rank of taxonRanks" [ngValue]="rank">
                      {{ rank }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6">
                  <label for="speciesTypeTxtFld">Species Type</label>
                  <select
                    class="form-control"
                    id="speciesTypeTxtFld"
                    name="speciesTypeTxtFld"
                    [(ngModel)]="criteria.speciesType"
                  >
                    <option [ngValue]="null"></option>
                    <option
                      *ngFor="let speciesType of speciesTypes"
                      [ngValue]="speciesType"
                    >
                      {{ speciesType }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-md-2">
              <button
                type="button"
                class="btn btn-primary btn-block"
                (click)="searchAction()"
              >
                Search
              </button>
              <button
                type="button"
                class="btn btn-light btn-block"
                (click)="clearAction()"
              >
                Clear
              </button>
            </div>
          </div>
        </form>

        <br />

        <div class="row" *ngIf="pagedSearchResponse">
          <div class="col-md-12">
            <div class="table-responsive">
              <table class="table table-striped table-bordered table-sm small">
                <thead>
                  <tr>
                    <th>Taxon Name</th>
                    <th>Taxon Rank</th>
                    <th>Species Type</th>
                  </tr>
                </thead>
                <tbody
                  *ngIf="
                    pagedSearchResponse && pagedSearchResponse.searchResults
                  "
                >
                  <tr
                    *ngFor="let taxon of pagedSearchResponse.searchResults"
                    (click)="selectAction(taxon.nztcsTaxonId)"
                  >
                    <td>{{ taxon.taxonName }}</td>
                    <td>{{ taxon.taxonRank }}</td>
                    <td>{{ taxon.speciesType }}</td>
                  </tr>
                </tbody>

                <tbody
                  *ngIf="
                    pagedSearchResponse && !pagedSearchResponse.searchResults
                  "
                >
                  <td colspan="100">No matching search results found.</td>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="row" *ngIf="pagedSearchResponse">
          <div class="col-md-6">
            <pagination
              [maxSize]="5"
              [itemsPerPage]="criteria.pageSize"
              [(ngModel)]="criteria.pageNumber"
              [totalItems]="pagedSearchResponse.total"
              (pageChanged)="onPageChanged($event)"
            >
            </pagination>
          </div>

          <div class="col-md-6 text-right" *ngIf="pagedSearchResponse">
            Total Results: {{ pagedSearchResponse.total }}, Page:
            {{ pagedSearchResponse.page }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
