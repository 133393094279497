<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Assessment Details</h2>
    </div>

    <div class="col-md-8 text-right mt-1">
      <span *ngIf="commentsDisplayed && commentListCount > 0">
        <fa-icon [icon]="commentIcon"></fa-icon>
      </span>

      <span *ngIf="tagsDisplayed && assessment.model">
        <span
          *ngFor="let tag of assessment.model.tags"
          class="badge badge-secondary mr-1"
          (mouseenter)="tag.mouseActive = true"
          (mouseleave)="tag.mouseActive = false"
        >
          {{ tag.tagName }}
          <a
            href="#"
            class="bg-secondary text-light"
            (click)="removeTagAction(tag)"
            *ngIf="tag.mouseActive"
          >
            <fa-icon [icon]="removeIcon"></fa-icon>
          </a>
        </span>
      </span>

      <button
        type="button"
        class="btn btn-sm"
        (click)="editTagsAction()"
        *ngIf="editTagsAvailable"
      >
        <fa-icon [icon]="editIcon"></fa-icon> Tags
      </button>

      <button
        type="button"
        class="btn btn-sm btn-light"
        [disabled]="!navigatePrevAvailable()"
        (click)="navigateToNextAssessment(-1)"
      >
        <fa-icon [icon]="moveLeft"></fa-icon>
        Previous
      </button>

      <button
        type="button"
        class="btn btn-sm btn-light"
        [disabled]="!navigateNextAvailable()"
        (click)="navigateToNextAssessment(1)"
      >
        Next
        <fa-icon [icon]="moveRight"></fa-icon>
      </button>
    </div>
  </div>

  <hr />
</div>

<div class="row" *ngIf="assessment.model && report.model">
  <!-- VIEW Mode -->
  <div class="col-md-6" *ngIf="!editMode">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-6">
            <h4>Selected Assessment</h4>
          </div>
          <div class="col-md-6" align="right">
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="changeAssessmentStatus('COMPLETED')"
                *ngIf="completeAvailable"
              >
                Complete
              </button>
              <button
                type="button"
                class="btn btn-info btn-sm"
                (click)="changeAssessmentStatus('IN_PROGRESS')"
                *ngIf="revertAvailable"
              >
                Revert to Draft
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                (click)="updateNameAction()"
                *ngIf="updateNameAvailable"
              >
                Update Name
              </button>
              <button
                type="button"
                class="btn btn-success btn-sm"
                (click)="editAction()"
                *ngIf="editAvailable"
              >
                Edit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <tabset>
          <tab heading="{{ report.model.yearAssessed }}">
            <app-assessment-tab
              [assessment]="assessment"
              [nztcsSpecies]="nztcsSpecies"
              [report]="report"
            ></app-assessment-tab>
          </tab>
        </tabset>
      </div>
    </div>
  </div>

  <!-- EDIT Mode -->
  <div class="col-md-6" *ngIf="editMode">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-6">
            <h4>Selected Assessment</h4>
          </div>
          <div class="col-md-6" align="right">
            <div class="btn-group" dropdown>
              <button
                dropdownToggle
                type="button"
                class="btn btn-sm btn-primary dropdown-toggle"
              >
                Save As... <span class="caret"></span>
              </button>
              <ul *dropdownMenu class="dropdown-menu" role="menu">
                <li *ngFor="let saveOption of saveOptions" role="menuitem">
                  <a
                    class="dropdown-item"
                    href="#"
                    (click)="saveAction(saveOption)"
                    >{{ saveOption }}</a
                  >
                </li>
              </ul>
            </div>

            <div class="btn-group">
              <!-- no need to do "access" checking on these buttons, because it's implicit within getting the the edit permission -->
              <!--<button type="button" class="btn btn-primary btn-sm" (click)="saveAction('COMPLETED')">Save Complete</button>
              <button type="button" class="btn btn-success btn-sm" (click)="saveAction()">Save Draft</button>-->
              <button
                type="button"
                class="btn btn-light btn-sm"
                (click)="cancelAction()"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body">
        <alert type="danger" *ngFor="let msg of assessment.responseMessages">
          {{ msg.description }}
        </alert>
        <tabset>
          <tab [heading]="report.model.yearAssessed" active="true">
            <br />

            <div class="row">
              <div class="col-md-4"><b>Species Name</b></div>
              <div class="col-md-8">
                <div [innerHTML]="assessment.model.speciesAssessmentName"></div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4"><b>Edit Status</b></div>
              <div class="col-md-8">
                <div>
                  <app-status-badge
                    [statusValue]="assessment.model.editStatus"
                  ></app-status-badge>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4"><b>Report</b></div>
              <div class="col-md-8">
                <a href="#/reports/{{ report.model.reportId }}">{{
                  report.model.name
                }}</a>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-10">
                <div class="form-group">
                  <label class="control-label" for="inlineRadio1"
                    >Population State</label
                  >
                  <br />
                  <label class="radio-inline mr-4">
                    <input
                      type="radio"
                      name="inlineRadio1"
                      id="inlineRadio1"
                      [(ngModel)]="assessment.model.populationState"
                      value="NATURAL"
                      (change)="updateConservationStatus(null)"
                    />
                    Natural
                  </label>
                  <label class="radio-inline mr-4">
                    <input
                      type="radio"
                      name="inlineRadio2"
                      id="inlineRadio2"
                      [(ngModel)]="assessment.model.populationState"
                      value="UNNATURAL"
                      (change)="updateConservationStatus(null)"
                    />
                    Unnatural
                  </label>
                  <label class="radio-inline mr-4">
                    <input
                      type="radio"
                      name="inlineRadio3"
                      id="inlineRadio3"
                      [(ngModel)]="assessment.model.populationState"
                      value="UNKNOWN"
                      (change)="updateConservationStatus(null)"
                    />
                    Unknown
                  </label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label class="control-label" for="popTrendSelect"
                    >Population Trend</label
                  >

                  <select
                    class="form-control"
                    id="popTrendSelect"
                    name="popTrendSelect"
                    [(ngModel)]="assessment.model.populationTrend"
                    [compareWith]="compareOnCode"
                    (change)="updateConservationStatus(null)"
                  >
                    <option [ngValue]="null"></option>
                    <option *ngFor="let pt of popTrendOptions" [ngValue]="pt">
                      {{ pt.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="control-label" for="trendConfSelect"
                    >Trend Confidence</label
                  >
                  <select
                    class="form-control"
                    id="trendConfSelect"
                    name="trendConfSelect"
                    [(ngModel)]="assessment.model.popTrendConfidence"
                    [compareWith]="compareOnCode"
                  >
                    <option [ngValue]="null"></option>
                    <option
                      *ngFor="let tc of trendConfidenceOptions"
                      [ngValue]="tc"
                    >
                      {{ tc.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <br />

            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label class="control-label" for="popSizeSelect"
                    >Population Size</label
                  >
                  <select
                    class="form-control"
                    id="popSizeSelect"
                    name="popSizeSelect"
                    [(ngModel)]="assessment.model.populationSize"
                    (change)="updateConservationStatus(null)"
                    [compareWith]="compareOnCode"
                  >
                    <option [ngValue]="null"></option>
                    <option *ngFor="let ps of popSizeOptions" [ngValue]="ps">
                      {{ ps.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-5">
                <div class="form-group">
                  <label class="control-label" for="sizeConfSelect"
                    >Size Confidence</label
                  >
                  <select
                    class="form-control"
                    id="sizeConfSelect"
                    name="sizeConfSelect"
                    [(ngModel)]="assessment.model.popSizeConfidence"
                    [compareWith]="compareOnCode"
                  >
                    <option [ngValue]="null"></option>
                    <option
                      *ngFor="let sc of sizeConfidenceOptions"
                      [ngValue]="sc"
                    >
                      {{ sc.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-7">
                <label class="control-label" for="categorySelect"
                  >Conservation Status</label
                >
                <select
                  class="form-control"
                  id="categorySelect"
                  name="categorySelect"
                  (ngModelChange)="updateConservationStatus($event)"
                  [(ngModel)]="assessment.model.category"
                  [compareWith]="compareOnCode"
                >
                  <option [ngValue]="null"></option>
                  <option
                    *ngFor="let category of categoryList"
                    [ngValue]="category"
                  >
                    {{ category.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-5">
                <label class="control-label" for="umbrellaCategory"
                  >Umbrella Category</label
                >
                <div
                  class="form-control rowtotal"
                  id="umbrellaCategory"
                  name="umbrellaCategory"
                  *ngIf="assessment.model.category"
                >
                  {{ assessment.model.category.umbrellaCategory }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-7"></div>
              <div class="col-md-5">
                <label class="control-label" for="criteriaPath"> {{ report.model.reportType.code === 'R' ? 'Criteria Code' : 'Criteria'}} </label>
                <div
                  class="form-control rowtotal"
                  id="criteriaPath"
                  name="criteriaPath"
                >
                  {{ assessment.model.criteria }}
                </div>
              </div>
            </div>

            <hr />

            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label" for="statusChangeSelect"
                    >Status Change</label
                  >
                  <fa-icon
                    [icon]="warningIcon"
                    [tooltip]="
                      'If Conservation Status is different then Status Change can\'t be No Change'
                    "
                    *ngIf="isStatusChangeRequired()"
                  ></fa-icon>
                  <select
                    class="form-control"
                    [ngClass]="
                      isStatusChangeRequired() ? 'border border-warning' : ''
                    "
                    id="statusChangeSelect"
                    name="statusChangeSelect"
                    [(ngModel)]="assessment.model.statusChange"
                    [compareWith]="compareOnCode"
                  >
                    <option
                      *ngFor="let statusChange of statusChangeList"
                      [ngValue]="statusChange"
                    >
                      {{ statusChange.title }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label class="control-label" for="changeReasonSelect"
                    >Change Reason</label
                  >
                  <fa-icon
                    [icon]="warningIcon"
                    [tooltip]="
                      'If Conservation Status is different then Change Reason can\'t be No Change'
                    "
                    *ngIf="isChangeReasonRequired()"
                  ></fa-icon>
                  <select
                    class="form-control"
                    [ngClass]="
                      isChangeReasonRequired() ? 'border border-warning' : ''
                    "
                    id="changeReasonSelect"
                    name="changeReasonSelect"
                    [(ngModel)]="assessment.model.changeReason"
                    [compareWith]="compareOnCode"
                  >
                    <option
                      *ngFor="let changeReason of changeReasonList"
                      [ngValue]="changeReason"
                    >
                      {{ changeReason.title }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">Qualifiers</div>
            </div>
            <div class="row">
              <div class="col-md-5">
                <select
                  multiple
                  size="8"
                  class="form-control"
                  [(ngModel)]="selectedAssessmentQualifiers"
                  [compareWith]="compareOnCode"
                >
                  <option
                    *ngFor="let qualifier of assessment.model.qualifiers"
                    [ngValue]="qualifier"
                  >
                    {{ qualifier.title }}
                  </option>
                </select>
              </div>
              <div class="col-md-2">
                <button
                  type="button"
                  class="btn btn-light btn-sm btn-block"
                  (click)="
                    onMoveQualifier(
                      availableQualifiers,
                      assessment.model.qualifiers,
                      selectedAvailableQualifiers
                    )
                  "
                >
                  &lt;
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-sm btn-block"
                  (click)="
                    onMoveQualifier(
                      assessment.model.qualifiers,
                      availableQualifiers,
                      selectedAssessmentQualifiers
                    )
                  "
                >
                  &gt;
                </button>
              </div>
              <div class="col-md-5">
                <select
                  multiple
                  size="8"
                  class="form-control"
                  [(ngModel)]="selectedAvailableQualifiers"
                  [compareWith]="compareOnCode"
                >
                  <option *ngFor="let aq of availableQualifiers" [ngValue]="aq">
                    {{ aq.title }}
                  </option>
                </select>
              </div>
            </div>
          </tab>
        </tabset>
      </div>
    </div>
  </div>

  <!-- Assessment History - always shown -->
  <div class="col-md-6">
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col-md-6">
            <h4>Assessment History</h4>
          </div>
          <div class="col-md-6" align="right">
            <!-- Placeholder for top right actions -->
          </div>
        </div>
      </div>
      <div class="card-body">
        <tabset>
          <tab
            *ngFor="let relatedAssessment of relatedAssessments"
            [heading]="relatedAssessment.report.model.yearAssessed"
          >
            <app-assessment-tab
              [assessment]="relatedAssessment.assessment"
              [report]="relatedAssessment.report"
            ></app-assessment-tab>
          </tab>
        </tabset>
      </div>
    </div>

    <br />
    <div *ngIf="assessment.model.speciesId">
      <app-nztcs-species-assessment-view
        [nztcsSpeciesId]="assessment.model.speciesId"
      ></app-nztcs-species-assessment-view>
    </div>

    <br />
    <app-nzor-detail
      [nztcsSpeciesId]="assessment.model.speciesId"
    ></app-nzor-detail>
  </div>
</div>

<div class="row" *ngIf="editMode">
  <div class="col-md-12">
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="control-label">Public Notes</label>
        <!--<editor [init]="editorSettings" [(ngModel)]="assessment.model.publicNotes"></editor>-->
        <app-text-editor
          #textEditorPublicNotes
          [context]="'assessment:' + assessmentId"
          [text]="assessment.model.publicNotes"
          [attachmentsEnabled]="assessmentId > 0"
          (attachmentsChangedEvent)="publicNotesAttachmentsChanged()"
          [publicallyVisible]="true"
        ></app-text-editor>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-md-12">
        <label class="control-label">Private Notes</label>
        <!--<editor [init]="editorSettings" [(ngModel)]="assessment.model.notes"></editor>-->
        <app-text-editor
          #textEditorPrivateNotes
          [context]="'assessment:' + assessmentId"
          [text]="assessment.model.notes"
          [attachmentsEnabled]="assessmentId > 0"
          (attachmentsChangedEvent)="privateNotesAttachmentsChanged()"
        >
        </app-text-editor>
      </div>
    </div>
  </div>
</div>

<!-- Comments and Tags - only if authenticated user -->
<div *ngIf="currentUser.authenticated">
  <br />
  <app-comment-list
    [assessmentId]="assessmentId"
    [addCommentAvailable]="addCommentAvailable"
    (commentListCount)="onCommentListCountChanged($event)"
  ></app-comment-list>
  <app-edit-tags
    #editTagsComponent
    [assessmentId]="assessmentId"
    [(assessmentTags)]="assessment.model.tags"
    *ngIf="assessment.model"
  ></app-edit-tags>
</div>
