import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Tag } from '../../domain/nztcs/tag';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TagService } from '../service/tag.service';

@Component({
  selector: 'app-tag-edit',
  templateUrl: './tag-edit.component.html',
})
export class TagEditComponent implements OnInit {
  @ViewChild('tagEditModal', { static: true })
  tagEditModal: ModalDirective;

  @Input()
  tag: Tag;

  @Output()
  tagChangedEvent = new EventEmitter();

  constructor(private tagService: TagService) {}

  ngOnInit() {}

  saveAction() {
    this.tagService.save(this.tag).subscribe(() => {
      this.cancelAction();
      this.tagChangedEvent.next();
    });
  }

  cancelAction() {
    this.tagEditModal.hide();
  }

  editTag(tag: Tag) {
    this.tag = tag;
    this.tagEditModal.show();
  }
}
