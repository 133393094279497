/* eslint-disable @typescript-eslint/naming-convention */
export class EditStatus {
  public static VALUES = [
    'DRAFT',
    'PENDING',
    'IN_PROGRESS',
    'COMPLETED',
    'PUBLISHED',
  ];
}
