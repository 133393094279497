export class User {
  personId: number;
  name: string;
  token: string;
  authorities: string[];

  authenticated: boolean;
  nameEditor: boolean;
  historicalNameEditor: boolean;
  administrator: boolean;

  updatePermissions(): void {
    this.authenticated = this.hasRole('ROLE_USER');
    this.nameEditor = this.hasRole('ROLE_NAME_EDITOR');
    this.historicalNameEditor = this.hasRole('ROLE_HISTORICAL_NAME_EDITOR');
    this.administrator = this.hasRole('ROLE_ADMINISTRATOR');
  }

  hasRole(roleName: string): boolean {
    return this.authorities && this.authorities.indexOf(roleName) > -1;
  }
}
