import { Injectable } from '@angular/core';
import { NztcsTaxonSearchCriteria } from './nztcs-taxon-search-criteria';
import { NztcsTaxonEntityService } from '../service/nztcs-taxon-entity.service';
import { PagedResponse } from '../../domain/response/PagedResponse';

@Injectable({
  providedIn: 'root',
})
export class NztcsTaxonSearchCtxServiceService {
  criteria = new NztcsTaxonSearchCriteria();
  response: any = {
    searchResults: [],
    numberOfPages: 0,
  };

  constructor(private nztcsTaxonEntityService: NztcsTaxonEntityService) {}

  hasSearchResults(): boolean {
    return this.response && this.response.searchResults.length > 0;
  }

  search() {
    this.nztcsTaxonEntityService
      .findByCriteria(this.criteria)
      .subscribe((pagedResponse: PagedResponse) => {
        this.response = pagedResponse;
        this.response.numberOfPages = Math.ceil(
          pagedResponse.total / this.criteria.pageSize
        );
      });
  }

  clear() {
    this.criteria = new NztcsTaxonSearchCriteria();
  }
}
