import { Component, OnInit, ViewChild } from '@angular/core';
import { Content } from '../service/content';
import { ActivatedRoute, Router } from '@angular/router';
import { ContentService } from '../service/content.service';
import { TextEditorComponent } from '../../text-editor/text-editor.component';

@Component({
  selector: 'app-content-edit',
  templateUrl: './content-edit.component.html',
})
export class ContentEditComponent implements OnInit {
  @ViewChild('textEditorComponent')
  textEditorComponent: TextEditorComponent;

  contentName: string;
  content: Content;

  visibilityOptionList = [];

  constructor(
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.visibilityOptionList =
      this.contentService.getContentVisibilityOptions();

    this.route.params.subscribe((params) => {
      this.contentName = params['contentName'];
      if (this.contentName && this.contentName === '__new__') {
        console.log('new content ');
        this.newContent();
      } else if (this.contentName) {
        console.log('load content ' + this.contentName);
        this.loadContent();
      }
      // else do nothing...
    });
  }

  loadContent() {
    this.contentService
      .findByName(this.contentName)
      .subscribe((response) => (this.content = response));
  }

  getContext() {
    return 'content:' + this.content.contentId;
  }

  newContent() {
    this.content = new Content();
  }

  saveAction() {
    // Only updated the text when we do a save
    this.content.html = this.textEditorComponent.text;

    this.contentService.save(this.content).subscribe(() => {
      this.router.navigate(['content-search']);
    });
  }

  cancelAction() {
    this.router.navigate(['content-search']);
  }
}
