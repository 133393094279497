import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlines',
})
export class NewlinesPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (value) {
      return value.split(/\n/g);
    } else {
      return null;
    }
  }
}
