import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UmbrellaCategoryStatistic } from './umbrella-category-statistic';

@Injectable()
export class StatisticsService {
  constructor(private http: HttpClient) {}

  public getUmbrellaCategory(reportId: number) {
    let params = new HttpParams();
    if (reportId) {
      params = params.set('reportId', '' + reportId);
    }

    return this.http.get<UmbrellaCategoryStatistic[]>(
      '/rest/statistics/umbrellaCategory',
      { params }
    );
  }
}
