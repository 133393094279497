import { Injectable } from '@angular/core';

@Injectable()
export class UserService {
  public currentUser = {
    administrator: true,
  };

  constructor() {}
}
