<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Edit Taxon</h2>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-md-4">
      <div class="card" *ngIf="taxon">
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h5>Taxon</h5>
            </div>
            <div class="col-md-6" align="right">
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary btn-sm"
                  (click)="saveAction()"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  (click)="cancelAction()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div *ngIf="responseMessages && responseMessages.length > 0">
            <alert type="danger" *ngFor="let msg of responseMessages">{{
              msg.description
            }}</alert>
          </div>

          <form autocomplete="off">
            <div class="row">
              <div class="form-group col-md-8">
                <label for="name">Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="taxon.taxonName"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-md-8">
                <label for="rank">Rank</label>
                <select
                  class="form-control"
                  id="rank"
                  name="rank"
                  [(ngModel)]="taxon.taxonRank"
                >
                  <option *ngFor="let rank of taxonRanks" [ngValue]="rank">
                    {{ rank }}
                  </option>
                </select>
              </div>
            </div>
          </form>

          <div class="row" *ngIf="!taxon.nztcsTaxonId">
            <div class="form-group col-md-8">
              <label for="rank">Species Type</label>
              <select
                class="form-control"
                id="speciesType"
                name="speciesType"
                [(ngModel)]="taxon.speciesType"
              >
                <option
                  *ngFor="let speciesType of speciesTypeList"
                  [ngValue]="speciesType"
                >
                  {{ speciesType }}
                </option>
              </select>
            </div>
          </div>

          <div class="row" *ngIf="taxon.nztcsTaxonId">
            <div class="col-md-4"><b>Species Type</b></div>
            <div class="col-md-6">{{ taxon.speciesType }}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-4">
      <div class="card" *ngIf="taxonDto && taxonDto.parent">
        <h5 class="card-header">Parent</h5>
        <div class="card-body">
          <div class="row">
            <div class="col-md-4"><b>Name</b></div>
            <div class="col-md-8">
              <a routerLink="/nztcs-taxon/{{ taxonDto.parent.nztcsTaxonId }}">{{
                taxonDto.parent.taxonName
              }}</a>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Rank</b></div>
            <div class="col-md-8">{{ taxonDto.parent.taxonRank }}</div>
          </div>

          <div class="row">
            <div class="col-md-4"><b>Species Type</b></div>
            <div class="col-md-8">{{ taxonDto.parent.speciesType }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row mt-4">
    <div class="col-md-8">
      <div
        class="card"
        *ngIf="taxonDto && taxonDto.children && taxonDto.children.length > 0"
      >
        <div class="card-header">
          <div class="row">
            <div class="col-md-6">
              <h5>Children</h5>
            </div>
            <div class="col-md-6" align="right"></div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-3" *ngFor="let child of taxonDto.children">
              <span
                ><a routerLink="/nztcs-taxon/{{ child.nztcsTaxonId }}">{{
                  child.taxonName
                }}</a></span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
