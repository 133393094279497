import { Component, Input, OnInit } from '@angular/core';
import { NZORNameService } from '../service/nzorname.service';
import { SpeciesNzorNameService } from '../../species/service/species-nzor-name.service';
import { NztcsSpeciesService } from '../../nztcs-species/service/nztcs-species.service';

@Component({
  selector: 'app-nzor-detail',
  templateUrl: './nzor-detail.component.html',
})
export class NzorDetailComponent implements OnInit {
  @Input()
  nztcsSpeciesId: number;

  nzorId: string;
  nzorName: any;
  nzorCommonNames: any;

  constructor(
    private nztcsSpeciesService: NztcsSpeciesService,
    private nzorNameService: NZORNameService,
    private speciesNzorNameService: SpeciesNzorNameService
  ) {}

  ngOnInit() {
    if (this.nztcsSpeciesId) {
      this.nztcsSpeciesService
        .getNztcsSpecies(this.nztcsSpeciesId)
        .subscribe((response) => {
          this.nzorId = response.nzorId;
          if (this.nzorId) {
            this.loadNZORDetails();
          }
        });
    }
  }

  loadNZORDetails() {
    this.nzorNameService
      .findByIdExtended(this.nzorId)
      .subscribe((nzorNameResponse) => {
        this.nzorName = nzorNameResponse;
      });

    this.nzorNameService
      .getCommonNames(this.nzorId)
      .subscribe((nzorCommonNamesResponse) => {
        this.nzorCommonNames = nzorCommonNamesResponse;
      });
  }
}
