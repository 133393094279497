import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable()
export class CommentService {
  constructor(private http: HttpClient) {}

  getCommentsByAssessmentId(assessmentId: number) {
    return this.http.get('/rest/comments/', {
      params: new HttpParams().set('assessmentId', assessmentId.toString()),
    });
  }

  saveComment(comment: any) {
    return this.http.post('/rest/comments', comment);
  }

  removeComment(commentId: number) {
    return this.http.delete('/rest/comments/' + commentId);
  }
}
