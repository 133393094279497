import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { JwtModule } from '@auth0/angular-jwt';

import { AppComponent } from './app.component';
import { appRoutingProviders, routing } from './app.routes';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PersonSearchComponent } from './person/search/person-search.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { PersonEditComponent } from './person/edit/person-edit.component';
import { YesNoPipe } from './common/yesno/yes-no.pipe';
import { UserService } from './user/user.service';
import { LoginComponent } from './authentication/login/login.component';
import { AuthenticationService } from './authentication/service/authentication.service';
import { AuthGuard } from './auth.guard';
import { HomeComponent } from './home/home.component';
import { NameMatchSearchComponent } from './nzor/name-match-search/name-match-search.component';
import { NameMatchDetailComponent } from './nzor/name-match-detail/name-match-detail.component';
import { BatchControlComponent } from './batch/batch-control/batch-control.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ReportDetailComponent } from './report/report-detail/report-detail.component';
import { ReportSearchComponent } from './report/report-search/report-search.component';
import { AssessmentSearchComponent } from './assessment/assessment-search/assessment-search.component';
import { AssessmentDetailComponent } from './assessment/assessment-detail/assessment-detail.component';
import { ContactViewComponent } from './contact/contact-view/contact-view.component';
import { StatusBadgeComponent } from './common/status-badge/status-badge.component';
import { MyLinkDirective } from './common/my-link.directive';
import { AssessmentTabComponent } from './assessment/assessment-tab/assessment-tab.component';
import { SpeciesDetailComponent } from './species/species-detail/species-detail.component';
import { NewlinesPipe } from './common/newlines/newlines.pipe';
import { NzorDetailComponent } from './nzor/nzor-detail/nzor-detail.component';
import { CommentListComponent } from './comments/comment-list/comment-list.component';
import { ResetPasswordComponent } from './person/edit/reset-password/reset-password.component';
import { NgProgressModule } from 'ngx-progressbar';
import { NgProgressHttpModule } from 'ngx-progressbar/http';
import { EditTagsComponent } from './assessment/assessment-detail/edit-tags/edit-tags.component';
import { AssessmentExportComponent } from './assessment/assessment-export/assessment-export.component';
import { CodeValueViewComponent } from './code/code-value-view/code-value-view.component';
import { CodeValueEditComponent } from './code/code-value-edit/code-value-edit.component';
import { MessagesViewComponent } from './messages/messages-view/messages-view.component';
import { CategoryTagsComponent } from './assessment/assessment-search/category-tags/category-tags.component';
import { UmbrellaCategoryViewComponent } from './statistics/umbrella-category-view/umbrella-category-view.component';
import { NgChartsModule } from 'ng2-charts';
import { HttpErrorInterceptor } from './http-error-interceptor';
import { SpeciesSelectorComponent } from './report/report-detail/species-selector/species-selector.component';
import { AssessmentSelectorComponent } from './report/report-detail/assessment-selector/assessment-selector.component';
import { MessageDialogComponent } from './common/message-dialog/message-dialog.component';
import { EditNameComponent } from './assessment/assessment-tab/edit-name/edit-name.component';
import { HomeQuickSearchComponent } from './home/home-quick-search/home-quick-search.component';
import { NameSourceComponent } from './common/name-source/name-source.component';
import { PanelMembershipComponent } from './person/panel-membership/panel-membership.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { VersionService } from './version/version.service';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ContentEditDialogComponent } from './content/content-edit-dialog/content-edit-dialog.component';
import { ContentViewComponent } from './content/content-view/content-view.component';
import { ContentService } from './content/service/content.service';
import { TagListComponent } from './tag/tag-list/tag-list.component';
import { TagEditComponent } from './tag/tag-edit/tag-edit.component';
import { NztcsSpeciesSearchComponent } from './nztcs-species/nztcs-species-search/nztcs-species-search.component';
import { NztcsSpeciesViewComponent } from './nztcs-species/nztcs-species-view/nztcs-species-view.component';
import { BatchJobReportComponent } from './batch/batch-control/batch-job-report/batch-job-report.component';
import { TaxonNameListComponent } from './nztcs-taxon-name/taxon-name-list/taxon-name-list.component';
import { TaxonNameEditComponent } from './nztcs-taxon-name/taxon-name-edit/taxon-name-edit.component';
import { TaxonSearchComponent } from './nztcs-taxon-name/taxon-search/taxon-search.component';
import { TaxonNameMoveDialogComponent } from './nztcs-taxon-name/taxon-name-view/taxon-name-move-dialog/taxon-name-move-dialog.component';
import { NztcsSpeciesEditComponent } from './nztcs-species/nztcs-species-edit/nztcs-species-edit.component';
import { BiowebSpeciesSourceComponent } from './nztcs-species/nztcs-species-edit/bioweb-species-source/bioweb-species-source.component';
import { NZORSpeciesSourceComponent } from './nztcs-species/nztcs-species-edit/nzorspecies-source/nzorspecies-source.component';
import { SpeciesSourceDirective } from './nztcs-species/nztcs-species-edit/species-source.directive';
import { SpeciesSourceListComponent } from './nztcs-species/nztcs-species-edit/species-source-list/species-source-list.component';
import { CopyAttributeFieldComponent } from './nztcs-species/nztcs-species-edit/copy-attribute-field/copy-attribute-field.component';
import { BiowebTaxonNameListComponent } from './nztcs-species/nztcs-species-edit/bioweb-species-source/bioweb-taxon-name-list/bioweb-taxon-name-list.component';
import { BiowebSpeciesSearchComponent } from './nztcs-species/nztcs-species-edit/bioweb-species-source/bioweb-species-search/bioweb-species-search.component';
import { EditSpeciesNameComponent } from './nztcs-species/nztcs-species-edit/edit-species-name/edit-species-name.component';
import { TaxonSelectComponent } from './nztcs-species/nztcs-species-edit/taxon-select/taxon-select.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NZORTaxonNameListComponent } from './nztcs-species/nztcs-species-edit/nzorspecies-source/nzortaxon-name-list/nzortaxon-name-list.component';
import { ChangeSpeciesTypeDialogComponent } from './nztcs-taxon-name/taxon-name-view/change-species-type-dialog/change-species-type-dialog.component';
import { WaitSpinnerComponent } from './common/wait-spinner/wait-spinner.component';
import { NzorSpeciesSearchComponent } from './nztcs-species/nztcs-species-edit/nzorspecies-source/nzor-species-search/nzor-species-search.component';
import { NztcsSpeciesAssessmentViewComponent } from './nztcs-species/nztcs-species-assessment-view/nztcs-species-assessment-view.component';
import { TaxonNameViewComponent } from './nztcs-taxon-name/taxon-name-view/taxon-name-view.component';
import { ContentSearchComponent } from './content/content-search/content-search.component';
import { ContentEditComponent } from './content/content-edit/content-edit.component';
import { ContentLinksComponent } from './content/content-links/content-links.component';
import { FileUploadModule } from 'ng2-file-upload';
import { FileUploadComponent } from './attachment/file-upload/file-upload.component';
import { AttachmentSearchComponent } from './attachment/attachment-search/attachment-search.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {environment} from '../environments/environment';

export const myTokenGetter = () => {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  return currentUser ? currentUser.token : '';
};

const url = new URL(location.href);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PersonSearchComponent,
    PersonEditComponent,
    YesNoPipe,
    HomeComponent,
    NameMatchSearchComponent,
    NameMatchDetailComponent,
    BatchControlComponent,
    ReportDetailComponent,
    ReportSearchComponent,
    AssessmentSearchComponent,
    AssessmentDetailComponent,
    AssessmentExportComponent,
    ContactViewComponent,
    StatusBadgeComponent,
    MyLinkDirective,
    AssessmentTabComponent,
    SpeciesDetailComponent,
    NewlinesPipe,
    NzorDetailComponent,
    CommentListComponent,
    ResetPasswordComponent,
    EditTagsComponent,
    CodeValueViewComponent,
    CodeValueEditComponent,
    MessagesViewComponent,
    CategoryTagsComponent,
    UmbrellaCategoryViewComponent,
    SpeciesSelectorComponent,
    AssessmentSelectorComponent,
    MessageDialogComponent,
    EditNameComponent,
    HomeQuickSearchComponent,
    NameSourceComponent,
    PanelMembershipComponent,
    PageNotFoundComponent,
    ContentEditDialogComponent,
    ContentViewComponent,
    TagListComponent,
    TagEditComponent,
    NztcsSpeciesSearchComponent,
    NztcsSpeciesViewComponent,
    BatchJobReportComponent,
    TaxonNameListComponent,
    TaxonNameEditComponent,
    TaxonSearchComponent,
    TaxonNameMoveDialogComponent,
    NztcsSpeciesEditComponent,
    BiowebSpeciesSourceComponent,
    NZORSpeciesSourceComponent,
    SpeciesSourceDirective,
    SpeciesSourceListComponent,
    CopyAttributeFieldComponent,
    BiowebTaxonNameListComponent,
    BiowebSpeciesSearchComponent,
    EditSpeciesNameComponent,
    TaxonSelectComponent,
    NZORTaxonNameListComponent,
    ChangeSpeciesTypeDialogComponent,
    WaitSpinnerComponent,
    NzorSpeciesSearchComponent,
    NztcsSpeciesAssessmentViewComponent,
    TaxonNameViewComponent,
    ContentSearchComponent,
    ContentEditComponent,
    ContentLinksComponent,
    FileUploadComponent,
    AttachmentSearchComponent,
    TextEditorComponent,
  ],
  imports: [
    FileUploadModule,
    BrowserModule,
    NgChartsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule,
    NgProgressModule,
    NgProgressHttpModule,
    AlertModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    ProgressbarModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
    EditorModule,
    FontAwesomeModule,
    BrowserAnimationsModule,
    routing,
    JwtModule.forRoot({
      config: {
        tokenGetter: myTokenGetter,
      },
    }),
    NgxGoogleAnalyticsModule.forRoot(url.hostname === 'nztcs.org.nz' ? environment.ga : null),
    NgxGoogleAnalyticsRouterModule
  ],
  providers: [
    appRoutingProviders,
    ContentService,
    UserService,
    VersionService,
    AuthenticationService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
