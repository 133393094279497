import {Code} from '../../domain/nztcs/code';
import { Category } from '../../domain/nztcs/category';
import {PopulationState} from '../../domain/nztcs/population-state.enum';

export class ConservationStatusRequest {
  constructor(
    public criteria?: string,
    public inputConservationStatus?: Category,
    public outputConservationStatus?: Category,
    public reportType?: Code,
    public populationSize?: Code,
    public populationState?: PopulationState,
    public populationTrend?: Code
  ) {}
}
