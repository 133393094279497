<div class="container-fluid">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">Report Details</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1" *ngIf="editMode">
        <button
          type="button"
          class="btn btn-danger btn-sm"
          (click)="onDeleteAction()"
        >
          Delete
        </button>
        <div class="btn-group" dropdown>
          <button
            dropdownToggle
            type="button"
            class="btn btn-sm btn-primary dropdown-toggle"
          >
            Save As... <span class="caret"></span>
          </button>
          <ul *dropdownMenu class="dropdown-menu" role="menu">
            <li *ngFor="let saveOption of saveOptions" role="menuitem">
              <a
                class="dropdown-item"
                href="#"
                (click)="saveAction(saveOption)"
                >{{ saveOption }}</a
              >
            </li>
          </ul>
        </div>

        <button
          type="button"
          class="btn btn-sm btn-light"
          (click)="cancelAction()"
        >
          Cancel
        </button>
      </div>

      <div class="btn-group mt-1" *ngIf="!editMode">
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="updateNamesAction()"
          *ngIf="reportEditor && editAvailable"
        >
          Update names
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="changeReportEditStatusAction('COMPLETED')"
          *ngIf="reportEditor && completeAvailable"
        >
          Complete
        </button>
        <button
          type="button"
          class="btn btn-info btn-sm"
          (click)="changeReportEditStatusAction('IN_PROGRESS')"
          *ngIf="reportEditor && revertAvailable"
        >
          Revert
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="changeReportEditStatusAction('PUBLISHED')"
          *ngIf="reportEditor && publishAvailable"
        >
          Publish
        </button>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          (click)="changeReportEditStatusAction('COMPLETED')"
          *ngIf="reportEditor && unpublishAvailable"
        >
          Unpublish
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          (click)="changeReportPublishedStatusAction('ARCHIVED')"
          *ngIf="reportEditor && archiveAvailable"
        >
          Archive
        </button>
        <button
          type="button"
          class="btn btn-warning btn-sm"
          (click)="changeReportPublishedStatusAction('CURRENT')"
          *ngIf="reportEditor && unarchiveAvailable"
        >
          Unarchive
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="editAction()"
          *ngIf="reportEditor && editAvailable"
        >
          Edit
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="newReportFromThisAction()"
          *ngIf="reportEditor"
        >
          New from this
        </button>
        <button
          type="button"
          class="btn btn-success btn-sm"
          (click)="showAssessments()"
        >
          Show Assessments
        </button>
      </div>
    </div>
  </div>
  <hr />

  <alert *ngIf="deleteFailureMsg" type="danger" [dismissible]="true">{{
    deleteFailureMsg
  }}</alert>

  <!-- View mode -->
  <div *ngIf="!editMode && selectedReport.model">
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-4"><b>Name</b></div>
          <div class="col-md-8">{{ selectedReport.model.name }}</div>
        </div>

        <div class="row">
          <div class="col-md-4"><b>Year Assessed</b></div>
          <div class="col-md-8">{{ selectedReport.model.yearAssessed }}</div>
        </div>

        <div class="row">
          <div class="col-md-4"><b>Year Published</b></div>
          <div class="col-md-8">{{ selectedReport.model.yearPublished }}</div>
        </div>

        <div class="row">
          <div class="col-md-4"><b>Methodology</b></div>
          <div class="col-md-8">
            {{ selectedReport.model.reportType.title }}
          </div>
        </div>

        <div class="row">
          <div class="col-md-4"><b>Edit Status</b></div>
          <div class="col-md-8">
            <app-status-badge
              [statusValue]="selectedReport.model.editStatus"
            ></app-status-badge>
          </div>
        </div>

        <div class="row">
          <div class="col-md-4"><b>Published Status</b></div>
          <div class="col-md-8">
            <app-status-badge
              [statusValue]="selectedReport.model.publishedStatus"
            ></app-status-badge>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-12">
            <tabset>
              <tab heading="Umbrella Category">
                <app-umbrella-category-view
                  [reportId]="reportId"
                ></app-umbrella-category-view>
              </tab>
            </tabset>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="row">
          <div class="col-md-12"><b>Notes</b></div>
          <div class="col-md-12" [innerHTML]="selectedReport.model.notes"></div>
        </div>

        <div class="row" *ngIf="reportEditor">
          <div class="col-md-12"><b>Private Notes</b></div>
          <div
            class="col-md-12"
            [innerHTML]="selectedReport.model.privateNotes"
          ></div>
        </div>
      </div>
    </div>
  </div>

  <!-- Edit mode -->
  <div *ngIf="editMode && reportEditor && selectedReport.model">
    <div class="row">
      <div *ngIf="selectedReport && selectedReport.responseMessages.length > 0">
        <alert
          type="danger"
          *ngFor="let msg of selectedReport.responseMessages"
          >{{ msg.description }}</alert
        >
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="nameTxtFld">Name</label>
              <input
                type="text"
                class="form-control"
                id="nameTxtFld"
                name="nameTxtFld"
                [(ngModel)]="selectedReport.model.name"
                required
              />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="yearAssessedTxtFld"
                >Year Assessed</label
              >
              <input
                type="text"
                class="form-control"
                id="yearAssessedTxtFld"
                name="yearAssessedTxtFld"
                [(ngModel)]="selectedReport.model.yearAssessed"
                required
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="reportTypeSelect">Methodology</label>
              <select
                type="text"
                class="form-control"
                id="reportTypeSelect"
                name="reportTypeSelect"
                [(ngModel)]="selectedReport.model.reportType"
                [compareWith]="compareReportType"
                required
              >
                <option *ngFor="let rt of reportTypeList" [ngValue]="rt">
                  {{ rt.title }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label class="control-label" for="yearPublishedTxtFld"
                >Year Published</label
              >
              <input
                type="text"
                class="form-control"
                id="yearPublishedTxtFld"
                name="yearPublishedTxtFld"
                [(ngModel)]="selectedReport.model.yearPublished"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="control-label">Public Notes</label>
        <!--<editor [init]="editorSettings" [(ngModel)]="selectedReport.model.notes"></editor>-->
        <app-text-editor
          #textEditorPublicNotes
          [context]="'report:' + reportId"
          [text]="selectedReport.model.notes"
          [publicallyVisible]="true"
          [attachmentsEnabled]="reportId > 0"
          (attachmentsChangedEvent)="publicNotesAttachmentsChanged()"
        >
        </app-text-editor>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <label class="control-label">Private Notes</label>
        <!--<editor [init]="editorSettings" [(ngModel)]="selectedReport.model.privateNotes"></editor>-->
        <app-text-editor
          #textEditorPrivateNotes
          [context]="'report:' + reportId"
          [text]="selectedReport.model.privateNotes"
          [attachmentsEnabled]="reportId > 0"
          (attachmentsChangedEvent)="privateNotesAttachmentsChanged()"
        >
        </app-text-editor>
      </div>
    </div>
  </div>

  <div *ngIf="selectedReport.model && reportEditor && !editMode">
    <hr />
    <br />

    <tabset>
      <tab heading="Assessments">
        <br />
        <app-assessment-selector
          #assessmentSelectorComponent
          [reportName]="selectedReport.model.name"
          [reportId]="selectedReport.model.reportId"
          [modifable]="editAvailable"
        >
        </app-assessment-selector>
      </tab>

      <tab heading="Members">
        <br />
        <div class="row" *ngIf="reportEditor">
          <div class="col-md-4">
            <label class="control-label" for="personSelect">Person</label>
            <select
              class="form-control"
              id="personSelect"
              name="personSelect"
              [(ngModel)]="newPanelMember.personId"
            >
              <option *ngFor="let p of peopleList" [ngValue]="p.personId">
                {{ p.fullName }}
              </option>
            </select>
          </div>

          <div class="col-md-4">
            <label class="control-label" for="reportRoleSelect"
              >Report Role</label
            >
            <select
              class="form-control"
              id="reportRoleSelect"
              name="reportRoleSelect"
              [(ngModel)]="newPanelMember.reportRole"
            >
              <option *ngFor="let r of reportRoleOptions" [ngValue]="r.value">
                {{ r.name }}
              </option>
            </select>
          </div>

          <div class="col-md-2">
            <button
              type="button"
              class="btn btn-sm btn-primary btn-block"
              (click)="addPanelMemberAction()"
            >
              Add
            </button>
          </div>
        </div>

        <br />

        <div class="row">
          <div class="col-md-8">
            <table class="table table-striped table-bordered">
              <tbody>
                <tr>
                  <th>Report Panel Member</th>
                  <th>Report Role</th>
                  <th *ngIf="reportEditor">Remove</th>
                </tr>
                <tr *ngFor="let panelMember of selectedReport.model.panelMembers">
                  <td>
                    {{ panelMember.person.firstName }}
                    {{ panelMember.person.familyName }}
                  </td>
                  <td>{{ panelMember.reportRole }}</td>
                  <td *ngIf="reportEditor">
                    <button
                      type="button"
                      class="btn btn-danger btn-sm"
                      (click)="removePanelMemberAction(panelMember)"
                    >
                      <fa-icon [icon]="removeIcon"></fa-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </tab>
    </tabset>
  </div>
</div>
