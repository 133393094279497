import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-batch-job-report',
  templateUrl: './batch-job-report.component.html',
})
export class BatchJobReportComponent implements OnInit {
  @Input()
  jobReport: any;

  constructor(public sanitizer: DomSanitizer) {}

  ngOnInit() {}
}
