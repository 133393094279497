import { NztcsTaxonName } from './nztcs-taxon-name';

export class NztcsSpeciesEntity {
  nztcsSpeciesId: number;
  biowebSpeciesId: number;
  nzorId: string;
  nameAndAuthority: string;
  scientificName: string;
  speciesEpithet: string;
  trinomialType: string;
  trinomialEpithet: string;
  namingAuthority: string;
  taxonomicStatus: string;
  commonName: string;
  maoriName: string;
  alternativeNames: string;
  speciesType: string;
  biostatusCategory: string;
  environmentCategory: string;
  generationTimeInYears: number;
  lifeMode: string;
  notes;

  taxonName: NztcsTaxonName;

  constructor() {}
}
