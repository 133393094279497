<div class="panel panel-default">
  <div class="panel-body">
    <progressbar [max]="100" [value]="jobReport.progress">
      <span style="color: white; white-space: nowrap">{{
        jobReport.progress
      }}</span>
    </progressbar>

    <div>
      <ul class="dl-horizontal" *ngIf="jobReport.messages">
        <li
          *ngFor="let msg of jobReport.messages"
          [innerHTML]="sanitizer.bypassSecurityTrustHtml(msg)"
        ></li>
      </ul>
    </div>

    <div>
      <dl class="dl-horizontal">
        <dt>Status</dt>
        <dd>{{ jobReport.batchJobStatus }}</dd>

        <dt>Last Result</dt>
        <dd>{{ jobReport.lastResult }}</dd>
      </dl>
    </div>
  </div>
</div>
