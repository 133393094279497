<div class="container-fluid">
  <h1>Content Search</h1>
  <hr />

  <form class="form">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <label for="nameCriteriaTxtFld">Name</label>
            <input
              type="text"
              class="form-control"
              id="nameCriteriaTxtFld"
              name="nameCriteriaTxtFld"
              [(ngModel)]="criteria.nameCriteria"
            />
          </div>

          <div class="col-md-4"></div>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          type="button"
          class="btn btn-light btn-block"
          (click)="clearAction()"
        >
          Clear
        </button>
        <button
          type="button"
          class="btn btn-success btn-block"
          (click)="newAction()"
        >
          New...
        </button>
      </div>
    </div>
  </form>

  <br />

  <div class="row" *ngIf="response">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Link Label</th>
              <th>Visibility</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody *ngIf="response.searchResults.length > 0">
            <tr *ngFor="let content of response.searchResults">
              <td>
                <a [routerLink]="'/content/' + content.name">
                  {{ content.linkLabel }}
                </a>
              </td>
              <td>{{ content.visibility }}</td>
              <td>
                <button
                  class="btn btn-sm btn-success mr-2"
                  (click)="editAction(content)"
                >
                  <fa-icon [icon]="editIcon"></fa-icon>
                </button>
                <button
                  class="btn btn-sm btn-danger"
                  (click)="deleteAction(content)"
                >
                  <fa-icon [icon]="deleteIcon"></fa-icon>
                </button>
              </td>
            </tr>
          </tbody>

          <tbody *ngIf="response.searchResults.length === 0">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="response">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="criteria.pageSize"
        [(ngModel)]="criteria.pageNumber"
        [totalItems]="response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ response.total }}, Page: {{ response.page }} of
      {{ response.numberOfPages }}
    </div>
  </div>
</div>
