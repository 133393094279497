import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead';
import { NztcsTaxonEntityService } from '../../service/nztcs-taxon-entity.service';
import { NztcsTaxonEntity } from '../../../domain/nztcs/nztcs-taxon-entity';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { NztcsTaxonSearchCriteria } from '../../taxon-search/nztcs-taxon-search-criteria';
import { PagedResponse } from '../../../domain/response/PagedResponse';

@Component({
  selector: 'app-taxon-name-move-dialog',
  templateUrl: './taxon-name-move-dialog.component.html',
})
export class TaxonNameMoveDialogComponent implements OnInit {
  @ViewChild('taxonNameMoveModal', { static: true })
  taxonNameMoveModal: ModalDirective;

  @Output()
  taxonMovedEvent = new EventEmitter();

  selectedTaxon: NztcsTaxonEntity;

  destinationInput: string;
  dataSource: Observable<any>;

  destinationTaxon: NztcsTaxonEntity = new NztcsTaxonEntity();
  moveValid: boolean = null;
  speciesTypeIsDifferent: boolean = null;

  responseMessages: any[];

  constructor(private nztcsTaxonEntityService: NztcsTaxonEntityService) {
    this.dataSource = Observable.create((observer: any) => {
      observer.next(this.destinationTaxon);
    }).pipe(mergeMap((token: string) => this.getStatesAsObservable(token)));
  }

  getStatesAsObservable(token: any): Observable<any> {
    const criteria = new NztcsTaxonSearchCriteria();
    criteria.name = this.destinationInput;
    return this.nztcsTaxonEntityService
      .findByCriteria(criteria)
      .pipe(map((pagedResponse: PagedResponse) => pagedResponse.searchResults));
  }

  ngOnInit() {}

  show(selectedTaxon: NztcsTaxonEntity) {
    this.selectedTaxon = selectedTaxon;
    this.destinationInput = null;
    this.destinationTaxon = null;
    this.moveValid = null;
    this.taxonNameMoveModal.show();
  }

  moveAction() {
    const childId = this.selectedTaxon.nztcsTaxonId;
    const parentId = this.destinationTaxon.nztcsTaxonId;
    this.nztcsTaxonEntityService
      .moveTaxon(childId, parentId)
      .subscribe((response) => {
        this.responseMessages = response.responseMessages;
        if (this.responseMessages.length === 0) {
          this.taxonNameMoveModal.hide();
          this.taxonMovedEvent.next('moved');
        }
      });
  }

  cancelAction() {
    this.taxonNameMoveModal.hide();
  }

  onSelect($event: TypeaheadMatch) {
    console.log('onSelect() = ' + JSON.stringify($event.item));
    this.destinationTaxon = $event.item;

    if (this.selectedTaxon && this.destinationTaxon) {
      const fromRank = this.selectedTaxon.taxonRank;
      const toRank = this.destinationTaxon.taxonRank;
      this.moveValid = this.nztcsTaxonEntityService.isMoveValid(
        fromRank,
        toRank
      );
    }

    this.speciesTypeIsDifferent =
      this.selectedTaxon &&
      this.destinationTaxon &&
      this.selectedTaxon.speciesType !== this.destinationTaxon.speciesType;
  }
}
