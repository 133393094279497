export class NztcsTaxonEntity {
  public nztcsTaxonId: number;
  public parentId: number;
  public taxonName: string;
  public taxonRank: string;
  public speciesType: string;

  // Only used in GUI
  public selected: boolean;

  constructor() {}
}
