<!-- View Mode -->
<div class="container-fluid" *ngIf="!editMode">
  <div class="row">
    <div class="col-md-4">
      <h2 id="viewHdg">View Person</h2>
    </div>
    <div class="col-md-8 text-right">
      <div class="btn-group mt-1">
        <button class="btn btn-sm btn-primary" id="editBtn" (click)="onEdit()">
          Edit
        </button>
        <button
          class="btn btn-sm btn-primary"
          id="resetPasswordBtn"
          (click)="onResetPassword(resetPasswordModal)"
        >
          Reset Password
        </button>
      </div>
    </div>
  </div>
  <hr />

  <div class="row">
    <div class="col-md-12">
      <dl class="row">
        <dt class="col-md-3">Name</dt>
        <dd class="col-md-9">{{ person.firstName }} {{ person.familyName }}</dd>

        <dt class="col-md-3">Email / Username</dt>
        <dd class="col-md-9">{{ person.userName }}</dd>

        <dt class="col-md-3">Administrator</dt>
        <dd class="col-md-9">{{ person.administrator | yesNo }}</dd>

        <dt class="col-md-3">Name Editor</dt>
        <dd class="col-md-9">{{ person.nameEditor | yesNo }}</dd>

        <dt class="col-md-3">Historical Name Editor</dt>
        <dd class="col-md-9">{{ person.historicalNameEditor | yesNo }}</dd>
      </dl>
    </div>
  </div>

  <app-panel-membership
    [personId]="personId"
    (deleteEnabledEvent)="deleteEnabledEvent($event)"
  ></app-panel-membership>
</div>

<!-- Edit Mode -->
<div class="container-fluid" *ngIf="editMode">
  <div class="row">
    <div class="col-md-12">
      <h2 id="editHdg">Edit Person</h2>
      <hr />
    </div>
  </div>

  <!--<div id="responseMessages" class="alert alert-warning" role="alert" *ngIf="responseMessages.length > 0">
    <ul>
      <li *ngFor="let msg of responseMessages">{{msg.severity}} - {{msg.description}}</li>
    </ul>
  </div>-->

  <app-messages-view [messages]="responseMessages"></app-messages-view>

  <div class="row">
    <div class="col-md-6">
      <form (ngSubmit)="onSave()" #personForm="ngForm" novalidate>
        <div class="row">
          <div class="form-group col-md-6">
            <label for="firstName">First Name</label>
            <input
              type="text"
              class="form-control"
              id="firstName"
              [(ngModel)]="person.firstName"
              name="firstName"
            />
          </div>

          <div class="form-group col-md-6">
            <label for="familyName">Family Name</label>
            <input
              type="text"
              class="form-control"
              id="familyName"
              [(ngModel)]="person.familyName"
              name="familyName"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-12">
            <label for="username">Email / Username</label>
            <input
              type="text"
              class="form-control"
              id="username"
              required
              [(ngModel)]="person.userName"
              name="username"
            />
            <div
              *ngIf="formErrors.username"
              class="alert alert-danger"
              id="usernameFieldError"
            >
              {{ formErrors.username }}
            </div>
          </div>
        </div>
      </form>
    </div>

    <div class="col-md-6">
      <div class="col-md-6">
        <div class="panel panel-default">
          <div class="panel-heading">
            <h5 class="panel-title">Permissions</h5>
          </div>
          <div class="panel-body">
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  id="nameEditor"
                  name="nameEditor"
                  [(ngModel)]="person.nameEditor"
                  [disabled]="!currentUser.administrator"
                />
                Name Editor
              </label>
            </div>
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  id="historicalNameEditor"
                  name="historicalNameEditor"
                  [(ngModel)]="person.historicalNameEditor"
                  [disabled]="!currentUser.administrator"
                />
                Historical Name Editor
              </label>
            </div>
            <div class="form-check">
              <label>
                <input
                  type="checkbox"
                  id="administrator"
                  name="administrator"
                  [(ngModel)]="person.administrator"
                  [disabled]="!currentUser.administrator"
                />
                Administrator
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3">
      <button
        id="deleteBtn"
        (click)="onDelete()"
        class="btn btn-danger"
        [disabled]="!deleteEnabled"
      >
        Delete
      </button>
    </div>
    <div class="col-md-3"></div>
    <div class="col-md-3">
      <button
        id="saveBtn"
        type="submit"
        (click)="onSave()"
        class="btn btn-primary"
        [disabled]="!personForm.form.valid"
      >
        Save
      </button>
      <button
        id="cancelBtn"
        type="button"
        (click)="onCancel()"
        class="btn btn-light"
      >
        Cancel
      </button>
    </div>
  </div>
</div>

<app-reset-password
  #resetPasswordComponent
  (resetPasswordEvent)="onResetPasswordEvent()"
></app-reset-password>
