import { Component, OnInit } from '@angular/core';
import { PersonService } from '../service/person.service';
import { PersonCriteria } from '../service/person-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { Router } from '@angular/router';

@Component({
  selector: 'app-person-search',
  templateUrl: 'person-search.component.html',
  styleUrls: ['person-search.component.css'],
})
export class PersonSearchComponent implements OnInit {
  personCriteria = new PersonCriteria();
  response = {
    searchResults: [],
    total: 0,
  };

  constructor(private personService: PersonService, private router: Router) {}

  ngOnInit(): void {
    this.searchAction();
  }

  public searchAction() {
    return this.personService
      .findPersonsByCriteria(this.personCriteria)
      .subscribe((pagedResponse: PagedResponse) => {
        this.response.searchResults = pagedResponse.searchResults;
        this.response.total = pagedResponse.total;
      });
  }

  public clearAction() {
    this.personCriteria.name = null;
    this.searchAction();
  }

  public newPersonAction() {
    console.log('newPersonAction()');
    this.router.navigate(['/person-edit', -1]);
  }

  onPageChanged(event: any): void {
    if (event.page && this.personCriteria) {
      this.personCriteria.pageNumber = event.page;
      this.searchAction();
    }
  }
}
