<div class="container-fluid">
  <h1>Species Search</h1>
  <hr />

  <form class="form">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <label for="nameCriteriaTxtFld">Name</label>
            <input
              type="text"
              class="form-control"
              id="nameCriteriaTxtFld"
              name="nameCriteriaTxtFld"
              [(ngModel)]="searchCtx.criteria.nameCriteria"
            />
          </div>

          <div class="col-md-4">
            <label for="speciesTypeTxtFld">Species Type</label>
            <select
              class="form-control"
              id="speciesTypeTxtFld"
              name="speciesTypeTxtFld"
              [(ngModel)]="searchCtx.criteria.speciesType"
            >
              <option [ngValue]="null"></option>
              <option
                *ngFor="let speciesType of speciesTypes"
                [ngValue]="speciesType"
              >
                {{ speciesType }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          type="button"
          class="btn btn-light btn-block"
          (click)="clearAction()"
        >
          Clear
        </button>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-success btn-block"
          (click)="newSpeciesAction()"
          *ngIf="currentUser.nameEditor"
        >
          New...
        </button>
      </div>
    </div>
  </form>

  <br />

  <div class="row">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Name and Authority</th>
              <th>Common Name</th>
              <th>Māori Name</th>
              <th>Type</th>
              <th>Bio Status</th>
              <th>Taxonomic Status</th>
            </tr>
          </thead>
          <tbody *ngIf="searchCtx.hasSearchResults()">
            <tr *ngFor="let species of searchCtx.response.searchResults">
              <td>
                <a routerLink="/nztcs-species/{{ species.nztcsSpeciesId }}">
                  <span [innerHTML]="species.nameAndAuthority"></span>
                </a>
                <div
                  *ngFor="
                    let assessmentName of searchCtx.relatedAssessments[
                      species.nztcsSpeciesId
                    ]
                  "
                  [innerHTML]="assessmentName"
                ></div>
              </td>
              <td>{{ species.commonName }}</td>
              <td>{{ species.maoriName }}</td>
              <td>{{ species.speciesType }}</td>
              <td>{{ species.biostatusCategory }}</td>
              <td>{{ species.taxonomicStatus }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="!searchCtx.hasSearchResults()">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="searchCtx.criteria.pageSize"
        [(ngModel)]="searchCtx.criteria.pageNumber"
        [totalItems]="searchCtx.response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ searchCtx.response.total }}, Page:
      {{ searchCtx.response.page }} of {{ searchCtx.response.numberOfPages }}
    </div>
  </div>
</div>
