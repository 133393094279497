import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-name-source',
  templateUrl: './name-source.component.html',
  styleUrls: ['./name-source.component.scss'],
})
export class NameSourceComponent implements OnInit {
  @Input()
  nameSource: any;

  constructor() {}

  ngOnInit() {}
}
