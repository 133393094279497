import { Component, OnInit } from '@angular/core';
import { Category } from '../../../domain/nztcs/category';
import { AssessmentSearchCtxService } from '../../service/assessment-search-ctx.service';
import { AssessmentService } from '../../service/assessment.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-category-tags',
  templateUrl: './category-tags.component.html',
})
export class CategoryTagsComponent implements OnInit {
  categoryList: Category[];
  categoryTagActiveMap: Map<string, boolean> = new Map<string, boolean>();

  removeIcon = faTimes;

  constructor(
    public searchCtx: AssessmentSearchCtxService,
    private assessmentService: AssessmentService
  ) {}

  ngOnInit() {
    this.assessmentService.categoryList().subscribe((categoryList) => {
      this.categoryList = categoryList;
    });
  }

  onChange(selectedValue, target) {
    const selectedOption = this.getOptionFromTitle(selectedValue);
    if (selectedOption) {
      this.searchCtx.criteria.categoryCode.push(selectedOption.code);
    }
    target.value = null;
  }

  getOptionFromTitle(title) {
    let option = null;
    for (const nextOption of this.categoryList) {
      if (nextOption.title === title) {
        option = nextOption;
        break;
      }
    }
    return option;
  }

  getOptionFromCode(code) {
    let option = null;
    for (const nextOption of this.categoryList) {
      if (nextOption.code === code) {
        option = nextOption;
        break;
      }
    }
    return option;
  }

  removeCategoryFromSearch(code: string) {
    const indexof = this.searchCtx.criteria.categoryCode.indexOf(code, 0);
    this.searchCtx.criteria.categoryCode.splice(indexof, 1);
    this.categoryTagActiveMap.delete(code);
  }

  getCategoryName(code: string): string {
    let title = '';
    if (code) {
      const option = this.getOptionFromCode(code);
      if (option) {
        title = option.title;
      }
    }
    return title;
  }

  setCategoryTagActiveMapValue(code: string, value: boolean) {
    this.categoryTagActiveMap.set(code, value);
  }

  isCategoryTagActive(code: string): boolean {
    return this.categoryTagActiveMap.get(code);
  }
}
