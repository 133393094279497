import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NztcsSpeciesEntity } from '../../domain/nztcs/nztcs-species-entity';
import { NztcsSpeciesSearchCriteria } from '../nztcs-species-search/nztcs-species-search-criteria';
import { PagedResponse } from '../../domain/response/PagedResponse';
import { HttpClient } from '@angular/common/http';
import { NztcsSpeciesUsage } from './nztcs-species-usage';
import { CalculateSpeciesNameRequest } from './calculate-species-name-request';
import { NameItem } from '../../domain/nztcs/name-item';

@Injectable()
export class NztcsSpeciesService {
  constructor(private http: HttpClient) {}

  getThreeGenerationPeriodInYears(nztcsSpecies: NztcsSpeciesEntity) {
    const threeGenerationPeriodInYears = Math.max(
      10,
      nztcsSpecies.generationTimeInYears * 3
    );
    return threeGenerationPeriodInYears;
  }

  getNztcsBioStatusMap() {
    return this.http.get('/rest/nztcsSpecies/bioStatusMap');
  }

  getNztcsSpecies(speciesId: number): Observable<NztcsSpeciesEntity> {
    return this.http.get<NztcsSpeciesEntity>('/rest/nztcsSpecies/' + speciesId);
  }

  findByCriteria(nztcsSpeciesSearchCriteria: NztcsSpeciesSearchCriteria) {
    return this.http.post<PagedResponse>(
      '/rest/nztcsSpecies/findNztcsSpeciesByCriteria',
      nztcsSpeciesSearchCriteria
    );
  }

  saveNztcsSpecies(nztcsSpecies: NztcsSpeciesEntity): Observable<any> {
    return this.http.post('/rest/nztcsSpecies/', nztcsSpecies);
  }

  removeNztcsSpecies(speciesId: number) {
    return this.http.delete('/rest/nztcsSpecies/' + speciesId);
  }

  findNztcsSpeciesUsage(speciesId: number): Observable<NztcsSpeciesUsage[]> {
    return this.http.get<NztcsSpeciesUsage[]>(
      '/rest/nztcsSpecies/findNztcsSpeciesUsage/' + speciesId
    );
  }

  getSpeciesTypes(): string[] {
    return [
      'Alga',
      'Bacteria',
      'Bird',
      'Fish',
      'Fungus',
      'Herpetofauna',
      'Invertebrate',
      'Mammal',
      'Plant',
      'Protist',
      'Virus',
    ];
  }

  getTrinomialTypes(): string[] {
    return ['f.', 'subsp.', 'var.'];
  }

  getBioStatusOptions(): string[] {
    return [
      'Endemic',
      'Endemicity not specified',
      'Exotic',
      'Non-endemic',
      'Absent',
      'Unknown',
    ];
  }

  calculateSpeciesName(request: CalculateSpeciesNameRequest): Observable<any> {
    return this.http.post<any>(
      '/rest/nztcsSpecies/calculateSpeciesName',
      request
    );
  }

  getLifeModeOptions() {
    return [
      'Lichenised',
      'Lichenicolous',
      'Mycorrhizal',
      'Parasitic',
      'Predatory',
      'Saprotrophic',
      'Unspecified',
    ];
  }
}
