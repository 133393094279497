import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NztcsSpeciesEntity } from '../../../domain/nztcs/nztcs-species-entity';
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-copy-attribute-field',
  templateUrl: './copy-attribute-field.component.html',
  styleUrls: ['copy-attribute-field.component.scss'],
})
export class CopyAttributeFieldComponent implements OnInit {
  @Input()
  sourceLabel: string;

  @Input()
  sourceEntity: any;

  @Input()
  sourceAttribute: string;

  @Input()
  sourceAttributeChild: string;

  @Input()
  sourceAttributeConverter: (value: any) => any;

  @Input()
  targetEntity: NztcsSpeciesEntity;

  @Input()
  targetAttribute: string;

  @Output()
  copy = new EventEmitter()

  faAngleLeft = faAngleLeft;

  constructor() {}

  ngOnInit() {}

  getConvertedValue() {
    if (this.sourceAttributeConverter) {
      return this.sourceAttributeConverter(this.getValue());
    } else {
      return this.getValue();
    }
  }

  getValue() {
    if (this.sourceAttributeChild) {
      return this.getAttributeValueAsList();
    } else {
      return this.sourceEntity[this.sourceAttribute];
    }
  }

  copyAction() {
    if (this.targetEntity && this.targetAttribute) {
      this.targetEntity[this.targetAttribute] = this.getConvertedValue();
      this.copy.emit(this.targetAttribute);
    }
  }

  private getAttributeValueAsList() {
    let value = '';
    if (
      this.sourceEntity &&
      this.sourceAttribute &&
      this.sourceEntity[this.sourceAttribute]
    ) {
      const list = this.sourceEntity[this.sourceAttribute];
      for (let i = 0; i < list.length; i++) {
        value = value + list[i][this.sourceAttributeChild];
        if (i < list.length - 1) {
          value = value + ', ';
        }
      }
    }
    return value;
  }
}
