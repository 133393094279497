/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable brace-style */
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { SpeciesSourceComponent } from '../species-source-component';
import { NztcsSpeciesEntity } from '../../../domain/nztcs/nztcs-species-entity';
import { SpeciesService } from '../../../species/service/species.service';
import { BiowebSpeciesSearchComponent } from './bioweb-species-search/bioweb-species-search.component';
import { CopyAttributeFieldComponent } from '../copy-attribute-field/copy-attribute-field.component';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-bioweb-species-source',
  templateUrl: './bioweb-species-source.component.html',
})
export class BiowebSpeciesSourceComponent
  implements OnInit, SpeciesSourceComponent
{
  @Input()
  nztcsSpecies: NztcsSpeciesEntity;

  @ViewChild('biowebSpeciesSearchComponent', { static: true })
  biowebSpeciesSearchComponent: BiowebSpeciesSearchComponent;

  @ViewChildren(CopyAttributeFieldComponent)
  copyAttributeFields: QueryList<CopyAttributeFieldComponent>;

  @Output()
  copy = new EventEmitter();

  biowebSpecies: any;

  faAngleDoubleLeft = faAngleDoubleLeft;

  constructor(private biowebService: SpeciesService) {}

  ngOnInit() {
    this.biowebSpeciesSearchComponent.speciesSelectedEvent.subscribe(
      (speciesId) => {
        console.log('Source got species selected event: ' + speciesId);
        this.nztcsSpecies.biowebSpeciesId = speciesId;
        this.loadBiowebSpecies();
      }
    );

    this.loadBiowebSpecies();
  }

  loadBiowebSpecies() {
    if (this.nztcsSpecies && this.nztcsSpecies.biowebSpeciesId) {
      this.biowebService
        .getSpecies(this.nztcsSpecies.biowebSpeciesId)
        .subscribe((species) => {
          this.biowebSpecies = species;

          // TODO: Dirty hack to get the genus
          if (
            this.biowebSpecies &&
            this.biowebSpecies.taxon &&
            this.biowebSpecies.taxon.taxonLevel &&
            this.biowebSpecies.taxon.taxonLevel.levelName === 'Genus'
          ) {
            this.biowebSpecies.genus = this.biowebSpecies.taxon.taxonName;
          }
        });
    }
  }

  onCopy(event) {
    this.copy.emit(event);
  }

  searchAction() {
    this.biowebSpeciesSearchComponent.show();
  }

  copyAllAction() {
    this.copyAttributeFields.forEach((copyField) => {
      copyField.copyAction();
    });
  }

  disconnectAction() {
    this.nztcsSpecies.biowebSpeciesId = null;
    this.biowebSpecies = null;
  }

  convertNameReliability(value: any) {
    const MAP_DATA = {
      PA: 'Taxonomically Determinate',
      PN: 'Taxonomically Unresolved',
      PD: 'Taxonomically Unresolved',
      PR: 'Taxonomically Unresolved',
      NZ: 'Taxonomically Invalid',
      NU: 'Taxonomically Invalid',
      NI: 'Taxonomically Invalid',
      TI: 'Taxonomically Indistinct',
      '*': 'Unknown',
    };

    const convertedValue = MAP_DATA[value];
    console.log(
      'convertNameReliability() - ' + value + ' to ' + convertedValue
    );
    return convertedValue;
  }

  convertBioStatus(value: any) {
    const BIOSTATUS_MAP = {
      EXCAS: 'Exotic',
      EXCULT: 'Exotic',
      EXERAD: 'Exotic',
      EXNAT: 'Exotic',
      EXOTIC: 'Exotic',
      INDEND: 'Endemic',
      INDENDEX: 'Endemic',
      INDIGENOUS: 'Endemicity not specified',
      INDNON: 'Non-endemic',
      INDNONEX: 'Non-endemic',
      NA: 'Error',
      UNCERTAIN: 'Unknown',
      UNKNOWN: 'Unknown',
    };

    const convertedValue = BIOSTATUS_MAP[value];
    console.log('convertBioStatus() - ' + value + ' to ' + convertedValue);
    return convertedValue;
  }
}
