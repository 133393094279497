<div class="container-fluid">
  <h1>Attachment Search</h1>
  <hr />

  <form class="form">
    <div class="row">
      <div class="col-md-8">
        <div class="row">
          <div class="col-md-6">
            <label for="nameCriteriaTxtFld">Name</label>
            <input
              type="text"
              class="form-control"
              id="nameCriteriaTxtFld"
              name="nameCriteriaTxtFld"
              [(ngModel)]="criteria.nameCriteria"
            />
          </div>

          <div class="col-md-4"></div>
        </div>
      </div>

      <div class="col-md-2">
        <button
          type="button"
          class="btn btn-primary btn-block"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          type="button"
          class="btn btn-light btn-block"
          (click)="clearAction()"
        >
          Clear
        </button>
        <button
          type="button"
          class="btn btn-success btn-block"
          (click)="uploadAction()"
        >
          Upload...
        </button>
      </div>
    </div>
  </form>

  <br />

  <div class="row" *ngIf="response">
    <div class="col-md-12">
      <div class="table-responsive">
        <table class="table table-striped table-bordered table-sm small">
          <thead>
            <tr>
              <th>Filename</th>
              <th>Content Type</th>
              <th>Context</th>
            </tr>
          </thead>
          <tbody *ngIf="response.searchResults.length > 0">
            <tr *ngFor="let attachment of response.searchResults">
              <td>
                <a
                  [attr.href]="'/rest/attachment/files/' + attachment.filename"
                  >{{ attachment.filename }}</a
                >
              </td>
              <td>{{ attachment.contentType }}</td>
              <td>{{ attachment.context }}</td>
            </tr>
          </tbody>

          <tbody *ngIf="response.searchResults.length === 0">
            <td colspan="100">No matching search results found.</td>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="response">
    <div class="col-md-6">
      <pagination
        [maxSize]="5"
        [itemsPerPage]="criteria.pageSize"
        [(ngModel)]="criteria.pageNumber"
        [totalItems]="response.total"
        (pageChanged)="onPageChanged($event)"
      >
      </pagination>
    </div>

    <div class="col-md-6 text-right">
      Total Results: {{ response.total }}, Page: {{ response.page }} of
      {{ response.numberOfPages }}
    </div>
  </div>
</div>
