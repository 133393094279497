export class CalculateSpeciesNameRequest {
  taxonName: string;
  speciesEpithet: string;
  trinomialType: string;
  trinomialEpithet: string;
  namingAuthority: string;
  speciesType: string;

  constructor() {}
}
