<div class="card mb-3 species-source-heading-background">
  <div class="card-body">
    <div class="row">
      <div class="col-md-1">
        <button
          class="btn btn-sm btn-success"
          type="button"
          (click)="copyAllAction()"
        >
          <fa-icon [icon]="faAngleDoubleLeft"></fa-icon>
        </button>
      </div>
      <div class="col-md-8">
        <h5 class="card-title">NZOR Species</h5>
        <small><b>NZOR ID:</b> {{ nztcsSpecies.nzorId }}</small>
      </div>

      <div class="col-md-3">
        <button
          class="btn btn-sm btn-block btn-primary"
          (click)="searchAction()"
        >
          Search
        </button>
        <button
          class="btn btn-sm btn-block btn-danger"
          (click)="disconnectAction()"
          *ngIf="nztcsSpecies.nzorId"
        >
          Disconnect
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="nzorName">
  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Taxonomy</h5>

      <div class="a-row">
        <div class="a-form-label mb-4">Hierarchy</div>
        <div class="a-cell-value">
          <app-nzortaxon-name-list
            [nzorName]="nzorName"
          ></app-nzortaxon-name-list>
        </div>
      </div>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Formal Name</h5>

      <!-- you can't render html inside of an input field, so we'll make the div look like an non-editable input field -->
      <div
        class="form-control bg-light"
        [innerHTML]="nzorName.formattedFullName"
      ></div>

      <app-copy-attribute-field
        [sourceLabel]="'Authors'"
        [sourceEntity]="nzorName"
        [sourceAttribute]="'authors'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'namingAuthority'"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Other Names</h5>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Attributes</h5>

      <app-copy-attribute-field
        [sourceLabel]="'Bio Status'"
        [sourceEntity]="nzorName"
        [sourceAttribute]="'biostatus'"
        [targetEntity]="nztcsSpecies"
        [targetAttribute]="'bioStatusCategory'"
      >
      </app-copy-attribute-field>
    </div>
  </div>

  <div class="card mb-3">
    <div class="card-body">
      <h5 class="card-title">Notes</h5>
    </div>
  </div>
</div>

<app-nzor-species-search #nzorSpeciesSearchComponent></app-nzor-species-search>
