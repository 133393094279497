export class NameMatchCriteria {
  scientificName: string;
  mrExact = false;
  mrIgnored = false;
  mrMultiple = false;
  mrSkipped = false;
  mrUnmatched = false;
  nrAuto = false;
  nrAssigned = false;
  nrIgnored = false;

  pageNumber = 1;
  pageSize = 25;

  searchResponse: any = {
    searchResults: [],
  };

  constructor() {}
}
