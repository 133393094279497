<span *ngIf="taxonName">
  <!-- http://localhost:4200/nztcs-species/44658 -->
  <!--<span tooltip="{{taxon.taxonRank}}" [ngClass]="alternateColor ? '' : 'alternate-name'">{{taxon.taxon}}</span>&nbsp;-->
  <!--<span tooltip="{{taxon.taxonRank}}" [ngClass]="alternateColor ? '' : 'font-weight-bold'">{{taxon.taxon}}&nbsp;</span>-->
  <!--<span tooltip="{{taxon.taxonRank}}" [ngClass]="alternateColor ? 'text-muted' : ''">{{taxon.taxon}}&nbsp;</span>-->
  <!--<span tooltip="{{taxon.taxonRank}}">{{taxon.taxon}}<span *ngIf="taxon.parent">&nbsp;/&nbsp;</span></span>-->
  <!--<span tooltip="{{taxon.taxonRank}}">{{taxon.taxon}}<span *ngIf="taxon.parent">, </span></span>-->
  <span tooltip="{{ taxonName.taxonRank }}"
    ><a routerLink="/nztcs-taxon/{{ taxonName.nztcsTaxonId }}">{{
      taxonName.taxonName
    }}</a
    ><span *ngIf="taxonName.parent"> / </span></span
  >
  <!--<span tooltip="{{taxon.taxonRank}}" [ngClass]="alternateColor ? '' : 'bg-info'">{{taxon.taxon}}&nbsp;</span>-->
  <!--<span tooltip="{{taxon.taxonRank}}" [ngClass]="alternateColor ? 'bg-secondary' : 'bg-info'">{{taxon.taxon}}&nbsp;</span>-->
  <span *ngIf="taxonName.parent">
    <app-taxon-name-list
      [taxonName]="taxonName.parent"
      [alternateColor]="!alternateColor"
    ></app-taxon-name-list>
  </span>
</span>
